<template>
    <!-- 404 -->
    <div class="error">
        <img class="error-img" src="@/images/404.png" alt="">
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style lang="scss" scoped>
.error {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
