<template>
    <div class="pc_index_hot_tabs">
        <div class='pc_index_ul'>
            <div @click="changeTabs(item, index)" class="pc_index_li" v-for="(item, index) in product" :key="index">
                <span :class="['tabs', tabs_index == index ? 'pc_index_active' : 'pc_index_name']">{{
                    item.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        tabs_index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            product: [],
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        addMissingCharacter(str, character) {
            if (!str.includes(character)) {
                str += character;
            }
            return str;
        },
        getInit() {

            this.$store.dispatch('getHotProduct').then(res => {

                if (res) {
                    res.forEach(item => {
                        if (!item.title.includes('产品')) {
                            item.title += '产品';
                        }
                        this.product.push(item)
                    })
                }
            })

        },
        changeTabs(row, index) {

            this.$emit('changeTabs', row, index)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_index_hot_tabs {
    width: 100%;
    height: 100%;

    @include text-center;

    .pc_index_ul {
        overflow-x: scroll;
        display: flex;
        height: 70px;

        // flex-wrap: wrap;
        &::-webkit-scrollbar {
            display: none;
            /* Chrome、Safari和Opera浏览器 */
        }

        .pc_index_li {
            flex-shrink: 0;
            display: flex;
            height: 65px;
            width: 210px;
            border: 1px solid #CCCCCC;
            @include text-center;



            .pc_index_name {
                height: 100%;
                display: flex;

                @include text-center;
                @include font(18, 25, #333333, 400);
            }

            .pc_index_active {
                width: 100%;
                display: block;
                height: 100%;
                background: var(--bg-colors);
                @include text-center;
                @include font(18, 25, #fff, 600);
            }

            .tabs {
                font-size: 25px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }
}
</style>
