<template>
    <div class="pc_rd_shiyan">
        <div class="public-title">试验范畴</div>
        <div class="public-padding-left-right">
            <div class="box">
                <div class="left_box">
                    <div :class="['li', parent_index == index ? 'active_li' : '']" v-for="(item, index) in list"
                        :key="index" @mouseenter="parent_index = index">
                        {{ item.title }}
                    </div>
                </div>
                <template v-for="(item, index) in list">
                    <div class="right_box" v-if="parent_index == index" :key="index">
                        <div class="left">
                            <div class="right_box_title">
                                {{ item.title }}
                            </div>
                            <div class="right_box_text" style="white-space: pre-wrap;" v-html="item.lgText">

                            </div>
                        </div>
                        <div class="right">
                            <img class="lg_img" :src="item.lgImg" alt="">
                        </div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {
            list: [],
            parent_index: 0,
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_shiyan')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_shiyan {
    width: 100%;
    background: #F7F7F7;

    .public-padding-left-right {
        padding: 0 260px;
        box-sizing: border-box;
    }

    .public-title {
        text-align: center;
        @include font(36, 32, #1a1a1a, 600);
        padding: 120px 0 60px 0;
    }

    .box {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding-bottom: 100px;

        .left_box {
            width: 260px;
            margin-right: 30px;

            .li {
                width: 260px;
                padding: 23px 30px;
                box-sizing: border-box;
                margin-bottom: 3px;
                background: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include font(16, 22, #151515, 400);

                &:hover {
                    cursor: pointer;
                }
            }

            .active_li {
                background: var(--bg-colors);
                @include font(16, 22, #fff, 400)
            }
        }

        .right_box {
            width: 1110px;
            height: 100%;
            box-sizing: border-box;
            background: #fff;
            padding: 70px 80px;
            display: flex;
            justify-content: space-between;

            .left {
                width: 450px;

                .right_box_title {
                    @include font(28, 52, #151515, 600);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                }

                .right_box_text {

                    @include font(16, 32, #444452, 400);
                }
            }

            .right {
                width: 450px;

                .lg_img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
