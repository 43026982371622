<template>
    <div class="pc_list">
        <div class="list_ul">
            <div class="list_li public-padding-left-right"
                :style="`background-color:${index % 2 == 0 ? '#F7F7F7' : '#fff'};`" v-for="(item, index) in list"
                :id="item.lid" :key="index">
                <div class="lt">
                    <div class="top">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.smText }}
                        </div>
                    </div>
                    <div class="bottom">
                        <img class="sm-img" :src="item.smImg" alt="">
                    </div>
                </div>
                <!-- <div class="lt" v-else>
                    <div class="right">
                        <img class="sm-img" :src="item.smImg" alt="">
                    </div>
                    <div class="left">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.smText }}
                        </div>
                    </div>

                </div> -->

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        },
        paddingTopBottom: {
            type: String,
            default: '80px'
        },
        paddingLeft: {
            type: String,
            default: '80px'
        },
        paddingRight: {
            type: String,
            default: '80px'
        },
        bg_color: {
            type: String,
            default: '#fff'
        }
    },
    data() {
        return {
        }
    },
    created() { },
    mounted() {

    },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_list {
    width: 100%;
    box-sizing: border-box;
    // .public-padding-left-right {
    //     padding: 0 260px;
    //     box-sizing: border-box;
    // }

    .list_ul {
        width: 100%;

        .list_li {
            display: flex;
            align-items: center;

            padding: 60px 30px 80px;

            &:nth-child(even) {
                .left {
                    padding-left: 40px;
                    box-sizing: border-box;
                }
            }

            &:nth-child(odd) {
                .left {
                    padding-right: 40px;
                    box-sizing: border-box;
                }
            }

            .lt,
            .rt {
                width: 100%;
                display: flex;
                flex-direction: column;
                // background-color: v-bind(bg_color);

                .top {
                    // min-height: 398px;
                    width: 100%;
                    box-sizing: border-box;

                    .title {
                        @include font(34, 40, #1A1A1A, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        padding-bottom: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .describe {
                        @include font(28, 40, #383838, 400);
                        margin-bottom: 40px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                        /* 这里是超出几行省略 */
                        overflow: hidden;
                    }
                }

                .bottom {
                    width: 100%;

                    .sm-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                    }

                }
            }
        }
    }
}
</style>
