<template>
    <div class="pc-jion-describe type-arae">
        <div class="header-goback" @click="$router.go(-1)">
            <i class="iconfont icon-xitongfanhui"></i>
            <span class="text">返回职位列表</span>
        </div>
        <div class="public-padding">
            <div class="content-box">
                <div class="name">{{ jionList.title }}</div>
                <div class="address">
                    <img class="address-img" src="@/images/icon_position.png" alt="">
                    <span class="address-text">{{ jionList.site }}</span>
                </div>
                <div class="position-describe">
                    <div class="title">职位描述</div>
                    <div class="fwb" style="white-space: pre-wrap;" v-html="jionList.text"></div>
                    <div class="email" :href="jionList.email">简历请投递至邮箱：{{ jionList.email }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            jid: '',
            jionList: {}
        }
    },
    created() {
        this.jid = this.$route.params.jid

    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.jionDetailApi(this.jid)
            if (res.data.meta.code == 200) {
                this.jionList = res.data.data
            }
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-jion-describe {
    width: 100%;
    height: 100%;
    background: #FAFAFA;

    .header-goback {
        width: 100%;
        background: var(--bg-colors);
        display: flex;
        align-items: center;
        padding: 41px 360px;
        color: #FFFFFF;

        margin-bottom: 30px;

        &:hover {
            cursor: pointer;
        }

        .icon-xitongfanhui {
            font-size: 20px;
        }

        .text {
            font-size: 20px;
            font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 27px;
            padding-left: 8px;
        }
    }

    .content-box {
        width: 100%;
        background: #fff;
        padding: 50px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
        font-weight: 400;
        color: #666666;
        line-height: 21px;

        .name {
            font-size: 20px;
            font-family: 'PingFangSC-Semibold, PingFang SC';
            font-weight: 600;
            color: #1A1A1A;
            line-height: 28px;
            padding-bottom: 16px;
        }

        .address {
            display: flex;
            align-items: center;
            padding-bottom: 40px;

            .address-text {
                padding-left: 4px;

            }
        }

        .position-describe {

            .title {
                font-size: 18px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 30px;

                &::after {
                    content: '';
                    display: flex;
                    width: 89%;
                    background: #E6E6E6;
                    height: 1px;
                    padding-left: 40px;
                }
            }

            .fwb {
                padding-bottom: 42px;
                font-size: 18px;
                line-height: 40px;
            }

            .email {
                color: var(--bg-colors);
                font-size: 16px;
                font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
                font-weight: 600;
                line-height: 21px;
            }
        }
    }
}

.public-padding {
    padding: 0 360px;
    box-sizing: border-box;
}
</style>
