<template>
    <div class="about_1">
        <banner :images="$store.state.public_banner_info.smImg" :title="$store.state.public_banner_info.title"></banner>
        <div class="top_content">
            <div class="modules_1">
                <div class="modules_1_box">
                    <div class="top" style="white-space: pre-wrap;" v-html="$store.state.public_banner_info.showText">
                    </div>
                    <div class="center">
                        <div class="center_ul">
                            <div class="center_li" v-for="(item, index) in business" :key="index">
                                <span class="num">
                                    {{ item.num }}
                                    <span v-if="index == 0" class="add">+</span>
                                </span>
                                <span class="name">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="bottom_title">
                            <span class="backg"></span>
                            <span class="title">业务模块</span>
                        </div>
                        <div class="bottom_text" style="white-space: pre-wrap;" v-html="$store.state.company.yewu">
                        </div>
                    </div>
                </div>
                <div class="modules_1_right_img">
                    <img class="img_about_1" :src="$store.state.public_banner_info.showSmImg" alt="">
                </div>
            </div>
            <div class="modules_2">
                <div class=" public_margin_top_bottom_font">
                    企业理念
                </div>
                <div class="modules_2_bottom" :style="`background-image:url(${bg})`">
                    <div class="modules_2_li" v-for="item in modulesThreeArr" :key="item.lid">
                        <div class="name">{{ item.title }}</div>
                        <div class="describe" style="white-space: pre-wrap;" v-html="item.smText"></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="create_box">
            <div class="public_margin_top_bottom_font" id="smartY">
                智能制造板块
            </div>
            <div class="ul">
                <div class="parament_li" v-for="(item, index) in smart_list" :key="index"
                    :style="`background-image:url(${item.smallSmImg})`">
                    <div class="parament_li_box">

                        <img class="icon" :src="item.icon" alt="">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="children_box" v-for="(it, i) in item.categories" :key="i">
                            {{ it }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="course">
            <div class="public_margin_top_bottom_font">
                发展历程
            </div>
            <aboutTimer></aboutTimer>
        </div>

    </div>
</template>

<script>
import aboutTimer from './about_content/about_timer.vue'
import banner from '../banner.vue'
import { mapGetters } from "vuex";
export default {
    name: '',
    components: { aboutTimer, banner },

    data() {
        return {
            list: [
                {
                    num: '3000',
                    name: '员工'
                },
                {
                    num: 1,
                    name: '院士专家工作站'
                },
                {
                    num: 3,
                    name: '技术中心'
                },
                {
                    num: 7,
                    name: '研发平台'
                },
                {
                    num: 10,
                    name: '高新技术企业'
                },
            ],
            key: '',
            modulesTwoObj: {},
            modulesThreeArr: [],
            business: [],
            bg: '',
            smart_list: [],
        }
    },
    computed: {
        ...mapGetters(["company"]),
    },
    mounted() {
        this.key = this.$route.params.key;

        this.$store.dispatch('getPageHeader', this.key)
        this.getInit()
        this.getCreate()

    },
    methods: {
        async getInit() {
            let num = ['员工', '院士专家工作站', '技术中心', '研发平台', '高新技术企业']
            this.company.amount.split('|').forEach((item, index) => {
                this.business.push({
                    name: num[index],
                    num: item
                })
            })

            const res_three = await this.$API.listApi('about_qiyelinian')
            if (res_three.status == 200) {
                this.modulesThreeArr = res_three.data.data
                this.bg = this.modulesThreeArr[0].smImg
            }

        },
        async getCreate() {
            const res = await this.$API.siteBannerApi()
            if (res.data.meta.code == 200) {
                this.smart_list = res.data.data
            }
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_1 {
    width: 100%;
    height: 100%;

    .pc_banner {
        width: 100%;

        .banner_img {
            width: 100%;
        }
    }

    .top_content {
        width: 100%;
        // padding: var(--padding-left-right);
        margin-top: 30px;
        padding: 0 40px;
        box-sizing: border-box;

        .modules_1 {
            @include font(16, 30, #383838, 400);
            font-family: 'PingFangSC-Regular, PingFang SC';
            display: flex;
            flex-direction: column;

            box-sizing: border-box;

            .modules_1_box {
                width: 100%;

                .top {
                    width: 100%;
                    margin-bottom: 24px;
                    font-size: 28px;
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    font-weight: 400;
                    color: #383838;
                    line-height: 52px;
                }

                .center {
                    width: 100%;
                    background: #FCFAFA;
                    height: 121px;

                    @include text-center;

                    .center_ul {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .center_li {
                            @include text-center;
                            flex-direction: column;

                            .num {
                                @include font(40, 49, var(--bg-colors), 600);
                                font-family: 'Inter-SemiBold, Inter';
                                display: flex;


                                .add {
                                    display: flex;
                                    align-items: flex-start;
                                    @include font(24, 29, var(--bg-colors), 600);

                                }
                            }



                            .name {
                                padding-top: 17px;
                                @include font(20, 28, #333333, 400);
                                font-family: 'PingFangSC-Regular, PingFang SC';

                            }
                        }
                    }
                }

                .bottom {
                    width: 100%;

                    .bottom_title {
                        position: relative;
                        @include height-width(32, 80);
                        margin: 27px 0 10px;
                        width: 120px;

                        .backg {
                            @include height-width(23, 19);
                            position: absolute;
                            left: 0;
                            background: url('../../../../images/about/pic_about_decorate.png') no-repeat;
                            background-size: 100% 100%;
                        }

                        .title {
                            @include font(30, 32, #1a1a1a, 600);
                            font-family: 'PingFangSC-Semibold, PingFang SC';

                        }
                    }

                    .bottom_text {
                        font-size: 28px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #383838;
                        line-height: 52px;
                    }
                }
            }

            .modules_1_right_img {
                padding-top: 20px;
                width: 100%;

                .img_about_1 {
                    width: 100%;
                }
            }
        }

        .modules_2 {
            width: 100%;

            .modules_2_bottom {
                width: 100%;
                height: 764px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-wrap: wrap;

                .modules_2_li {
                    width: calc(100% / 2);
                    height: calc(100% /3);
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    padding: 0 62px;
                    box-sizing: border-box;
                    border: 1px solid #fff;
                    text-align: center;

                    &:hover {
                        background: #811c2bbe;
                    }

                    .name {
                        @include font(28, 40, #ffffff, 600);
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        margin-bottom: 16px;
                    }

                    .describe {
                        @include font(18, 32, #ffffff, 400);
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        width: 100%;

                    }
                }
            }
        }
    }

    .course {
        width: 100%;
        height: 100%;
        // background: url(../../../../images/about/bg_about_develop.png) no-repeat;
        background-size: 100% 100%;
        // margin-bottom: 100px;
    }

    .create_box {
        padding: 0 30px;

        .ul {

            display: flex;
            overflow-x: scroll;

            .parament_li {
                margin-right: 20px;
                width: 268px;
                height: 376px;
                display: flex;
                flex-direction: column;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.167);
                }


                .parament_li_box {
                    width: 268px;
                    height: 376px;
                    padding: 50px 40px;
                    box-sizing: border-box;
                    z-index: 9;

                    .title {
                        font-size: 22px;
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 25px;
                        padding-bottom: 15px;
                    }

                    .children_box {
                        font-size: 18px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 34px;
                        white-space: nowrap;
                    }
                }
            }
        }

    }

    .public_margin_top_bottom_font {
        margin: 80px 0 40px;
        @include font(40, 56, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
        text-align: center;
    }
}
</style>
