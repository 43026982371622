<template>
    <div class="yd_rd_shiyan">
        <div class="public-title">试验范畴</div>
        <div class="public-padding-left-right">
            <div class="box">
                <div class="top_box">
                    <div :class="['li', parent_index == index ? 'active_li' : '']" v-for="(item, index) in list"
                        :key="index" @mouseenter="parent_index = index">
                        {{ item.title }}
                    </div>
                </div>
                <template v-for="(item, index) in list">
                    <div class="bottom_box" v-if="parent_index == index" :key="index">
                        <div class="top">

                            <div class="bottom_box_text" style="white-space: pre-wrap;" v-html="item.smText">

                            </div>
                        </div>
                        <div class="bottom">
                            <img class="sm_img" :src="item.smImg" alt="">
                        </div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {
            list: [],
            parent_index: 0,
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_shiyan')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.yd_rd_shiyan {
    width: 100%;
    background: #F7F7F7;

    // .public-padding-left-right {
    //     padding: 0 260px;
    //     box-sizing: border-box;
    // }

    .public-title {
        text-align: center;
        @include font(36, 32, #1a1a1a, 600);
        padding: 120px 0 60px 0;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 80px;

        .top_box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 40px;

            .li {
                width: 48%;
                padding: 23px 30px;
                box-sizing: border-box;
                margin-bottom: 3px;
                background: #fff;
                @include font(24, 40, #151515, 500);

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // &:hover {
                //     cursor: pointer;
                // }
            }

            .active_li {
                background: var(--bg-colors);
                @include font(24, 40, #fff, 500);
            }
        }

        .bottom_box {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background: #fff;
            display: flex;
            flex-direction: column;
            padding: 30px 40px 80px;
            box-sizing: border-box;

            .top {
                width: 100%;

                // .bottom_box_title {
                //     @include font(28, 52, #151515, 600);
                //     font-family: 'PingFangSC-Semibold, PingFang SC';

                // }

                .bottom_box_text {
                    @include font(24, 40, #444452, 400);
                    padding-bottom: 30px;
                }
            }

            .bottom {
                width: 100%;

                .sm_img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
