<template>
    <div class="about_banner">
        <div class="pc_banner">
            <img class="banner_img" :src="images" alt="">
            <div class="title">{{ title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: String
        },
        title: {
            type: String
        }
    },

    data() {
        return {
        }
    },

    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_banner {
    width: 100%;

    .pc_banner {
        width: 100%;
        position: relative;

        .banner_img {
            width: 100%;

            vertical-align: middle;
        }

        .title {
            position: absolute;
            left: 260px;
            top: 50%;
            transform: translate(0, -50%);
            @include font(40, 52, #fff, 600);
            font-family: "PingFangSC-Semibold, PingFang SC";

        }
    }

}
</style>
