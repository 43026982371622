<template>
    <div class="type-arae">
        <div class="about-1" v-if="key == 'jianjie'">
            <aboutJianJie></aboutJianJie>
        </div>
        <div class="about-2" v-if="key == 'rongyu'">
            <aboutRongYu></aboutRongYu>
        </div>
        <div class="about-3" v-if="key == 'buju'">
            <aboutBuju></aboutBuju>
        </div>
        <div class="about-4" v-if="key == 'xinwen-huodong'">
            <pcAboutXinwenHuodong></pcAboutXinwenHuodong>
        </div>
        <div class="about-5" v-if="key == 'zeren'">
            <pcAboutZeren></pcAboutZeren>
        </div>
    </div>
</template>

<script>
import aboutJianJie from '@/views/pc/components/about/pc_about_jianjie.vue'
import aboutRongYu from '@/views/pc/components/about/pc_about_rongyu.vue'
import aboutBuju from '@/views/pc/components/about/pc_about_buju.vue'
import pcAboutXinwenHuodong from '@/views/pc/components/about/pc_about_xinwen-huodong.vue'
import pcAboutZeren from '@/views/pc/components/about/pc_about_zeren.vue'
export default {
    name: '',
    components: { aboutJianJie, aboutRongYu, aboutBuju, pcAboutXinwenHuodong, pcAboutZeren },
    data() {
        return {
            key: ''
        }
    },
    created() {
        this.key = this.$route.params.key;
    },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";
</style>
