import axios from "axios";
// import nav from "./nav";

const requestUrl = axios.create({
  baseURL: '/haili',
  timeout: 5000,
});

requestUrl.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

requestUrl.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default requestUrl;
