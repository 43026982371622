import { render, staticRenderFns } from "./yd_baogao-gonggao.vue?vue&type=template&id=dc9c7272&scoped=true&"
import script from "./yd_baogao-gonggao.vue?vue&type=script&lang=js&"
export * from "./yd_baogao-gonggao.vue?vue&type=script&lang=js&"
import style0 from "./yd_baogao-gonggao.vue?vue&type=style&index=0&id=dc9c7272&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9c7272",
  null
  
)

export default component.exports