<template>
    <div class="pc_touzi_info">
        <div class="title">
            公司信息
        </div>
        <div class="box">

            <div class="top_company">
                <div class="company_li" v-for="(item, index) in company_info" :key="index">
                    <div class="li_box">
                        <div class="company_title">
                            <img class="icon" :src="item.img" alt="">
                            <span class="text">{{ item.title }}</span>
                        </div>
                        <div class="company_name">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_img">
                <img class="show_lgImg" :src="showLgImg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: '',
    components: {},
    data() {
        return {
            company_info: [],

        }
    },
    props: {
        showLgImg: {
            type: String
        }
    },
    computed: {
        ...mapGetters([
            "company",
            "info",
            "company"]),
    },
    mounted() {
        this.getInit()
    },
    methods: {
        getInit() {
            let arr = this.company.gupiao.split('|')
            let title = ['上市时间', '证券类别', '联系地址']
            let img = [
                require('../../../../images/touzi/icon_message_1.png'),
                require('../../../../images/touzi/icon_message_2.png'),
                require('../../../../images/touzi/icon_message_3.png')
            ]

            let company1 = []

            let company2 = [
                {
                    img: require('../../../../images/touzi/icon_message_4.png'),
                    title: '投资者专线',
                    content: this.info.touziPhone
                },
                {
                    img: require('../../../../images/touzi/icon_message_5.png'),
                    title: '联系传真',
                    content: this.info.fax
                },
                {
                    img: require('../../../../images/touzi/icon_message_6.png'),
                    title: '联系邮箱',
                    content: this.info.touziEmail
                },
            ]
            arr.forEach((item, index) => {
                company1.push({
                    img: img[index],
                    title: title[index],
                    content: item
                })
            });

            this.company_info = [

                ...company1, ...company2,
            ]
            console.log(this.company_info)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_touzi_info {
    width: 100%;

    .title {
        @include font(36, 50, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
        padding: 80px 0 40px;
        text-align: center;
    }

    .box {
        display: flex;
        background-size: 100% 100%;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .bottom_img {
            width: 100%;


            .show_lgImg {
                width: 100%;
            }
        }

        .top_company {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
            box-sizing: border-box;

            background: url('../../../../images/touzi/bg_message.png') no-repeat;

            .company_li {
                width: calc(100% / 2 - 94px);
                height: 50%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 20px 0 20px 94px;
                position: relative;



                .company_title {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    position: relative;

                    .icon {
                        @include height-width(30, 30);
                        position: absolute;
                        left: -38px;
                    }

                    .text {
                        @include font(28, 40, #333, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';

                    }
                }



                .company_name {
                    padding-top: 8px;
                    @include font(24, 33, #999, 400);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                    width: 200px;

                    word-wrap: break-word;
                    word-break: normal;
                }
            }
        }
    }

}
</style>
