<template>
    <div class="pc_touzi_stock ">
        <div class="top">
            <div class="stock_title">股价信息</div>
            <div class="box">
                <div class="one_line">
                    <span class="stock_name">股票简称：{{ dapandata.name }}</span>
                    <span>证券代码：{{ data.no }}</span>
                </div>
                <div class="two_line">
                    <span class="stock_dot">{{ dapandata.dot }}</span>
                    <span class="stock_nowpic">
                        <img class="icon_up" src="../../../../images/touzi/icon_up.png" alt="">
                        <span>{{ dapandata.nowPic }}</span>
                        <span>({{ dapandata.rate }})</span>
                    </span>
                </div>
            </div>
            <div class="three_line">
                <span class="stock_timer">截至{{ data.date }} {{ data.time }} 北京时间*报价有15分钟以上延迟</span>
            </div>
        </div>
        <div class="bottom">
            <div class="stock_box">
                <div class="li">
                    <span>今开</span>
                    <span class="num">{{ data.todayStartPri }}</span>
                </div>
                <div class="li">
                    <span>昨收</span>
                    <span class="num">{{ data.yestodEndPri }}</span>
                </div>
                <div class="li">
                    <span>最高</span>
                    <span class="num">{{ data.todayMax }}</span>
                </div>
                <div class="li">
                    <span>最低</span>
                    <span class="num">{{ data.todayMin }}</span>
                </div>
                <div class="li">
                    <span>成交量</span>
                    <span class="num">{{ dapandata.traNumber }}</span>
                </div>
                <div class="li">
                    <span>成交额</span>
                    <span class="num">{{ dapandata.traAmount }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            dapandata: {},
            data: {},
        }
    },
    created() { },
    mounted() {
        this.getInit()

    },
    methods: {
        async getInit() {
            const res = await this.$API.stockApi()
            if (res.data.data.resultcode == '200') {
                this.dapandata = res.data.data.result[0].dapandata
                this.data = res.data.data.result[0].data
            }
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_touzi_stock {
    background: url(../../../../images/touzi/bg_invest_gujia.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 758px;
    display: flex;
    flex-direction: column;
    padding: 80px 40px 101px;
    box-sizing: border-box;

    .top {
        width: 100%;

        .stock_title {
            text-align: center;
            @include font(36, 50, #fff, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding-bottom: 60px;
        }

        .box {
            display: flex;
            align-items: center;

            .one_line {
                @include font(28, 40, #fff, 500);
                font-family: 'PingFangSC-Medium, PingFang SC';
                display: flex;
                flex-direction: column;
                padding-right: 193px;

                .stock_name {
                    padding: 0 30px 20px 0;
                }
            }

            .two_line {
                // padding-top: 28px;
                @include font(24, 33, rgba(255, 255, 255, 0.7), 400);
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                // justify-content: flex-end;

                .stock_dot {
                    @include font(50, 60, #fff, 600);
                    font-family: 'Inter-SemiBold, Inter';
                    padding-right: 5px;
                }

                .stock_nowpic {
                    .icon_up {
                        padding-right: 5px;
                        @include height-width(16, 16)
                    }
                }
            }
        }


        .three_line {
            padding-top: 40px;
            @include font(22, 33, rgba(255, 255, 255, 0.7), 400);
            font-family: 'PingFangSC-Medium, PingFang SC';
            padding-bottom: 80px;
        }
    }

    .bottom {
        flex: 1;



        .stock_box {
            @include font(16, 22, rgba(255, 255, 255, 0.8), 400);
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;

            .li {
                display: flex;
                flex-direction: column;
                align-items: center;
                // text-align: left;
                width: calc(100% / 4);
                padding-bottom: 60px;
                @include font(28, 40, #fff, 400);

                .num {
                    padding-top: 16px;
                    @include font(24, 33, #fff, 400);
                }
            }
        }
    }
}
</style>
