<template>
	<div>
		<div class="index-img-box">
		  <div class="swiper-button-next swiper-right-icon"><span class="jt"></span></div>
		  <div class="swiper-button-prev swiper-left-icon"><span class="jt"></span></div>
		  <div style="width: 1340px;overflow: hidden;">
		    <div style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff" class="swiper mySwiper2">
		      <div class="swiper-wrapper">
		        <div class="swiper-slide" v-for="(item, index) in $store.state.homeProductList" :key="index">
		            <div class="swiper_cont">
		              <div class="img">
		                <img class="swiper-img" :src="item.lgImg" alt="" />
		              </div>
		              <div class="swiper_text">
		                <div class="text_box">
		                  <h4>{{ item.title }}</h4>
		                  <p v-html="item.text.replace(/\n/g, '<br /><br />').replace(/-/g, `<span></span>`)"></p>
		                </div>
		              </div>
		            </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <div :style="`width: ${$store.state.homeProductList.length*170}px;overflow: hidden;margin: 40px auto 0;`">
		    <div thumbsSlider="" class="swiper mySwiper">
		      <div class="swiper-wrapper">
		        <div class="swiper-slide swiper-slide1" @click="goToSlide(index)" v-for="(item, index) in $store.state.homeProductList" :key="index">
		          <img class="swiper-img" :src="item.lgImg" alt="" />
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
</template>

<script>
	import Swiper from './myswiper/swiper-bundle.min.js'; // 注意引入的是Swiper
	import './myswiper/swiper-bundle.min.css' // 注意这里的引入
	let ii = -1
	export default {
		data() {
			return {
				swiper1: null,
			}
		},
		mounted() {
			setTimeout(() => {
				this.swiper1 = new Swiper(".mySwiper", {
					autoplay: {
						delay: 2500,
					},
				  loop: true,
				  spaceBetween: 20,
				  slidesPerView: this.$store.state.homeProductList.length > 3 ? 4 : this.$store.state.homeProductList.length,
				  freeMode: true,
				  watchSlidesProgress: true,
				});
				var swiper2 = new Swiper(".mySwiper2", {
				  loop: true,
					autoplay: {
						delay: 2500,
					},
				  spaceBetween: 10,
				  navigation: {
				    nextEl: ".swiper-button-next",
				    prevEl: ".swiper-button-prev"
				  },
				  thumbs: {
				    swiper: this.swiper1,
				  },
				});
				// 监听鼠标悬停事件
				swiper2.el.onmouseover = () => {
				  swiper2.autoplay.stop();
				  this.swiper1.autoplay.stop();
				}
				
				// 监听鼠标离开事件
				swiper2.el.onmouseleave = () => {
				  swiper2.autoplay.start();
				  this.swiper1.autoplay.start();
				}
				// 监听鼠标悬停事件
				this.swiper1.el.onmouseover = () => {
				  swiper2.autoplay.stop();
				  this.swiper1.autoplay.stop();
				}
				
				// 监听鼠标离开事件
				this.swiper1.el.onmouseleave = () => {
				  swiper2.autoplay.start();
				  this.swiper1.autoplay.start();
				}
			}, 1000)
		},
		methods: {
			goToSlide(i) {
				console.log(i,ii);
				if (i > 2) {
					if (i > ii) {
						this.swiper1.slideNext();
					} else {
						this.swiper1.slidePrev();
					}
				}
				ii = i
			}
		},
	}
</script>

<style scoped lang="scss">
@import "@/utils/css/mixin.scss";
.swiper_cont{
  width: 100%;
  display: flex;
  background: #f1f1f1;
  height: 540px;
  .img{
    width: 50%;
    display: flex;
    @include text-center;
    height: 540px;
    background: #fff;
  }
  .swiper_text{
    width: 50%;
    height: 540px;
    text-align: left;
    display: flex;
    h4{
      font-size: 32px;
      // font-family: 'PingFangSC-Semibold, PingFang SC';
      font-weight: 600;
      color: #333;
      line-height: 38px;
      padding: 0 60px;
      margin: 70px 0 30px;
    }
    p {
      padding: 6px 60px;
      color: #7b7b7b;
      line-height: 26px;
    /deep/ span {
        width:6px;
        height: 6px;
        font-size: 0;
        line-height: 0;
        background: #7b7b7b;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}
.swiper-button-prev:after,.swiper-button-next:after{
  content: none;
}
.swiper-button-prev{
  left: -100px;
}
.swiper-button-next{
  right: -100px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}
.mySwiper2 {
  height: 540px;
  width: 100%;
  .swiper-slide{
    width: 100%;
  }
}

.mySwiper {
  height: 140px;
  box-sizing: border-box;
  padding: 10px 0;
  display: flex;
  @include text-center;
}

.mySwiper .swiper-slide {
  height: 100%;
  background:#FFF;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 5px solid var(--bg-colors);
  box-sizing: border-box;
  background: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-left-icon {
  border-radius: 50%;
  border: 1px solid #ccc;
  @include height-width(72, 72);
  @include text-center;
  background: #fff;
  &:hover {
    cursor: pointer;
  }
  .jt {
    display: inline-block;
    @include height-width(15, 15);
    border-top: 3px solid #ccc;
    border-left: 3px solid #ccc;
    box-sizing: border-box;
    transform: rotate(-45deg)
  }
}

.swiper-right-icon {
  border: 1px solid #ccc;
  border-radius: 50%;
  @include text-center;
  @include height-width(72, 72);
  
  background: #fff;
  &:hover {
    cursor: pointer;
  }

  .jt {
    display: inline-block;
    @include height-width(15, 15);
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    box-sizing: border-box;
    transform: rotate(-45deg)
  }
}
.swiper-slide {
    text-align: center;
    line-height: 100%;
    color: #b0b0b0;
}
.swiper-slide1 {
	width: 12%;
}
.index-img-box {
	// width: 1340px;
	margin: 60px 100px 0;
	height: 710px;
	position: relative;
	.index-img-ul {
	  width: 100%;
	  display: flex;
	  flex-wrap: wrap;
	  position: relative;
	  /deep/ .el-carousel__indicators {
		// 指示器
		display: none;
	  }

	  /deep/ .el-carousel {
		width: 100%;

		.el-carousel__item {
		  width: 100% !important;
		  display: flex;
		  flex-wrap: wrap;

		  .swiper-img {
			max-width: 320px;
			height: 260px;
		  }

		  .swiper-text {
			font-size: 18px;
			font-family: 'PingFangSC-Regular, PingFang SC';
			font-weight: 400;
			color: #333333;
			line-height: 25px;
			box-sizing: border-box;
			padding-top: 10px;
		  }

		  .index-img-li {
			width: 25%;
			height: 310px;
			padding: 10px 10px 8px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;

			&:nth-child(1) {
			  padding-left: 0;
			}

			&:nth-child(4) {
			  padding-right: 0;
			}

			&:nth-child(4) {
			  padding-right: 0;
			}

			&:nth-child(5) {
			  padding-left: 0;
			}

			&:nth-child(8) {
			  padding-right: 0;
			}


		  }
		}
	  }

	 
	}
	}
</style>