<template>
    <div v-if="tabs_list.length > 0" class="about_timer" :style="`background-image:url(${tabs_list[0].smImg})`">
        <div class="top_tabs">
            <div class="tabs" ref="tabs">
                <div class="tabs_ul" ref="tabsUl">
                    <div class="left" @click="delTabs()"></div>
                    <div class="tabs_li" ref="tabsTitle" @click="changeTabs(item, index)" v-for="(item, index) in tabs_list"
                        :key="index">
                        <span :class="['tabs_title', tabs_index == index ? 'active_title' : '']">
                            {{ item.dateTime }} </span>
                    </div>
                    <div class="right" @click='addTabs()'></div>
                </div>
            </div>
        </div>
        <div class="content_tabs" ref="contentTab">
            <div class="content_ul" ref="contentUl">
                <div ref="contentLi" :class="['content_li', tabs_index == index ? 'active' : '']"
                    v-for="(item, index) in tabs_list" :key="index">
                    <div class="content_year">
                        {{ item.dateTime }}
                        <span class="icon" v-if="tabs_index == index">

                        </span>
                    </div>
                    <div class="content_title" style="white-space: pre-wrap;" v-html="item.lgText">

                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>

import moment from "moment";
export default {

    data() {
        return {
            tabs_index: 0,
            tabs_list: [],
						st: null
        }
    },
    created() {
        this.getInit()
    },
		mounted() {
			this.st = setInterval(() => {
				this.addTabs()
			},2000)
		},
    methods: {
        async getInit() {
            const res = await this.$API.listApi('about_fazhanlicheng')

            if (res.status == 200) {
                this.tabs_list = res.data.data
                console.log('3333', this.tabs_list[0])
            }
            this.tabs_list.forEach(item => {
                item.dateTime = moment(item.dateTime).format("YYYY")
            })
        },
        //点击tab栏 函数
        addTabsFun() {
						console.log(33234234234);
            let containerW = this.$refs.tabsUl.offsetWidth
            let tabsTitle = this.$refs.tabsTitle[this.tabs_index]
            var scrollLeftNum = tabsTitle.offsetLeft - containerW / 1.3 + tabsTitle.offsetWidth / 1.3
            this.$refs.tabs.scrollLeft = scrollLeftNum
        },
        contentTabsFun() {
            let contentTab = this.$refs.contentTab.offsetWidth
            let contentLi = this.$refs.contentLi[this.tabs_index]
            var contentNum = contentLi.offsetLeft - contentTab / 1.3 + contentLi.offsetWidth / 1.3
            this.$refs.contentUl.scrollLeft = contentNum
        },
        // tab点击事件
        changeTabs(item, index) {
            this.tabs_index = index
            this.addTabsFun()
            this.contentTabsFun()
        },
        delTabs() {
            if (this.tabs_index == 0) {
                this.tabs_index = this.tabs_list.length - 1
                this.addTabsFun()
                this.contentTabsFun()
            } else {
                this.tabs_index = this.tabs_index - 1
                this.addTabsFun()
                this.contentTabsFun()
            }

        },
        addTabs() {
            if (this.tabs_index == this.tabs_list.length - 1) {
                this.tabs_index = 0
                this.addTabsFun()
                this.contentTabsFun()
            } else {
                this.tabs_index = this.tabs_index + 1
                this.addTabsFun()
                this.contentTabsFun()
            }
        }

    },
		beforeDestroy() {
			clearInterval(this.st)
		}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_timer {
    width: 100%;
    height: 690px;
    padding: var(--padding-left-right);
    box-sizing: border-box;
    background-size: 100% 100%;
    // background: no-repeat;

    .top_tabs {
        width: 100%;
        background: #fff;
        height: 100px;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        .tabs {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 70%;
            margin: auto;

            .tabs_ul {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // padding-bottom: 100px;

                .tabs_li {
                    flex-shrink: 0;
                    @include text-center;
                    width: 20%;

                    &:hover {
                        cursor: pointer;
                    }


                    .tabs_title {
                        font-family: 'AlibabaPuHuiTiB';
                        @include font(32, 44, #811c29b1);
                    }

                    .active_title {
                        font-family: 'AlibabaPuHuiTiB';
                        @include font(40, 55, var(--bg-colors));
                    }
                }
            }



        }

        .left,
        .right {
            @include height-width(30, 30);
            // border: 3px solid #111;
            position: absolute;
            @include text-center;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: '';
                // position: absolute;
                // top: 0;
                display: inline-block;
                border: 3px solid #111;
                @include height-width(15, 15);
                transform: rotate(45deg)
            }
        }

        .left {
            left: 80px;

            &::after {
                content: '';
                border-top: 0;
                border-right: 0;
            }
        }

        .right {
            right: 80px;

            &::after {
                content: '';
                border-bottom: 0;
                border-left: 0;
            }
        }
    }

    .content_tabs {
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: space-between;

        .content_ul {
            display: flex;
            overflow: hidden;
            width: 100%;
        }

        .content_li {
            width: 33.3%;
            flex-shrink: 0;
        }

        .active {
            background: #fff;
            width: 33.3%;

            .content_year {
                position: relative;

                .icon {
                    display: inline-block;
                    position: absolute;
                    left: 52px;
                    bottom: -8px;
                    width: 13px;
                    height: 13px;
                    border-radius: 13px;
                    border: 1px solid var(--bg-colors);
                    box-sizing: border-box;
                    @include text-center;

                    &::after {
                        content: '';
                        width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        background: var(--bg-colors);
                    }
                }


            }
        }

        .content_year {
            padding: 40px 52px 29px;
            font-family: 'AlibabaPuHuiTiB';
            @include font(32, 44);
            border-bottom: 1px solid #D9D9D9;
        }

        .content_title {
            padding: 30px 52px 94px;
            font-family: 'AlibabaPuHuiTiB';
            @include font(18, 22, #444452);
        }
    }
}
</style>
