<template>
    <div class="pc_about_zeren">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>
        <div class="zeren_ul">
            <div class="zeren_li">
                <div class="text" style="white-space: pre-wrap;" v-html="public_banner_info.showText"></div>
                <img class="image" :src="public_banner_info.showLgImg" alt="">
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import banner from '../banner.vue'
export default {
    components: { banner },
    data() {
        return {
            init_list: []
        }
    },
    computed: {
        ...mapGetters(["public_banner_info"]),
    },
    mounted() {
        this.key = this.$route.params.key;
        localStorage.setItem('xinwen', this.key)
        this.$store.dispatch('getPageHeader', this.key)
        this.getNewList()
    },
    methods: {
        // getNewList() {
        //     this.$store.dispatch('getNew', { newsType: this.newsType, pageNum: this.pageNum, pageSize: this.pageSize })
        // },
        async getNewList() {
            const res = await this.$API.listApi('about_zeren')
            if (res.data.meta.code == 200) {
                this.init_list = res.data.data
            }
        },


    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_about_zeren {
    width: 100%;
    height: 100%;

    background: #FAFAFA;

    .zeren_ul {
        width: 100%;
        padding: var(--padding-left-right);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;

        .zeren_li {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0 30px 30px 0;

            .text {
                font-size: 22px;
                color: #777;
                line-height: 45px;
            }

            .image {
                padding-top: 80px;
            }
        }
    }


}
</style>
