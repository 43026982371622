<template>
    <div class="pc-jion">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>
        <div class="rencai-box">
            <div id="rencai" class="jion-nav ">

                <div class="nav" :style="`background-image:url(${public_banner_info.showLgImg})`">
                    <div class="title">{{ public_banner_info.showTitle }}</div>
                    <div class="describe">
                        {{ public_banner_info.showText }}</div>
                </div>
            </div>
        </div>

        <!-- 内容 -->
        <div class="content-box">
            <div class="li" v-for="(item, index) in modules_one_list" :key="index">
                <div class="content-title">
                    {{ item.title }}
                </div>
                <div class="content-describe" style="white-space: pre-wrap;" v-html="item.smText">

                </div>
                <div class="content-image">
                    <img :src="item.smImg" alt="">
                </div>
            </div>
        </div>
        <!-- 招聘 -->
        <div class="zhaopin-box " id="zhaopin">
            <div class="title">
                员工招聘
            </div>
            <div class="zhaopin">
                <div class="box" v-for="(item, index) in modules_two_list" :key="index" @click="goPage(item)">
                    <div class="left">
                        <img class="sm-img" :src="item.smImg" alt="">
                    </div>
                    <div class="right">
                        <div class="zhaopin-title">
                            {{ item.title }}
                        </div>
                        <div class="zhaopin-describe">
                            {{ item.smText }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/views/yd/components/banner.vue'

import { mapGetters } from "vuex";
export default {
    name: '',
    components: { banner },
    data() {
        return {
            key: '',
            modules_one_list: [], // 人尽其才
            modules_two_list: [], // 员工招聘
            public_banner_info: []
        }
    },
    computed: {
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.key = val.path.split('/')[1]
            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {

        async getInit() {
            this.$store.dispatch('getPageHeader', 'rencai').then(res => {
                this.public_banner_info = res
            })
            let meuns_zhaopin = []
            const modules_one_list = await this.$API.listApi('jion_rencai')
            if (modules_one_list.data.meta.code == 200) {
                this.modules_one_list = modules_one_list.data.data
            }
            this.$store.state.menus.forEach(pit => {
                pit.menus.forEach(cit => {
                    if (cit.menus) {
                        cit.menus.forEach(git => {
                            if (git && git.pmkey == "zhaopin") {
                                meuns_zhaopin.push(git)
                            }
                        })
                    }

                })
            })
            const modules_two_list = await this.$API.listApi('jion_zhaopin')
            if (modules_two_list.data.meta.code == 200) {
                modules_two_list.data.data.forEach((item, index) => {
                    this.modules_two_list.push({
                        mkey: meuns_zhaopin.length ? meuns_zhaopin[index].mkey : '',
                        ...item
                    })
                })
            }
        },
        goPage(item) {
            if (item.mkey == 'shehui') {
                localStorage.setItem('jobType', 1)
            } else {
                localStorage.setItem('jobType', 2)
            }
            this.$router.push({
                path: `/jion-page/${item.mkey}`,
                params: {
                    key: item.mkey
                }
            })
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-jion {
    width: 100%;

    padding: 1px 0;
    box-sizing: border-box;

    .rencai-box {
        padding: 80px 30px 60px;
        box-sizing: border-box;

        .jion-nav {
            width: 100%;

            .nav {

                padding: 60px 55px;
                box-sizing: border-box;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 100%;

                .title {
                    padding-bottom: 20px;
                    text-align: center;
                    @include font(28, 40, #fff, 600);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                }

                .describe {
                    text-align: center;
                    // padding: 23px 250px 0;
                    box-sizing: border-box;
                    @include font(24, 40, #fff, 400);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                }
            }
        }
    }


    .content-box {
        // margin-bottom: 100px;
        padding: 0 30px;

        .li {
            .content-title {
                font-size: 36px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 50px;
                padding-bottom: 20px;
            }

            .content-describe {
                font-size: 24px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #383838;
                line-height: 40px;
                padding-bottom: 40px;
            }

            .content-image {
                width: 100%;
                padding-bottom: 60px;

                img {
                    width: 100%;
                }
            }
        }
    }

    .zhaopin-box {
        width: 100%;
        background: #F7F7F7;
        padding: 0 30px;
        box-sizing: border-box;

        .title {
            @include font(40, 56, #1a1a1a, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding: 80px 0 0;
            text-align: center;
        }

        .zhaopin {
            width: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            padding-bottom: 100px;

            .box {
                width: 100%;
                background: #FFFFFF;
                display: flex;
                padding: 60px;
                box-sizing: border-box;
                position: relative;
                margin-top: 40px;

                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: url(../../../../images/bg_zhaopin.png) no-repeat;
                    background-size: 100% 100%;
                }

                .left {
                    z-index: 5;

                    .sm-img {
                        @include height-width(180, 180);
                        border-radius: 50%;
                    }
                }

                .right {
                    z-index: 5;
                    padding: 26px 44px;
                    box-sizing: border-box;

                    .zhaopin-title {
                        @include font(36, 50, #424142, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        padding-bottom: 20px;
                    }

                    .zhaopin-describe {
                        @include font(16, 32, #383838, 400);
                        font-family: 'PingFangSC-Regular, PingFang SC';
                    }
                }
            }
        }
    }


}
</style>
