<template>
    <div class="type-arae pc-rd">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>
        <!-- <div class="pc-rd-1" v-if="key == 'jishu'">
            <rdJishu></rdJishu>
        </div> -->
        <div class="pc-rd-2 ">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showSmImg="public_banner_info.showSmImg"
                    :showText="public_banner_info.showText" :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <div class="pc-rd-yanfa">
                <rdYanfa :title="public_banner_info.title"></rdYanfa>
            </div>
        </div>
        <!-- <div class="pc-rd-3" v-if="key == 'shiyan'">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showSmImg="public_banner_info.showSmImg"
                    :showText="public_banner_info.showText" :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <rdShiyan :title="public_banner_info.title"></rdShiyan>
        </div> -->
        <!-- <div class="pc-rd-4" v-if="key == 'jujiao'">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showSmImg="public_banner_info.showSmImg"
                    :showText="public_banner_info.showText" :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <rdJujiao :title="public_banner_info.title"></rdJujiao>
        </div> -->
    </div>
</template>

<script>
import rdJishu from '@/views/yd/components/rd/yd_rd_jishu.vue'
import rdJujiao from '@/views/yd/components/rd/yd_rd_jujiao.vue'
import rdShiyan from '@/views/yd/components/rd/yd_rd_shiyan.vue'
import rdYanfa from '@/views/yd/components/rd/yd_rd_yanfa.vue'

import banner from '@/views/yd/components/banner.vue'
import bannerBtmDescribe from '@/views/yd/components/banner_btm_describe.vue'
export default {
    name: '',
    components: { rdJishu, rdJujiao, rdShiyan, rdYanfa, banner, bannerBtmDescribe },
    data() {
        return {
            public_banner_info: {}
        }
    },
    computed: {
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            this.$store.dispatch('getPageHeader', 'rd').then(res => {
                console.log(res)
                this.public_banner_info = res
            })
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-rd {
    @include font(14, 22, #111, 400);
    padding: 1px 0;
    box-sizing: border-box;
}
</style>
