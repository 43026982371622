<template>
    <div class="pc_about_rongyu">
        <banner :images="$store.state.public_banner_info.smImg" :title="$store.state.public_banner_info.title"></banner>
        <!-- <div class="yd_img">
            <img class="img_about_honor" :src="$store.state.public_banner_info.showSmImg" alt="">
        </div> -->
        <div class="qydsj">
            <!-- <div class="public_margin_top_bottom_font">
                企业大事记
            </div> -->
            <div class="time-line">
                <el-timeline>
                    <el-timeline-item :class="index % 2 != 0 ? 'item__wrapper' : ''" placement="top" v-for="(item, index) in activities"
                        :key="index" :timestamp="item.dateTime">
                        <div class="timer" v-html="item.lgText"></div>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
import banner from '../banner.vue'
export default {
    name: '',
    components: { banner },
    data() {
        return {
            // modulesOneObj: {},
            activities: [
                {
                    content: 1
                },
                {
                    content: 1
                },
                {
                    content: 1
                },
                {
                    content: 1
                },
                {
                    content: 1
                },
                {
                    content: 1
                },
            ],
            key: ''
        }
    },
    created() { },
    mounted() {
        this.key = this.$route.params.key;
        this.$store.dispatch('getPageHeader', this.key)
        this.getInit()
    },
    methods: {
        async getInit() {


            const res_two = await this.$API.listApi('about_qiyedashiji')
            if (res_two.status == 200) {
                this.activities = res_two.data.data
            }
            this.activities.forEach(item => {
                item.dateTime = moment(item.dateTime).format("YYYY")
            })

        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_about_rongyu {
    width: 100%;
    height: 100%;

    .public_margin_top_bottom_font {
        margin: 120px 0 82px;
        @include font(40, 56, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
        text-align: center;
    }



    .yd_img {
        width: 100%;

        .img_about_honor {
            width: 100%;
        }
    }

    .qydsj {
        width: 100%;
        background: url(../../../../images/about/bg_about_develop.png) no-repeat;
        background-size: 150% 100%;
        margin-top: 100px;

        .time-line {
            height: calc(162px * 6);
            overflow-y: scroll;
            padding-bottom: 100px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        /deep/ .el-timeline {
            padding: 0 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .el-timeline-item {
                width: 50%;
                min-height: 162px;

                .el-timeline-item__node {
                    width: 15px;
                    height: 15px;
                    border: 1px solid var(--bg-colors);
                    background: transparent;
                    left: -3px;
                    box-sizing: border-box;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        border-radius: 7px;
                        background: #811C2B;
                    }
                }

                .timer {
                	font-size: 32px;
                	@include font(20, 28, #333, 400);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                	white-space: pre-wrap;
                }
                
                .el-timeline-item__timestamp {
                    // @include font(16, 28, #333, 400);
                	@include font(36, 39, var(--bg-colors), 600);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                
                }
            }

            .item__wrapper {
            	.el-timeline-item__timestamp {
            		text-align: right;
            	}
            }
            .el-timeline-item__wrapper {
                width: 100%;
                padding-left: 29px;
            	top: -0.2rem;
                .el-timeline-item__timestamp {
                    font-size: 36px;
                }
            }
            
            .item__wrapper .el-timeline-item__wrapper {
                left: calc(-50% + -29px) !important;
                transform: translate(-50%);
            }
        }
    }
}
</style>
