<template>
    <div class="yd_index_footer">
        <div class="yd_index_top">
            <div class="header_left">
                <img class="wx" :src="$store.state.info.qrcode" alt="">
            </div>
            <div class="header_right">
                <div class="text">Copyright©{{year}}</div>
                <div class="text">海联金汇科技股份有限公司 版权所有</div>
                <a href="https://beian.miit.gov.cn/" class="text">鲁ICP备20013248号-4</a>
            </div>
        </div>
        <div class="yd_index_bottom">
            <!-- <div class="top_text">
                Copyright © 2017 海联金汇科技股份有限公司 版权所有
            </div> -->
            <div class="bottom_text">
                <a href="/legal">法律声明</a>
                <span style="margin:0 5px;"> | </span>
                <a href="/privacy">隐私保护</a>
                <span style="margin:0 5px;"> | </span>
                <a class="router" href="/relation">联系我们</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
			year: ''
        }
    },
    created() {
		this.year = new Date().getFullYear()
	},
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";



.yd_index_footer {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    background: var(--bg-colors-grey);

    .yd_index_top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;

        .header_left {
            width: 200px;

            .wx {
                width: 100%;
            }
        }

        .header_right {
            flex: 1;
            padding-left: 30px;
            @include font(24, 22, #aaa, 400);

            .text {
                padding-bottom: 20px;
            }

            a {
                @include font(24, 22, #aaa, 400);
            }
        }

    }

    .yd_index_bottom {
        padding-top: 30px;
        @include font(24, 22, #aaa, 400);

        .top_text {
            @include text-center;
            padding-bottom: 30px;
        }

        .bottom_text {
            @include text-center;
            @include font(24, 22, #666, 400);

            a {
                @include font(24, 22, #666, 400);
            }
        }
    }
}
</style>
