import pc_about from "@/views/pc/views/about/pc_about.vue";
import yd_index from "@/views/yd/views/about/yd_about.vue";
import pcAboutNewDetailc from "@/views/pc/views/about/pc_about_new_detail.vue";
import ydAboutNewDetailc from "@/views/yd/views/about/yd_about_new_detail.vue";
const about = [
  {
    path: "/about/:key",
    components: {
      default: pc_about,
      yd: yd_index,
    },
  },
  {
    path: "/news/:nid",
    name: "news",
    components: {
      default: pcAboutNewDetailc,
      yd: ydAboutNewDetailc,
    },
  },
];

export default about;
