<template>
    <div class="pc_rd_jujiao">
        <div class="public-title">四大智能驾驶辅助系统</div>
        <div class="box ">
            <div class="top_ul">
                <div :class="['top_li', active_index == index ? 'active_top_li' : '']" @mouseenter="active_index = index"
                    v-for="(item, index) in list" :key="index">
                    {{ item.title }}
                </div>
            </div>
            <template v-for="(item, index) in list">
                <div class="bottom_li" v-if="active_index == index" :key="index">
                    <div class="top">
                        <div class="top_text" style="white-space: pre-wrap;" v-html="item.smText"></div>
                    </div>
                    <div class="bottom">
                        <img class="sm_img" :src="item.smImg" alt="">
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            list: [],
            active_index: [],
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_jujiao')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_jujiao {
    width: 100%;
    background: #F7F7F7;
    padding: 0 30px 100px;
    box-sizing: border-box;


    .public-title {
        text-align: center;
        @include font(36, 32, #1a1a1a, 600);
        padding: 120px 0 60px 0;
    }

    .box {
        .top_ul {
            @include justify(space-between);
            display: flex;
            flex-wrap: wrap;

            .top_li {
                width: 48%;
                padding: 21px 56px;
                box-sizing: border-box;
                background: #fff;
                @include font(24, 40, #151515, 400);
                font-family: 'PingFangSC-Medium, PingFang SC';
                margin-bottom: 20px;
            }

            .active_top_li {
                background: var(--bg-colors);
                @include font(24, 40, #fff, 400);
                position: relative;

                &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 50%;
                    width: 57px;
                    height: 33px;
                    transform: translate(-50%, 80%);

                    border: 28px solid #000;
                    border-top-color: var(--bg-colors);
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    box-sizing: border-box;
                }
            }
        }

        .bottom_li {
            background: #fff;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px;
            box-sizing: border-box;

            .top {
                @include font(16, 32, #444452, 400);
                font-family: 'AlibabaPuHuiTiR';
                padding-bottom: 30px;

                .top_text {
                    @include font(24, 48, '#44445', 600);
                    padding-bottom: 20px;
                }


            }

            .bottom {
                width: 100%;

                .sm_img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
