<template>
    <div class="yd_jion_page-content ">
        <div class="top">
            <div class="title">在招岗位</div>
            <div class="bottom">
                <div class="select">
                    <select class="el-select" v-model="select_val" placeholder="全部工作地点">
                        <option class="el-option" v-for="(item, index) in address_list" :key="index" :label="item"
                            :value="item">
                        </option>
                    </select>
                </div>
                <div class="search">
                    <input type="text" class="search_val" v-model="search_val" placeholder="输入岗位名称">
                    <i class="iconfont icon-sousuo icon_search"></i>
                    <button class="search_btn" @click="searchVal">查询</button>
                </div>
            </div>
        </div>
        <!-- 表格 -->

        <div class="ul" v-if="tableData.length >= 1">
            <div class="li" v-for="(item, index) in tableData" @click="changTable(item)" :key="index">
                <div class="list_title">{{ item.title }}</div>
                <div class="list_box">
                    <div class="list_post">岗位类别：{{ item.category }}</div>
                    <div class="list_address">工作地点：{{ item.site }}</div>
                </div>
                <div class="list_timer">发布时间：{{ item.publishTime }}</div>
            </div>
        </div>
        <div class="dataNul" v-else>暂无数据</div>
        <!-- 分页 -->
        <div class="pag_ing" v-if="tableData.length >= 1">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-size="pageSize" :pager-count='5' layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: '',
    components: {},

    data() {
        return {
            select_val: '',
            address_list: [],
            search_val: '',
            tableData: [],
            pageNum: 1,
            pageSize: 5,
            total: 0,
            jobType: localStorage.getItem('jobType')
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        changTable(row) {
            this.$router.push({
                name: "jion-describe",
                params: {
                    jid: row.jid,
                },
            });
        },
        async getInit(search) {
            let params = {
                search: search,
                site: this.select_val,
                jobType: this.jobType,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            const res = await this.$API.jionlApi(params)
            if (res.data.meta.code == 200) {
                this.tableData = res.data.data.data
                this.total = res.data.data.total
            }
            this.tableData.forEach(item => {
                if (item.publishTime) {
                    item.publishTime = moment(item.publishTime).format("YYYY-MM-DD")
                }
            })

            const address_res = await this.$API.sitesApi()
            if (address_res.data.meta.code == 200) {
                this.address_list = address_res.data.data
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getInit()
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getInit()
        },
        searchVal() {
            this.getInit(this.search_val)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.yd_jion_page-content {
    width: 100%;
    height: 100%;
    @include font(14, 20, #666, 400);
    font-family: 'PingFangSC-Regular, PingFang SC';

    .top {
        display: flex;
        flex-direction: column;
        // margin-bottom: 40px;
        padding: 70px 30px 40px;

        .title {
            font-size: 36px;
            font-family: 'PingFangSC-Semibold, PingFang SC';
            font-weight: 600;
            color: #1A1A1A;
            line-height: 32px;
            padding-bottom: 40px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 24px;
                height: 30px;
                background: url('../../../../images/about/pic_about_decorate.png')
            }
        }

        .bottom {
            display: flex;

            .select {
                margin-right: 16px;

                .el-select {
                    width: 198px;
                    height: 60px;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;

                    .el-input {
                        width: 100%;
                        height: 100%;

                        .el-input__inner {
                            height: 100%;
                        }

                        .el-select__caret {
                            display: flex;
                            align-items: center;
                        }

                        .el-input__suffix {
                            right: 15px;
                        }
                    }
                }
            }

            .search {
                position: relative;
                display: flex;
                align-items: center;

                .search_val {
                    width: 342px;
                    height: 60px;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    @include font(14, 20, #666, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    padding-left: 44px;


                }

                .icon_search {
                    position: absolute;
                    left: 20px;
                    // top: 50%;
                    // transform: translate(0, -50%)
                }

                .search_btn {
                    width: 100px;
                    height: 60px;
                    text-align: center;
                    background: var(--bg-colors);
                    @include font(14, 20, #fff, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    border: none;
                    margin-left: 16px;
                }
            }
        }
    }

    .ul {
        width: 100%;
        padding: 40px 30px 60px;
        box-sizing: border-box;
        background: #FAFAFA;

        .li {
            padding: 40px 40px 0;
            box-sizing: border-box;
            background: #fff;

            .list_title {
                font-size: 36px;
                font-family: 'PingFangSC-Medium, PingFang SC';
                font-weight: 500;
                color: #1A1A1A;
                line-height: 50px;
                padding-bottom: 20px;
            }

            .list_box {
                display: flex;
                padding-bottom: 20px;
                border-bottom: 1px solid #eee;
                font-size: 28px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #666666;
                line-height: 40px;
            }

            .list_post {
                margin-right: 90px;
            }
        }

        .list_timer {
            padding: 40px 0;
        }
    }

    .dataNul {
        text-align: center;
        padding-bottom: 30px;
    }

    /deep/ .el-table {

        tr th div {
            margin-left: 30px !important;
        }

        tr td div {
            margin-left: 30px !important;
        }
    }

    .pag_ing {
        display: flex;
        align-items: center;

        padding: 0 40px 30px;
        box-sizing: border-box;


        /deep/ .el-pagination {
            width: 100%;
            height: 62px !important;
            margin-top: 60px;
            display: flex;

            .btn-prev,
            .btn-next {
                width: 62px;
                padding: 5px !important;
                border: 1px solid #cbcfd3;
                height: 100%;
                box-sizing: border-box;
            }

            .el-pagination__jump {
                height: 62px;
                font-size: 24px;
                font-family: AlibabaPuHuiTiR;
                color: #171721;
                line-height: 62px;

                word-break: break-all;

                .el-input {
                    width: 76px;
                    height: 62px;

                    .el-input__inner {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                        opacity: 0.6;
                        border: 1px solid #CBCFD3;
                        font-size: 18px;
                    }
                }
            }

            .el-pager {
                display: flex;
                align-items: center;
                padding: 0 10px;
            }

            .el-pager .number {
                width: 62px;
                height: 62px;
                border-radius: 4px;
                opacity: 0.6;
                border: 1px solid #CBCFD3;
                line-height: 62px;
                padding: 0 4px;
                margin-right: 10px;
                font-size: 18px;

                &:last-child {
                    margin-right: 0;
                }

                .el-icon {
                    display: flex;
                    align-items: center;

                    height: 62px;
                }

            }

            .active {
                background: var(--bg-colors);
                color: #fff;
            }
        }
    }
}
</style>
