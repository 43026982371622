import pc_jion from "@/views/pc/views/jion/pc_jion.vue";
import yd_jion from "@/views/yd/views/jion/yd_jion.vue";
import pc_jion_page from "@/views/pc/views/jion/pc_jion_page.vue";
import yd_jion_page from "@/views/yd/views/jion/yd_jion_page.vue";
import pc_jion_describe from "@/views/pc/views/jion/pc_jion_describe.vue";
import yd_jion_describe from "@/views/yd/views/jion/yd_jion_describe.vue";
const jion = [
  {
    path: "/jion",
    components: {
      default: pc_jion,
      yd: yd_jion,
    },
  },
  {
    path: "/jion-page/:key",
    name: "/jion-page",
    components: {
      default: pc_jion_page,
      yd: yd_jion_page,
    },
  },
  {
    path: "/jion-describe/:jid",
    name: "jion-describe",
    components: {
      default: pc_jion_describe,
      yd: yd_jion_describe,
    },
  },
];

export default jion;
