<template>
    <div class="pc_touzi_message ">
        <div class="message_title">投资者交流</div>
        <div class="message_box">
            <a v-for="(item, index) in touzjiaolList" :key="index" class="img_box" target="_blank" ref="noreferrer" :href="item.smText">
                <img class="img_xxpl" :src="item.smImg" alt="信息披露">
                <div class="mask"></div>
                <div class="text-box">
                    <h4>{{ item.title }}</h4>
                    <span class="read-more">
                        <span>了解更多</span>
                        <i class="el-icon-arrow-right icon_color"></i>
                    </span>
                </div>
            </a>
            <div class="message_tab" v-if="false">
                <div @click="changeTab(1)" :class="['tab_title', tab_index == 1 ? 'active' : '']">定期报告</div>
                <div @click="changeTab(2)" :class="['tab_title', tab_index == 2 ? 'active' : '']">临时公告
                </div>
            </div>
            <div class="message_tab_content" v-if="false">
                <!-- 定期报告 -->
                <div class="terminal_box" v-if="tab_index == 1">
                    <a class="terminal_li" target="_blank" :href="item.fileUrl" v-for="(item, index) in terminal_list"
                        :key="index">
                        <span class="title">{{ item.title }}</span>
                        <span class="timer">{{ item.publishTime }}</span>
                    </a>
                </div>
                <!-- 临时公告 -->
                <div class="temporary_box" v-if="tab_index == 2">
                    <a class="terminal_li" target="_blank" :href="item.fileUrl" v-for="(item, index) in terminal_list"
                        :key="index">
                        <span class="title">{{ item.title }}</span>
                        <span class="timer">{{ item.publishTime }}</span>
                    </a>
                </div>


                <div class="look_all" @click="goAllPage">查看更多></div>
            </div>
        </div>
    </div>
</template>

<script>
// import moment from 'moment'
export default {
    name: '',
    components: {},
    data() {
        return {
            tab_index: 1,
            pageNum: 1,
            pageSize: 4,
            // terminal_list: []
            touzjiaolList: []
        }
    },

    mounted() {
        // this.getInit()
        this.getTouziJiaoLiu(); // 获取投资交流内容
    },
    methods: {
        // async getInit(reportType) {
        //     let params = {
        //         reportType: reportType ? reportType : 1,
        //         pageNum: this.pageNum,
        //         pageSize: this.pageSize
        //     }
        //     const res = await this.$API.reportApi(params)
        //     if (res.data.meta.code == 200) {
        //         this.terminal_list = res.data.data.data
        //     }
        //     this.terminal_list.forEach(item => {
        //         if (item.publishTime) {
        //             item.publishTime = moment(item.publishTime).format("YYYY-MM-DD")
        //         }
        //     })
        // },
        async getTouziJiaoLiu() {
            const res = await this.$API.touzjiaolApi()
            if (res.data.meta.code == 200) {
                this.touzjiaolList = res.data.data
            }
        },
        changeTab(index) {
            this.tab_index = index
            this.getInit(index)
        },

        goAllPage() {
            this.$router.push('/baogao-gonggao')
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_touzi_message {
    width: 100%;
    background: #FAFAFA;

    .message_title {
        padding: 80px 0 40px;
        text-align: center;
        @include font(36, 50, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
    }

    .message_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .img_box{
            width: 49%;
            position: relative;
            font-size: 20px;
            .text-box h4 {
                color: #fff;
            }
            
            .text-box p {
                color: #fff;
                font-size: 1em;
            }
            
            .text-box .read-more {
                visibility: hidden;
                display: none;
            }
            .text-box {
                -webkit-transition:all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                bottom: -25px;
                padding: 0 40px;
                position: absolute;
            }
        
            .text-box p {
                opacity: 0;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                padding-right: 0;
                max-height: 70px;
                display: none;
            }
        
            .text-box .read-more {
                margin-top: 20px;
                display: inline-block;
                opacity: 0;
                color: #fff;
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                .icon_color {
                    color: #c7000b;
                    vertical-align: middle;
                }
            }
            
            .read-more:hover .icon_color{
                -webkit-transition: all 0.2s;
                transition: all 0.2s;
                margin-left: 10px;
            }
            .img_xxpl,.img_hdpt{
                width: 100%;
                float: left;
                max-height: 300px;
            }
        }
        
        .img_box .mask {
            opacity: 0;
            width:100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
        }

        .img_box:hover .text-box {
            bottom: 30px;
        }

        .img_box:hover .mask {
            opacity: 0.2;
        }
        
        .img_box:hover .text-box h4,.img_box:hover .text-box p,.img_box:hover .text-box .read-more {
            opacity: 1;
            visibility: visible;
            display: block;
        }
        .message_tab {
            margin: auto;
            display: flex;
            border-bottom: 1px solid #ECECEC;

            .tab_title {
                text-align: center;
                @include font(28, 40, #666666, 600);
                padding-bottom: 30px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                width: 50%;

                &:hover {
                    cursor: pointer;
                }
            }

            .active {
                position: relative;
                color: var(--bg-colors);

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -15px;
                    content: '';
                    display: inline-block;
                    width: 30px;
                    height: 3px;
                    background: #811C29;
                    border-radius: 2px;
                }
            }
        }

        .message_tab_content {
            width: 100%;
            padding: 40px 30px 0;
            box-sizing: border-box;

            .terminal_box,
            .temporary_box {
                display: flex;
                flex-wrap: wrap;

                .terminal_li {
                    width: 100%;
                    background: #fff;
                    padding: 40px 30px;
                    box-sizing: border-box;
                    @include font(16, 22, #333, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 20px;

                    .title {
                        font-size: 28px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #333333;
                        line-height: 40px;
                    }

                    .timer {
                        padding-top: 16px;
                        color: var(--bg-colors);
                        font-size: 24px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #811C29;
                        line-height: 33px;
                    }
                }
            }

            .look_all {
                margin: auto;
                width: 140px;
                height: 44px;
                margin-top: 40px;
                border: 1px solid #99626A;
                @include font(22, 22, #811C2B, 400);
                font-family: 'PingFangSC-Regular, PingFang SC';
                @include text-center;
                padding: 10px 30px;
                display: none;
                &:hover {
                    cursor: pointer;
                }
            }

        }

    }
}
</style>
