<template>
  <div id="app">
    <div class="pc-show">
      <div class="pc-index-header" v-if="!$route.meta.isIndex">
        <pcIndexHeader :props_meuns_index="props_meuns_index" class="type-arae"></pcIndexHeader>
      </div>
      <router-view class="pc-view"></router-view>
			<div v-if="!$route.meta.isIndex">
				<div class="pc-index-footer" v-if="metaTitle != '404页面'">
				  <pcIndexFooter @changeFoot="changeFoot" class="type-arae"></pcIndexFooter>
				</div>
			</div>
      
    </div>
    <div class="yd-show">
      <div class="yd-index-header">
        <ydIndexHeader :shade_show="shade_show" @delShade="delShade" @openShade="openShade" class="type-arae">
        </ydIndexHeader>
      </div>
      <router-view name="yd" class="yd-view"></router-view>
      <div class="yd-index-footer" v-if="metaTitle != '404页面'">
        <ydIndexFooter class="type-arae"></ydIndexFooter>
      </div>
    </div>
  </div>
</template>

<script>
import pcIndexHeader from '@/views/pc/components/index/pc_index_header.vue'
import pcIndexFooter from '@/views/pc/components/index/pc_index_footer.vue'

import ydIndexHeader from '@/views/yd/components/index/yd_index_header.vue'
import ydIndexFooter from '@/views/yd/components/index/yd_index_footer.vue'
// import ydIndexFooter from './components/index/yd/yd_index_footer.vue'
export default {
  components: { pcIndexHeader, pcIndexFooter, ydIndexHeader, ydIndexFooter },
  data() {
    return {
      props_meuns_index: 0, // 菜单索引
      shade_show: false,
      _beforeUnload_time: 0, //保存界面刷新时先执行的时间
      _gap_time: 0 //保存浏览器刷新或关闭时执行的时间s
    }
  },

  computed: {
    metaTitle() {
      return this.$route.meta.title
    }
  },
  mounted() {
    this.$store.dispatch('getHotProduct')
    this.$store.dispatch('getHomeProcut')
    this.$store.dispatch('getApiAll')
    this.$store.dispatch('getInfoApi')
    this.rem()

  },
  // watch: {
  //   //监听路由携带的参数type,mold  配置地址:src/router/index.js
  //   '$route': {
  //     //执行 val:当前最新值 old:上一刻的值
  //     handler: function (val, old) {
  //       if (val && old) {
  //         if (val.hash !== old.hash) {
  //           this.shade_show = false
  //         }
  //       }
  //       console.log(val, 'val')
  //       console.log(old, 'old')
  //     },
  //     // 立即处理 进入页面就触发
  //     immediate: true,
  //     // 深度监听 属性的变化
  //     deep: true
  //   }
  // },
  methods: {
    rem() {
      this.autoRem()

      let t = null
      window.addEventListener(
        'resize',
        function () {
          clearTimeout(t)
          t = setTimeout(this.autoRem, 100)
        },
        false
      )
      window.addEventListener(
        'pageshow',
        function (e) {
          if (e.persisted) {
            clearTimeout(t)
            t = setTimeout(this.autoRem, 100)
          }
        },
        false
      )
    },
    autoRem() {
      var doc = document.documentElement
      var w = doc.getBoundingClientRect() ? doc.getBoundingClientRect().width : 1920
      const scale = w / 1920
      doc.style.fontSize = 100 * scale + 'px'
    },
    changeFoot(index) {

      this.props_meuns_index = index
    },
    openShade(show) {
      this.shade_show = show
      document.body.style.overflow = 'hidden'
      document.body.style.width = '100%'
      document.body.style.height = '100%'
      document.body.style.position = 'fixed'
    },
    delShade(show) {
      this.shade_show = show
      document.body.style.overflow = 'scroll'
      document.body.style.width = '100%'
      document.body.style.height = '100%'
      document.body.style.position = 'relative'
    },
    // beforeunloadHandler() {
    //   this._beforeUnload_time = new Date().getTime();
    // },
    // unloadHandler(e) {
    //   this._gap_time = new Date().getTime() - this._beforeUnload_time;
    //   debugger
    //   //判断是窗口关闭还是刷新
    //   if (this._gap_time <= 5) {
    //     //如果是登录状态，关闭窗口前，移除用户

    //     this.$store.commit('changeMenus', -1)
    //   }
    // },
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/* 全局统一样式 */
html,
body,
#app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  --bg-colors: #811c29;
  --overall-max-width: 2241PX;
  --padding: 80px 260px 126px;
  --padding-left-right: 0 260px 0;
  --padding-new-left-right: 0 460px 0;
  --bg-colors-grey: #f2f2f2;
}

.yd-index-header {
  width: 100%;
  background: #fff;
  position: fixed;
  z-index: 100;
}

// .yd-view {
//   min-height: calc(100vh - 1060px);
// }

// .pc-view {
//   min-height: calc(100vh - 755px);
// }

/* 版心 */

.type-arae {
  max-width: var(--overall-max-width);
  margin: auto !important;
}

/* 公共样式 */
div span {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "微软雅黑";
  list-style: none;
}



.yd-show {
  display: none;
}

.pc-show {
  display: block;
}

@media screen and(max-width: 850px) {
  .pc-show {
    display: none !important;
  }

  .yd-show {
    display: block !important;
    height: 100%;
    min-height: 100vh
  }
}
</style>
