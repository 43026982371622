<template>
    <div class="yd_touzi ">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>
        <!-- 公司信息 -->
        <div class="yd-touzi-info">
            <ydTouziInfo :showLgImg="public_banner_info.showLgImg"></ydTouziInfo>
        </div>
        <!-- 股价信息 -->
        <div class="yd-touzi-share">
            <ydTouziStock></ydTouziStock>
        </div>
        <!-- 信息披露 -->
        <div class="yd-touzi-message">
            <ydTouziMessage></ydTouziMessage>
        </div>
    </div>
</template>

<script>
import banner from '@/views/yd/components/banner.vue'
import ydTouziInfo from '@/views/yd/components/touzi/yd_touzi_info.vue'
import ydTouziStock from '@/views/yd/components/touzi/yd_touzi_stock.vue'
import ydTouziMessage from '@/views/yd/components/touzi/yd_touzi_message.vue'
import { mapGetters } from "vuex";
export default {
    name: '',
    components: { banner, ydTouziInfo, ydTouziStock, ydTouziMessage },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(["public_banner_info"]),
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.key = val.path.split('/')[1]

            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            this.$store.dispatch('getPageHeader', this.key)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.yd_touzi {
    width: 100%;
    @include font(14, 22, #111, 400);
    padding: 1px 0;
    box-sizing: border-box;


    .yd-touzi-info {
        padding-bottom: 80px;
        background: #fff;
    }

    .yd-touzi-message {
        padding-bottom: 80px;
        background: #FAFAFA;
    }
}
</style>
