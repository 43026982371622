<template>
    <div class="pc_baogao-gonggao type-arae">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>
        <div class="baogao_tab">
            <div @click="changeTab(1)" :class="['tab_title', tab_index == 1 ? 'active' : '']">定期报告</div>
            <div @click="changeTab(2)" :class="['tab_title', tab_index == 2 ? 'active' : '']">临时公告
            </div>
        </div>
        <div class="baogao_content public-padding-left-right">
            <!-- 定期报告 -->
            <div class="terminal_box" v-if="tab_index == 1">
                <a class="li" target="_blank" :href="item.fileUrl"
                    :style='index % 2 == 0 ? `background-color:#EBEBEB"` : "background-color:#FAFAFA"'
                    v-for="(item, index) in terminal_list" :key="index">
                    <div class="left">
                        <span class="title">{{ item.title }}</span>
                        <span class="icon"> <img src="../../../../images/touzi/icon_pdf.png" alt=""></span>
                    </div>
                    <span class="timer">{{ item.publishTime }}</span>
                </a>
            </div>
            <!-- 临时公告 -->
            <div class="temporary_box" v-if="tab_index == 2">
                <a class="li" target="_blank" :href="item.fileUrl" v-for="(item, index  ) in terminal_list" :key="index">
                    <div class="left">
                        <span class="title">{{ item.title }}</span>
                        <span class="icon"> <img src="../../../../images/touzi/icon_pdf.png" alt=""></span>
                    </div>
                    <span class="timer">{{ item.publishTime }}</span>
                </a>
            </div>
        </div>
        <div class="pag_ing" v-if="terminal_list.length >= 1">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import banner from '@/views/pc/components/banner.vue'
import moment from 'moment'
import { mapGetters } from "vuex";
export default {
    name: '',
    components: { banner },
    data() {
        return {
            key: '',
            tab_index: 1,
            terminal_list: [],
            pageNum: 1,
            pageSize: 10,
            total: 0,
        }
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.key = val.path.split('/')[1]
            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    computed: {
        ...mapGetters(["public_banner_info"]),
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit(reportType) {
            this.$store.dispatch('getPageHeader', this.key)
            let params = {
                reportType: reportType ? reportType : 1,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            const res = await this.$API.reportApi(params)
            if (res.data.meta.code == 200) {
                this.terminal_list = res.data.data.data
                this.total = res.data.data.total
            }
            this.terminal_list.forEach(item => {
                if (item.publishTime) {
                    item.publishTime = moment(item.publishTime).format("YYYY-MM-DD")
                }
            })
        },
        changeTab(index) {
            this.tab_index = index
            this.pageNum = 1
            this.getInit(index)
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getInit(this.tab_index)
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getInit(this.tab_index)
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_baogao-gonggao {
    width: 100%;
    background: #fff;

    .baogao_tab {
        padding: 38px 812px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.06);
        margin-bottom: 60px;

        .tab_title {
            @include font(22, 30, #181B20, 400);
            padding-bottom: 30px;
            font-family: 'PingFangSC-Semibold, PingFang SC';

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            position: relative;
            color: #181B20;
            font-weight: 600;

            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -15px;
                content: '';
                display: inline-block;
                width: 30px;
                height: 3px;
                background: #181B20;
                border-radius: 2px;
            }
        }
    }

    .baogao_content {
        width: 100%;
        @include font(14, 16, #5A5F69, 400);
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';


        .terminal_box,
        .temporary_box {
            width: 100%;
            // height: 800px;
            border: 1px solid #EBEBEB;

            .li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 40px;
                box-sizing: border-box;
                border-bottom: 1px solid #FAFAFA;
                @include font(18, 16, #5A5F69, 400);
                font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';

                .left {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;

                    .icon {
                        padding-left: 8px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .timer {

                    @include font(16, 16, #5A5F69, 400);
                }
            }
        }
    }

    .pag_ing {
        padding-bottom: 100px;

        /deep/ .el-pagination {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-prev,
            .btn-next {
                padding: 5px !important;
                border: 1px solid #cbcfd3;
            }

            .el-pager {
                .number {
                    border: 1px solid #cbcfd3;
                    margin: 0 2px;
                }

                .active {
                    background: var(--bg-colors);
                    color: #fff;
                }
            }
        }
    }
}

.public-padding-left-right {
    padding: 0 260px;
    box-sizing: border-box;
}
</style>
