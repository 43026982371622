<template>
    <div class="pc_jion_page-content public-padding-left-right">
        <div class="top">
            <div class="title">在招岗位</div>
            <div class="right">
                <div class="select">
                    <el-select v-model="select_val" placeholder="全部工作地点">
                        <el-option v-for="(item, index) in address_list" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="search">
                    <input type="text" class="search_val" v-model="search_val" placeholder="输入岗位名称">
                    <i class="iconfont icon-sousuo icon_search"></i>
                    <button class="search_btn" @click="searchVal">查询</button>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <template>
            <el-table :cell-style="cellStyle" class="table_data" :data="tableData" style="width: 100%"
                :header-cell-style="{ background: '#F0F0F0', color: '#606266', fontSize: '16px' }" align="center"
                @row-click="changTable">
                <el-table-column prop="title" label="岗位名称" min-width="25%">
                </el-table-column>
                <el-table-column prop="category" label="岗位类别" min-width="25%">
                </el-table-column>
                <el-table-column prop="site" label="工作地点" min-width="25%">
                </el-table-column>
                <el-table-column prop="publishTime" label="发布时间" min-width="25%">
                </el-table-column>
            </el-table>
        </template>
        <!-- 分页 -->
        <div class="pag_ing" v-if="tableData.length >= 1">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: '',
    components: {},

    data() {
        return {
            select_val: '',
            address_list: [],
            search_val: '',
            tableData: [],
            pageNum: 1,
            pageSize: 9,
            total: 0,
            jobType: localStorage.getItem('jobType')
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        cellStyle(data) {
            return "cursor:pointer;"

        },
        async getInit(search) {
            let params = {
                search: search,
                site: this.select_val,
                jobType: this.jobType,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            const res = await this.$API.jionlApi(params)
            if (res.data.meta.code == 200) {
                this.tableData = res.data.data.data
                this.total = res.data.data.total
                console.log(this.tableData)
            }
            this.tableData.forEach(item => {
                if (item.publishTime) {
                    item.publishTime = moment(item.publishTime).format("YYYY-MM-DD")
                }
            })

            const address_res = await this.$API.sitesApi()
            if (address_res.data.meta.code == 200) {
                this.address_list = address_res.data.data
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getInit()
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getInit()
        },
        searchVal() {
            this.getInit(this.search_val)
        },
        changTable(row, column, event) {
            this.$router.push({
                name: "jion-describe",
                params: {
                    jid: row.jid,
                },
            });
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_jion_page-content {
    width: 100%;
    height: 100%;
    @include font(14, 20, #666, 400);
    font-family: 'PingFangSC-Regular, PingFang SC';

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .title {
            font-size: 32px;
            font-family: 'PingFangSC-Semibold, PingFang SC';
            font-weight: 600;
            color: #1A1A1A;
            line-height: 32px;
        }

        .right {
            display: flex;

            .select {
                margin-right: 16px;

                /deep/ .el-select {
                    width: 148px;
                    height: 44px;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;

                    .el-input {
                        width: 100%;
                        height: 100%;

                        .el-input__inner {
                            height: 100%;
                        }
                    }
                }
            }

            .search {
                position: relative;
                display: flex;
                align-items: center;

                .search_val {
                    width: 400px;
                    height: 44px;
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    @include font(14, 20, #666, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    padding-left: 44px;


                }

                .icon_search {
                    position: absolute;
                    left: 20px;
                    // top: 50%;
                    // transform: translate(0, -50%)
                }

                .search_btn {
                    width: 100px;
                    height: 44px;
                    background: var(--bg-colors);
                    @include font(14, 20, #fff, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    border: none;
                    margin-left: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    /deep/ .el-table {

        tr th div {
            margin-left: 30px !important;
        }

        tr td div {
            margin-left: 30px !important;
        }

        .el-table__body-wrapper {
            .cell {
                font-size: 18px;
            }
        }
    }

    .pag_ing {
        padding-bottom: 100px;

        /deep/ .el-pagination {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-prev,
            .btn-next {
                padding: 5px !important;
                border: 1px solid #cbcfd3;
            }

            .el-pager {
                .number {
                    border: 1px solid #cbcfd3;
                    margin: 0 2px;
                }

                .active {
                    background: var(--bg-colors);
                    color: #fff;
                }
            }
        }
    }
}

/deep/ .table_data {
    .el-table__row {
        .cell {
            font-size: 16px;
        }
    }
}

.public-padding-left-right {
    padding: 87px 370px 0;
    box-sizing: border-box;
}
</style>
