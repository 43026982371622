<template>
    <div class="pc_index_header ">
        <div class="header-box">

        </div>
        <!-- 首页-menus -->
        <div class="content-heade type-arae">
            <!-- menus部分 -->
            <div class="menus-box">
                <a href="/index" class="img-a" @click="goIndex">
                    <img class="img-logo" :src="$store.state.info.logo" alt="">
                </a>
                <div class="right-tabs">
                    <div class="pc-index-menus">
                        <div class='pc-index-ul'>
                            <a href="javascript:;" @click="changeMenus(-1)"
                                :class="['index-page', menus_index == -1 ? 'pc-index-active' : 'pc-index-name']">
                                首页
                            </a>
                            <div class="pc-index-li" v-for="(item, index) in $store.state.menus" :key="item.mid">
                                <span v-if="item.show == 1" @click="changeMenus(item, index, $event)"
                                    :class="['tabs', menus_index == index ? 'pc-index-active' : 'pc-index-name']"> {{
                                        item.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 遮罩层存在层级部分 -->
            <div v-show="menus_show" class="children-box">
                <div class="menus-children" ref="menusChildren">
                    <template>
                        <template>
                            <a v-for="item in menusShowList" :key="item.mkey" :target="item.pmkey == 'commerce' ? '_blank' : '_self'" v-show="item.show == 1"
                                :href="item.url" class="menus-title">
                                {{ item.name }}
                            </a>
                        </template>
                    </template>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: '',
    props: {
        props_meuns_index: {
            type: Number
        }
    },
    computed: {
        ...mapGetters(["menus_index"]),
    },

    data() {
        return {
            chinese_or_english: true,// 中文Or英文
            menus_show: false,
            menusShowList: [],
            box_w: 0,
        }
    },

    watch: {
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
				console.log(val.fullPath);
                if (val.fullPath == '/') {
                    this.$store.commit('changeMenus', -1)
					this.changeMenus(-1)
                }
            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    mounted() {
    },

    methods: {
        changeMenus(item, i, e) {
            let index
            if (item == -1) {
                index = -1
                this.menus_show = false
                this.menusShowList = []
                this.$router.push('/index').catch(err => err)
            } else {
                index = i

                let x = e.currentTarget.offsetLeft
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.box_w = this.$refs.menusChildren.clientWidth / 2
                        this.$refs.menusChildren.style.left = x - this.box_w + 'px'
                        console.log(x - this.box_w)
                    }, 100)
                })
                // if (item.mkey == "about") {
                //     this.$refs.menusChildren.style.paddingLeft = 8 + 'rem'
                // } else if (item.mkey == "touzi") {
                //     this.$refs.menusChildren.style.paddingLeft = 13 + 'rem'
                // } else if (item.mkey == "jion") {
                //     this.$refs.menusChildren.style.paddingLeft = 15 + 'rem'
                // } else if (item.mkey == "commerce") {
                //     this.$refs.menusChildren.style.paddingLeft = 16 + 'rem'
                // }
                if (item.menus.length <= 0 || item.mkey == 'rd' || item.mkey == 'product') {
                    this.menus_show = false
                    this.menusShowList = []
                    this.$router.push(item.url).catch(err => err)
                } else {
                    this.menus_show = true
                    this.menusShowList = item.menus;
                    // 默认到第一个，最后一个新开窗口
                    if (item.mkey == 'commerce') {
                        // window.open(item.menus[0].url)
                        this.menus_show = true
                    } else {
                        this.$router.push(item.menus[0].url).catch(err => err)
                        this.menus_show = true
                    }
                }
            }
            this.$store.commit('changeMenus', index)
        },

        goIndex() {

            this.$store.commit('changeMenus', -1)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.header-box {
    width: 100%;
    min-height: 8px;
    background: #777a77;
    display: flex;
    justify-content: right;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 30%;
        height: 100%;
        background: var(--bg-colors);
    }
}

.content-heade {
    padding: 5px 120px;
    box-sizing: border-box;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.06);
    position: relative;

    .menus-box {
        @include justify-align(space-between);
        // align-items: flex-end;

        .img-a {

            width: 300px;

            @include text-center;

            .img-logo {
                width: 100%;
                height: 100%;
            }
        }


        .right-tabs {
            height: 80px;

            @include text-center;

            .pc-index-menus {
                width: 100%;
                height: 100%;

                @include text-center;

                .pc-index-ul {
                    width: 100%;
                    height: 100%;
                    @include text-center;

                    .index-page {
                        @include font(20, 25, #333333, 400);
                        padding: 0 24px;
                    }

                    .pc-index-li {
                        height: 100%;
                        padding: 0 24px;

                        @include text-center;


                        .tabs {
                            @include font(20, 25, #333333, 400);

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .pc-index-active {
                            position: relative;
                            display: block;
                            height: 100%;
                            box-sizing: border-box;
                            @include text-center;
                            @include font(20, 25, var(--bg-colors), 600);

                            &::before {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                @include height-width(2, 30);
                                bottom: 0;
                                background: var(--bg-colors)
                            }
                        }
                    }

                    .pc-index-name {
                        height: 100%;
                        display: flex;

                        @include text-center;
                        @include font(20, 25, #333333, 400);
                    }

                    .pc-index-active {
                        position: relative;
                        display: block;
                        height: 100%;
                        box-sizing: border-box;
                        @include text-center;
                        @include font(20, 25, var(--bg-colors), 600);

                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            @include height-width(2, 30);
                            bottom: 0;
                            background: var(--bg-colors)
                        }
                    }


                }
            }
        }
    }

    .children-box {
        position: relative;
        background: #0000004b;
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;

        .menus-children {
            left: 0;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: 'PingFangSC-Semibold, PingFang SC';


            .menus-title {
                padding: 0 20px;
                @include font(20, 22, #fff, 400);

                &:hover {
                    cursor: pointer;
                    @include font(20, 22, #fff, 600);
                }
            }
        }
    }



}
</style>
