<template>
    <!-- 法律声明 -->
    <div class="privacy">
        <div class="title public-yd-css-margin">海联金汇个人信息保护政策</div>
        <div class="fwb" v-html="privacy_text">

        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            privacy_text: ''
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.privacyApi()
            this.privacy_text = res.data.data

        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.privacy {
    width: 100%;
    height: 100%;
    padding: 1px 30px;
    box-sizing: border-box;
    @include font(16, 30, #111, 500);

    .title {
        text-align: center;
        @include font(40, 30, #111, 500);
        padding: 80px 0 40px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 40px;
    }

    .fwb {
        padding-bottom: 30px;
        // background: red;
        // min-height: calc(100vh - 685px);
    }


}
</style>
