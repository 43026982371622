<template>
    <div class="pc_rd_jishu">
        <pcRdListBg :list="list"></pcRdListBg>
    </div>
</template>

<script>
import pcRdListBg from './rd_content/pc_list_bg.vue'
export default {
    name: '',
    components: { pcRdListBg },
    data() {
        return {
            list: []
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_jishu')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_jishu {
    width: 100%;
}
</style>
