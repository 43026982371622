import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
/* API */
import * as API from "@/request/api.js";
API.grayApi().then(res => {
	if (res.data.data == true) {
		import ("./utils/css/grayscale.css");
	}
})
/* css */
import "./utils/css/index.css";
import "./views/yd/css/index.css";
/* pc公共css */
import "@/views/pc/css/public-about.css";
/* 按需饿了么 */
import "./utils/plugins/element.js";
/* vant */
import "./utils/plugins/vant.js";
/* 阿里巴巴图标库 */
import "./utils/icon/iconfont.css";
/* 视频播放器 */
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
/* 引入swiper */
// import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
// Vue.use(VueAwesomeSwiper);
Vue.use(VideoPlayer);
Vue.config.productionTip = false;
Vue.prototype.$API = API;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
