<template>
    <div class="pc_list_bg">
        <div class="list_ul">
            <div :style='index % 2 == 0 ? `background-image:url(${colorList[Math.floor(Math.random() * 2)]})` : "background-color:#f5f5f5"'
                class="list_li public-padding-left-right" v-for="(item, index) in list" :key="index">
                <div class="lt" v-if="index % 2 == 0">
                    <div class="left">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.lgText }}
                        </div>
                    </div>
                    <div class="right">
                        <img class="lg-img" :src="item.lgImg" alt="">
                    </div>
                </div>
                <div class="lt" v-else>
                    <div class="right">
                        <img class="lg-img" :src="item.lgImg" alt="">
                    </div>
                    <div class="left">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.lgText }}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        }
    },
    data() {
        return {
            colorList: [
                require('@/images/bg_technology_1.png'),
                require('@/images/bg_technology_2.png'),
            ]
        }
    },
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_list_bg {
    width: 100%;

    .public-padding-left-right {
        padding: 0 260px;
        box-sizing: border-box;
    }

    .public-padding-top-bottom {
        padding: 104px 0;
        box-sizing: border-box;
    }

    .list_ul {
        width: 100%;

        .list_li {
            display: flex;
            align-items: center;
            // height: 590px;
            background-repeat: no-repeat;
            padding-top: 105px;
            padding-bottom: 105px;

            &:nth-child(even) {
                .left {
                    margin: 0;
                }

                .right {
                    margin-right: 80px;
                }

                // background:  !important;
            }

            .lt,
            .rt {
                display: flex;
                align-items: center;

                .left {
                    width: 600px;
                    margin-right: 80px;

                    .title {
                        @include font(36, 32, #1A1A1A, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        padding-bottom: 44px;
                    }

                    .describe {
                        @include font(18, 32, #383838, 400);
                        font-family: 'PingFangSC-Regular, PingFang SC';
                    }
                }

                .right {
                    width: 720px;
                    height: 380px;

                    .lg-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
