import Vue from "vue";
import {
  Button,
  Carousel,
  CarouselItem,
  Timeline,
  TimelineItem,
  Pagination,
  Select,
  Option,
  Table,
  TableColumn,
	Collapse,
	CollapseItem,
} from "element-ui";

Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Pagination);
Vue.use(Option);
Vue.use(Select);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Collapse);
Vue.use(CollapseItem);
