import pc_product from "@/views/pc/views/product/pc_product.vue";
import yd_product from "@/views/yd/views/product/yd_product.vue";
const product = [
  {
    path: "/product",
    components: {
      default: pc_product,
      yd: yd_product,
    },
  },
];

export default product;
