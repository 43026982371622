import pc_rd from "@/views/pc/views/rd/pc_rd.vue";
import yd_rd from "@/views/yd/views/rd/yd_rd.vue";

const rd = [
  {
    path: "/rd",
    components: {
      default: pc_rd,
      yd: yd_rd,
    },
  },
];

export default rd;
