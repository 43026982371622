import Vue from "vue";
import VueRouter from "vue-router";
import pc_index from "@/views/pc/views/index/pc_index.vue";
import yd_index from "@/views/yd/views/index/yd_index.vue";
import pc_privacy from "@/views/pc/views/index/privacy.vue";
import yd_privacy from "@/views/yd/views/index/privacy.vue";
import pc_legal from "@/views/pc/views/index/legal.vue";
import yd_legal from "@/views/yd/views/index/legal.vue";
import pc_relation from "@/views/pc/views/index/relation.vue";
import yd_relation from "@/views/yd/views/index/relation.vue";
import pc_error from "@/views/pc/views/error/index.vue";
import yd_error from "@/views/yd/views/error/index.vue";
import index from "@/views/index.vue";
import about from "./about";
import product from "./product";
import rd from "./rd";
import touzi from "./touzi";
import jion from "./jion";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    components: {
      default: index,
      yd: yd_index,
    },
    meta: {
      auth: false,
      title: "首页",
			isIndex: true
    },
  },
	{
	  path: "/index",
	  components: {
	    default: pc_index,
	    yd: yd_index,
	  },
	  meta: {
	    auth: false,
	    title: "首页",
	  },
	},
  {
    path: "/privacy",
    components: {
      default: pc_privacy,
      yd: yd_privacy,
    },
    meta: {
      auth: false,
      title: "法律声明",
    },
  },
  {
    path: "/legal",
    components: {
      default: pc_legal,
      yd: yd_legal,
    },
    meta: {
      auth: false,
      title: "隐私保护",
    },
  },
  {
    path: "/relation",
    components: {
      default: pc_relation,
      yd: yd_relation,
    },
    meta: {
      auth: false,
      title: "联系我们",
    },
  },
  ...about,
  ...product,
  ...rd,
  ...touzi,
  ...jion,
  {
    path: "*",
    name: "404",
    components: {
      default: pc_error,
      yd: yd_error,
    },
    meta: {
      auth: false,
      title: "404页面",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/hailiweb/",
  routes,
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
