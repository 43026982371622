<template>
    <div class="yd-index ">
        <!-- 顶部轮播图 -->
        <van-swipe class="public-yd-css-margin my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in $store.state.swiper" :key="item.sid">
                <img class="banner-imgs" :src="item.smImg" alt="">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容——智能制造板块 -->
        <div class="content-smart yd-index-modules">
            <!-- 标题 -->
            <div class="yd-index-modules-title">
                <span class="title">智能制造板块</span>
            </div>
        </div>
        <!-- about/jianjie#smartY -->
        <div class="smart-ul">
            <a href="/product" class="smart-li" v-for="(item, index) in smart_list" :key="index"
                :style="`background-image:url(${item.smImg})`">
                <div class="smart-li-box">
                    <div class="smart-title">
                        {{ item.title }}
                    </div>
                    <div class="smart-box">
                        <div class="categories" v-for="(it, i) in item.categories" :key="i">
                            {{ it }}
                        </div>
                    </div>
                </div>

            </a>
        </div>

        <!-- 内容——热点产品 -->
        <div class="content-hot yd-index-modules">
            <!-- 标题 -->
            <div class="yd-index-modules-title">
                <span class="title">核心产品</span>
            </div>
            <!-- tabs -->
            <!-- <div class="index-tabs">
                <ydIndexHotTabs :tabs_index="tabs_index" @changeTabs="changeTabs"></ydIndexHotTabs>
            </div> -->
            <!-- 描述 -->
            <!-- <div class="yd-index-describe-box">
                <template v-for="(item, index) in $store.state.productSm">
                    <span class="index-describe" v-if="tabs_index == index" v-html="item.desc" :key="item.pid">
                    </span>
                </template>
            </div> -->
        </div>
        <!-- 图片盒子 -->
        <div class="yd-index-img-box">
            <swiper id="mySwiper" :options="swiperOption" ref="mySwiper" @slide-change-transition-start="slidChange">
                <swiper-slide v-for="(item, index) in $store.state.homeProductList" :key="index">
                    <div class="swiper_cont">
                        <div class="img">
                            <img class="swiper-img" :src="item.smImg" alt="" />
                        </div>
                        <div class="swiper_text">
                            <div class="text_box">
                            <h4>{{ item.title }}</h4>
                            <p v-html="item.text.replace(/\n/g, '<br /><br />').replace(/-/g, `<span></span>`)"></p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>

                <!-- 根据情况引入控件 -->
                <!-- 上一张按钮 -->
                <div class="btn-left" slot="button-prev">
                </div>
                <!-- 下一张按钮 -->
                <div class="btn-right" slot="button-next">
                </div>  
            </swiper>
            
            <div class="swiper-pagination"></div> 
            <div class="look-all">
                <button class="btn" @click="$router.push('/product')">查看全部</button>
            </div>
        </div>

        <!-- 视频 -->
        <div class="video-player-box" v-if="videosrc !== ''">
            <!-- <video-player style="width: 100%;height: 100%;margin:0 auto;box-shadow:5px 5px 8px #888888"
                class="video-player vjs-custom-skin" ref="videoPlayer" :playsline="false" :options="playerOptions">
            </video-player> -->
            <video id="video" :poster="cpjsimg" loop="loop" webkit-playsinline="false" playsinline="false"
                x5-playsinline="false" x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="false"
                x5-video-ignore-metadata="true" x5-video-orientation="landscape" preload="preload" controls="controls"
                :src="videosrc"></video>
        </div>

        <!-- 内容——合作伙伴 -->
        <div class="content-partner yd-index-modules">
            <!-- 标题 -->
            <div class="yd-index-modules-title">
                <span class="title">合作客户</span>
            </div>
            <van-swipe class="my-van-swiper" :autoplay="3000">
                <van-swipe-item v-for="(item) in cooperationList" :key="item.pid">
                    <div class="img-box" v-for="(it, i) in item" :key="i">
                        <img class="img-home-customer" :src="it.logo" alt="">
                    </div>
                </van-swipe-item>

            </van-swipe>

            <!-- <div class="look-all">
                <button class="btn">查看全部</button>
            </div> -->
        </div>
    </div>
</template>

<script>
import ydIndexHotTabs from '../../components/index/yd_index_hot_tabs.vue'
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
    name: '',
    components: {
        ydIndexHotTabs,
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive,
    },

    data() {
        return {
            tabs_index: 0,//tab索引
            tabs_pid: '',//tab索引
            swiperOption: {
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    //下一张
                    nextEl: '.btn-right',//下一张标签类名可以自定义
                    //上一张
                    prevEl: '.btn-left'//上一张标签类名可以自定义
                },
                spaceBetween: 10,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false, //解决滑动后不能轮播的问题
                }
            }, // swiper
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",
                    src: ''//url地址
                }],
                poster: '', //你的封面地址
                // width: document.documentElement.clientWidth,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false,
                    durationDisplay: false,
                    remainingTimeDisplay: false,
                    fullscreenToggle: false  //全屏按钮
                }
            },  /* 视频播放器 */
            sl_arr: [],
            smart_list: [],
            cooperationList: [],
            cpjsimg: '',
            videosrc: '',
        }
    },
    mounted() {
        setTimeout(() => {
            this.getInit()
        }, 200)
        this.getPartner()
        this.getSmartInit()
        this.$store.dispatch('getInfoApi').then(res => {
            if (res) {
                // 视频
                // this.playerOptions['sources'][0]['src'] = res.video
                // this.playerOptions['poster'] = res.videoCover
                this.cpjsimg = res.videoCover
                this.videosrc = res.video
            }
        })
    },
    computed: {
        smList() {
            return this.$store.state.productLg
        },
        // swiper() {
        //     return this.$refs.mySwiper.$swiper
        // }
    },
    methods: {

        getInit() {
            this.smList.forEach(item => {
                item.list.forEach((it, index) => {
                    if (it.homeShow == 1) {
                        this.sl_arr.push({
                            index: index,
                            ...it
                        })
                    }

                })
            })
            this.tabs_pid = this.sl_arr[0].pid
        },
        async getSmartInit() {
            const res = await this.$API.siteBannerApi()
            if (res.data.meta.code == 200) {
                this.smart_list = res.data.data
            }
        },
        // changeTabs(row, index) {
        //     this.swiper.activeIndex = 0
        //     this.tabs_pid = row.pid
        //     this.tabs_index = index
        // },
        /* swiper 自动轮播 */
        slidChange() {
        },
        // 合作伙伴
        async getPartner() {
            const res = await this.$API.indexPartnerApi()
            let data = res.data.data
            const maxSubArrayLength = 9;
            for (let i = 0; i < data.length; i++) {
                // 获取当前数据
                const currentData = data[i];
                // 判断是否需要创建新的子数组
                if (i % maxSubArrayLength === 0) {
                    // 创建新的子数组
                    const subArray = [];
                    this.cooperationList.push(subArray);
                }
                // 添加数据到当前子数组
                const currentSubArray = this.cooperationList[this.cooperationList.length - 1];
                currentSubArray.push(currentData);
            }
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";
// 修改样式
.swiper_cont{
  width: 100%;
  height: 750px;
  .img{
    width: 100%;
    display: flex;
    @include text-center;
    height: 360px;
    img{
        width: 70%;
    }
  }
  .swiper_text{
    width: 100%;
    height: 420px;
    text-align: left;
    display: flex;
    h4{
      font-size: 32px;
      font-family: 'PingFangSC-Semibold, PingFang SC';
      font-weight: 600;
      color: #000;
      line-height: 38px;
      padding: 0 60px;
      margin: 30px 0 20px;
      text-align: center;
    }
    p {
      padding: 6px 60px;
      color: #3b3b3b;
      line-height: 28px;
      font-size: 25px;
    /deep/ span {
        width:6px;
        height: 6px;
        font-size: 0;
        line-height: 0;
        background: #7b7b7b;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}
.yd-index {
    width: 100%;
    box-sizing: border-box;
    padding: 1px;
    box-sizing: border-box;

    .my-swipe {
        width: 100%;

        .banner-imgs {
            width: 100%;
        }
    }


    .smart-ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .smart-li {
            padding: 40px 55px;
            box-sizing: border-box;
            width: 690px;
            height: 304px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-bottom: 20px;
            position: relative;

            &::after {
                content: '';
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
                z-index: 5;
            }

            .smart-li-box {
                z-index: 9;
                position: relative;

                .smart-title {
                    font-size: 28px;
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 40px;

                }

                .smart-box {
                    padding-top: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    max-height: 144px;
                    overflow-y: scroll;

                    .categories {
                        width: 50%;
                        font-size: 24px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #fff;
                        line-height: 40px;
                        padding-bottom: 12px;
                        display: flex;
                        align-items: center;

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            background: #fff;
                            margin-right: 17px;
                        }
                    }
                }
            }

        }
    }


    .content-hot {
        .index-tabs {
            width: 100%;
        }

        .yd-index-describe-box {
            margin-top: 20px;
            background: #F9F9F9;
            box-sizing: border-box;
            width: 100%;
            @include text-center;

            .index-describe {

                @include font(20, 34, #666666, 400);
                padding: 30px;
                box-sizing: border-box;
                text-align: center;
                font-family: 'PingFangSC-Regular, PingFang SC';
            }
        }



    }



    /* 移动-模块统一样式 */
    .yd-index-modules {
        padding: 60px 30px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .yd-index-modules-title {
        display: flex;
        height: 56px;
        align-items: center;
        margin-bottom: 40px;
    }

    .yd-index-modules-title .title {
        @include font(45, 50, #1A1A1A, 600);
        font-family: "PingFangSC-Semibold, PingFang SC";

    }

    .yd-index-modules .yd-index-modules-title::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background: var(--bg-colors);
        border-radius: 6px;
        margin-left: 20px;
    }

    .yd-index-modules .yd-index-modules-title::before {
        content: "";
        display: inline-block;
        @include height-width(6, 6);
        background: var(--bg-colors);
        border-radius: 6px;
        margin-right: 20px;
    }
}

.video-player-box {
    width: 100%;

    #video {
        width: 100%;
    }
}
</style>
<style lang="scss">
@import "@/utils/css/mixin.scss";

.my-swipe {
    .van-swipe__indicators {
        left: 50%;
        transform: translate(-50%, -10px) !important;


        .van-swipe__indicator {
            @include height-width(12, 12);
            border: none;
            border-radius: 50%;
            background-color: #fff;
        }

        .van-swipe__indicator--active {
            background: var(--bg-colors) !important;
            @include height-width(12, 40);
            border: none;
            border-radius: 10px;
        }
    }
}

.yd-index-img-box {
    width: 100%;
    height: 980px;
    position: relative;
    .swiper-pagination{
        width: 100%;
        top: 320px;
        left: 0;
    }
    .swiper-pagination-bullet{
            width:15px !important;
            height: 15px !important;
            margin: 0 10px;
            background: #fff;
            border: 1px solid #ccc;
        }
        .swiper-pagination-bullet-active{
            background: #811c29; 
        }
    .swiper-container {
        position: relative;
        width: 100%;

        height: 800px;
        .swiper-wrapper {
            height: 100% !important;
            // width: 100% !important;
            position: relative;
        }
        .swiper-slide {

            width:100%;
            height: 362px;
            background: transparent;

            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0 !important;
                left: 0 !important;
                width: 100%;
                height: 80%;
            }

            .sm-img {
                position: relative;
                width: 100%;
                height: 80%;
                vertical-align: middle;


            }

            .title {
                background: #fff;
                text-align: center;
                font-size: 28px;
                font-family: 'PingFangSC-Medium, PingFang SC';
                font-weight: 500;
                color: #333333;
                line-height: 40px;
                padding-top: 5px;

            }
        }
    }

    .btn-left {
        position: absolute;
        top: 30%;
        transform: translate(50px, -50%);
        @include height-width(60, 60);
        @include text-center;
        // background: red;
        border: 1px solid #ccc;
        border-radius: 60px;
        z-index: 9;
        box-sizing: border-box;
        background: #fff;
        &:hover {
            background: var(--bg-colors);
            border: none;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -4px;
            display: inline-block;
            @include height-width(15, 15);
            border-top: 3px solid #ccc;
            border-left: 3px solid #ccc;
            transform: rotate(-45deg);
        }
    }

    .btn-right {
        position: absolute;
        top: 30%;
        right: 0;
        transform: translate(-50px, -50%);
        @include height-width(60, 60);
        @include text-center;
        // background: red;
        border: 1px solid #ccc;
        border-radius: 60px;
        z-index: 9;
        background: #fff;
        &:hover {
            background: var(--bg-colors);
            border: none;
        }

        &::after {
            position: absolute;
            left: 50%;
            margin-left: -10px;
            content: '';
            display: inline-block;
            @include height-width(15, 15);
            border-bottom: 3px solid #ccc;
            border-right: 3px solid #ccc;
            transform: rotate(-45deg);
        }
    }


    .swiper-slide-active {

        transform: scale(1) !important;

        &::after {

            content: '';
            position: absolute;
            width: 100%;
            height: 80%;
            display: flex;
           
        }
    }

    .swiper-slide-next,
    .swiper-slide-prev {
        background: #ccc;
        opacity: 0.8 !important;
    }

}

.look-all {
    width: 100%;
    @include text-center;

    .btn {
        width: 230px;
        height: 60px;
        border: 1px solid #666666;
        margin-top: 60px;
        margin-bottom: 80px;
        background: #fff;
        @include font(24, 33, #333, 500);
        font-family: 'PingFangSC-Medium, PingFang SC';

    }
}

.my-van-swiper {
    width: 100%;
    // max-height: calc(125px * 4);
    margin-bottom: 60px;

    .van-swipe-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .img-box {
            width: 33%;
            height: 125px;

            .img-home-customer {
                padding: 10px 20px;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
            }
        }
    }

    .van-swipe__indicators {
        display: none;
    }

}
</style>

