<template>
    <div class="about_new_search">
        <div class="title">找到约{{ new_list.data.length }}条记录</div>
        <div class="search_ul" v-if="new_list.data.length > 0">
            <div class="search_li" v-for="(item, index) in new_list.data" @click="toDetail(item, index)" :key="item.nid">
                <div class="left_text">
                    {{ item.smText }}
                </div>
                <div class="right_icon iconfont icon-youjiantou"></div>
            </div>
        </div>
        <div v-else class="img_quesheng">
            <img src="@/images/img_quesheng.png" alt="">
            <!-- <div>您的页面走丢了</div> -->
        </div>

        <div class="pag_ing" v-if="new_list.data.length > 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="new_list.pageNum" :pager-count='5' :page-size="new_list.pageSize"
                layout="  prev, pager, next, jumper" :total="new_list.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        search_val: {
            type: String,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(["new_list"]),
    },
    mounted() {
    },
    methods: {
        handleCurrentChange(val) {
            this.new_list.pageNum = val;
            this.$store.dispatch('getNew', { search: this.search_val, pageNum: this.new_list.pageNum, pageSize: this.new_list.pageSize })
        },
        handleSizeChange(val) {
            this.new_list.pageSize = val;
            this.$store.dispatch('getNew', { search: this.search_val, pageNum: this.new_list.pageNum, pageSize: this.new_list.pageSize })
        },
        toDetail(row, id) {
            this.$router.push({
                name: "news",
                params: {
                    nid: row.nid,
                },
                query: {
                    id: id,
                },
            });
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_new_search {
    width: 100%;
    padding: 80px 30px;
    box-sizing: border-box;

    .title {
        @include font(32, 48, #181B20, 600);
    }

    .search_ul {
        width: 100%;

        .search_li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font(28, 42, #181B20, 400);
            padding: 15px 0;
            position: relative;
            padding: 20px 0;

            &::after {
                content: '';
                position: absolute;
                background: #bbb;
                bottom: 0;
                width: 100%;
                height: 1px;
            }

            .left_text {
                width: 560px;
                /* 文字超出一行省略号 */
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .right_icon {
                width: 2%;
            }
        }
    }

    .img_quesheng {
        margin-top: 140px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .pag_ing {
        display: flex;
        align-items: center;

        padding: 0 40px 30px;
        box-sizing: border-box;


        /deep/ .el-pagination {
            width: 100%;
            height: 62px !important;
            margin-top: 60px;
            display: flex;

            .btn-prev,
            .btn-next {
                width: 62px;
                padding: 5px !important;
                border: 1px solid #cbcfd3;
                height: 100%;
                box-sizing: border-box;
            }

            .el-pagination__jump {
                height: 62px;
                font-size: 24px;
                font-family: AlibabaPuHuiTiR;
                color: #171721;
                line-height: 62px;

                word-break: break-all;

                .el-input {
                    width: 76px;
                    height: 62px;

                    .el-input__inner {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                        opacity: 0.6;
                        border: 1px solid #CBCFD3;
                        font-size: 18px;
                    }
                }
            }

            .el-pager {
                display: flex;
                align-items: center;
                padding: 0 10px;
            }

            .el-pager .number {
                width: 62px;
                height: 62px;
                border-radius: 4px;
                opacity: 0.6;
                border: 1px solid #CBCFD3;
                line-height: 62px;
                padding: 0 4px;
                margin-right: 10px;
                font-size: 18px;

                &:last-child {
                    margin-right: 0;
                }

                .el-icon {
                    display: flex;
                    align-items: center;

                    height: 62px;
                }

            }

            .active {
                background: var(--bg-colors);
                color: #fff;
            }
        }
    }
}
</style>
