<template>
    <div class="yd_product">
        <banner :images="$store.state.public_banner_info.smImg" :title="$store.state.public_banner_info.title"></banner>
        <div class="product_box">
            <div class="product_parent pubilc-left-right">
                <div class="product_ul">
                    <div :class="['product_li', kelleg_key == item.pid ? 'active_product_li' : '']"
                        v-for="(item, index) in product_parent  " :key="item.pid" @mouseenter="changetabs(item, index)">
                        <div class="icon">
                            <img v-if="kelleg_key !== item.pid" :src="item.icon" alt="">
                            <img v-else :src="item.activeIcon" alt="">
                        </div>
                        <!-- 顶部tab -->
                        <div class="text">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="product_childrens pubilc-left-right">
                <div class="product_title">产品类别</div>
                <div class="product_content">
                    <template v-for="(  pit, pid  ) in   product_parent  ">
                        <div style="width:100%" :key="pid" v-if="kelleg_key == pit.pid">
                            <div class="content_tabs_li">
                                <div class="top">
                                    <!-- 上方tab -->
                                    <template v-for="(  cit, cid  ) in   pit.list  ">
                                        <div :class="['content_tabs', children_index == cid ? 'active_content_left' : '']"
                                            @click="children_index = cid">
                                            {{ cit.title }}
                                        </div>
                                    </template>
                                </div>
                                <!-- 下方内容 -->
                                <template v-for="(  cit, cid  ) in   pit.list  ">
                                    <div :key="cid" class="content_bottom" v-if="children_index == cid">

                                        <div class="top_text">
                                            <div class="describe" style="white-space: pre-wrap;" v-html="cit.text">
                                            </div>
                                        </div>
                                        <div class="bottom_img">
                                            <img class="sm_img" :src="cit.smImg" alt="">
                                        </div>
                                    </div>
                                </template>
                            </div>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import banner from '@/views/yd/components/banner.vue'
export default {
    name: '',
    components: { banner },
    data() {
        return {
            key: '',
            product_parent: [],
            children_index: 0,
            kelleg_key: ''
        }
    },

    mounted() {
        /* 图片 */
        this.$store.dispatch('getPageHeader', 'product')
        this.getInit()
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.kelleg_key = window.location.href.split('#')[1]

            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    methods: {
        async getInit() {
            const res = await this.$API.productApi()
            this.product_parent = res.data.data
            this.kelleg_key = this.product_parent[0].pid

        },
        changetabs(item, index) {
            this.kelleg_key = item.pid
            this.children_index = 0
        },

    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.yd_product {
    padding: 1px 0;
    box-sizing: border-box;
}

.product_box {
    width: 100%;
    height: 100%;
    background: #FAFAFA;

    .product_parent {
        box-sizing: border-box;
        background: #fff;

        .product_ul {
            display: flex;
            flex-wrap: wrap;
            @include font(16, 22, #666, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding: 40px;
            box-sizing: border-box;
            justify-content: space-between;

            .product_li {
                width: calc(100% / 4);
                padding: 15px 20px;
                box-sizing: border-box;
                @include text-center;
                flex-direction: column;

                .icon {
                    @include text-center;
                    // width: 100%;
                    width: 80%;
                    height: 70%;
                    padding-bottom: 5px;

                    transform: scale(1);

                    img {
                        width: 100%;
                    }
                }

                // .dianchi {
                //     @include text-center;
                //     // width: 100%;
                //     width: 60%;
                //     // height: 70%;
                //     padding-bottom: 15px;

                //     img {
                //         width: 100%;
                //         height: 100%;
                //     }
                // }

            }

            .active_product_li {
                .text {
                    color: var(--bg-colors);
                }
            }
        }
    }

    .product_childrens {
        background: #FAFAFA;
        padding: 0 30px;

        .product_title {
            @include font(36, 50, #1a1a1a, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding: 80px 0 40px;
            box-sizing: border-box;
            text-align: center;
        }

        .product_content {
            width: 100%;
            display: flex;
            background: #FAFAFA;

            .content_tabs_li {
                width: 100%;
                display: flex;
                flex-direction: column;

                ::-webkit-scrollbar {
                    width: 0 !important;
                }

                ::-webkit-scrollbar {
                    width: 0 !important;
                    height: 0;
                }

                .top {
                    width: 100%;
                    display: flex;
                    overflow-y: auto;
                    overflow-x: scroll;
                    // margin-right: 10px;
                    box-sizing: border-box;
                    justify-content: space-between;
                }

                .content_tabs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 0;
                    box-sizing: border-box;
                    background: #fff;
                    height: 64px;
                    @include font(28, 40, #151515, 500);
                    font-family: 'PingFangSC-Medium, PingFang SC';
                    width: 48%;
                    margin-bottom: 20px;
                    flex-shrink: 0
                }

                .active_content_left {
                    background: var(--bg-colors);
                    color: #fff;
                }

                .content_bottom {
                    box-sizing: border-box;
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 80px;

                    .bottom_img {
                        width: 279px;
                        padding-bottom: 66px;
                        box-sizing: border-box;

                        .sm_img {
                            width: 100%;
                        }
                    }

                    .top_text {
                        @include font(24, 40, #444452, 400);
                        font-family: 'AlibabaPuHuiTiR';
                        padding: 30px 30px 73px;

                        .title {
                            @include font(28, 52, #151515, 600);
                            font-family: 'AlibabaPuHuiTiR';
                        }

                        .describe {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
</style>
