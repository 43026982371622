<template>
    <div class="pc-jion type-arae">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>
        <div id="rencai" class="jion-nav public-padding-left-right">
            <div class="nav" :style="`background-image:url(${public_banner_info.showLgImg})`">
                <div class="title">{{ public_banner_info.showTitle }}</div>
                <div class="describe">
                    {{ public_banner_info.showText }}</div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="content-box ">
            <pcList :list="modules_one_list" :paddingTopBottom="'60px'" :paddingLeft="'0px'" :paddingRight="'0px'"
                :bg_color="'#fff'">
            </pcList>
        </div>
        <!-- 招聘 -->
        <div class="zhaopin-box public-padding-left-right" id="zhaopin">
            <div class="title">
                员工招聘
            </div>
            <div class="zhaopin">
                <div class="box" v-for="(item, index) in modules_two_list" :key="index" @click="goPage(item)">
                    <div class="left">
                        <img class="lg-img" :src="item.lgImg" alt="">
                    </div>
                    <div class="right">
                        <div class="zhaopin-title">
                            {{ item.title }}
                        </div>
                        <div class="zhaopin-describe">
                            {{ item.lgText }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/views/pc/components/banner.vue'

import pcList from '@/views/pc/components/jion/pc_jion_list.vue'
export default {
    name: '',
    components: { banner, pcList },
    data() {
        return {
            key: '',
            modules_one_list: [], // 人尽其才
            modules_two_list: [], // 员工招聘
            public_banner_info: []
        }
    },
    computed: {
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.key = val.path.split('/')[1]
            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        getInit() {
            this.$store.dispatch('getPageHeader', 'rencai').then(res => {
                this.public_banner_info = res
            })
            let meuns_zhaopin = []
            let time1 = new Date().getTime()
            this.$API.listApi('jion_rencai').then(modules_one_list => {
                let time2 = new Date().getTime()
                if (modules_one_list.data.meta.code == 200) {
                    this.modules_one_list = modules_one_list.data.data
                    this.$store.state.menus.forEach(pit => {
                        pit.menus.forEach(cit => {
                            if (cit.menus) {
                                cit.menus.forEach(git => {
                                    if (git && git.pmkey == "zhaopin") {
                                        meuns_zhaopin.push(git)
                                    }
                                })
                            }

                        })
                    })

                }

            })
            var timer = setTimeout(() => {
                this.$API.listApi('jion_zhaopin').then(modules_two_list => {
                    let time4 = new Date().getTime()
                    if (modules_two_list.data.meta.code == 200) {
                        modules_two_list.data.data.forEach((item, index) => {
                            
                            this.modules_two_list.push({
                                mkey: meuns_zhaopin.length ? meuns_zhaopin[index].mkey : '',
                                ...item
                            })
                        })
                    }
                })
            }, 100)

        },
        goPage(item) {
            if (item.mkey == 'shehui') {
                localStorage.setItem('jobType', 1)
            } else {
                localStorage.setItem('jobType', 2)
            }
            this.$router.push({
                path: `/jion-page/${item.mkey}`,
                params: {
                    key: item.mkey
                }
            })
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-jion {
    width: 100%;

    .jion-nav {
        width: 100%;

        .nav {
            // background: url(../../../../images/about/bg_news_details.png) no-repeat;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 284px;
            margin-bottom: 100px;

            .title {
                padding-top: 74px;
                text-align: center;
                @include font(36, 50, #fff, 600);
                font-family: 'PingFangSC-Semibold, PingFang SC';
            }

            .describe {
                text-align: center;
                padding: 23px 250px 0;
                box-sizing: border-box;
                @include font(20, 32, #fff, 400);
                font-family: 'PingFangSC-Semibold, PingFang SC';
            }
        }
    }

    .content-box {
        margin-bottom: 60px;
        padding: 0 260px;
    }

    .zhaopin-box {
        width: 100%;
        background: #F7F7F7;

        .title {
            @include font(40, 56, #1a1a1a, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding-bottom: 40px;
        }

        .zhaopin {
            width: 100%;
            display: flex;
            // justify-content: space-between;
            padding-bottom: 100px;

            .box {
                width: 680px;
                background: #FFFFFF;
                display: flex;
                padding: 60px;
                box-sizing: border-box;
                position: relative;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    cursor: pointer;
                }

                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: url(../../../../images/bg_zhaopin.png) no-repeat;
                    background-size: 100% 100%;
                }

                .left {
                    z-index: 5;

                    .lg-img {
                        @include height-width(180, 180);
                        border-radius: 50%;
                    }
                }

                .right {
                    z-index: 5;
                    padding: 26px 44px;
                    box-sizing: border-box;

                    .zhaopin-title {
                        @include font(36, 50, #424142, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        padding-bottom: 20px;
                    }

                    .zhaopin-describe {
                        @include font(18, 32, #383838, 400);
                        font-family: 'PingFangSC-Regular, PingFang SC';
                    }
                }
            }
        }
    }

    .public-padding-left-right {
        padding: 100px 260px 0;
        box-sizing: border-box;
    }
}
</style>
