<template>
    <div class="about_new_no-search">
        <!-- <div class="tabs">
            <div class="tabs_title" v-for="(item, index) in tab_list" :key="index" @click="changeTabs(item, index)"
                :class="{ active: active == index }">
                {{ item.title }}
            </div>
        </div> -->
        <div class="tabs_content">
            <div class="content_ul">
                <div class="content_li" @click="toDetail(item, index)" v-for="(item, index) in new_list.data"
                    :key="item.nid">
                    <div class="zd" v-if="item.isTop == 1">
                        置顶
                    </div>
                    <div class="left_img">
                        <img class="img_news" :src="item.lgImg" alt="" />
                    </div>
                    <div class="right_box">
                        <div class="left">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="describe">
                                {{ item.subTitle }}
                            </div>
                            <div class="browse_num">
                                <i class="iconfont icon-zhengyan icon-eye"></i>
                                <span class="num">{{ item.visit }}</span>
                            </div>
                        </div>
                        <div class="right">
                            <div class="day">{{ item.day }}</div>
                            <div class="year_month">{{ item.year_month }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pag_ing">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-size="pageSize" layout="prev,pager,next,jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            active: 0,
            // newsType: 1,
            // tab_list: [
            //     {
            //         title: "新闻中心",
            //         newsType: 1,
            //     },
            //     {
            //         title: "员工风采",
            //         newsType: 2,
            //     },
            // ],
            pageNum: 1,
            pageSize: 9,
            total: 0,
            //上一个现实的标题内容
            lastTitle: "",
            //下一个显示的标题内容
            nextTitle: "",
            //上一个的ID 用于跳转页面
            lastId: "",
            //下一个的ID 用于跳转页面
            nextId: "",
            // 控制按钮是否可以点击
            lastNoShow: "",
            nextNoShow: "",
            // new_list: []
        };
    },

    mounted() {
        this.getNewList()
    },
    computed: {
        ...mapGetters(["new_list"]),
    },
    methods: {
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getNewList()
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getNewList()
        },
        toDetail(row, id) {
            this.$router.push({
                name: "news",
                params: {
                    nid: row.nid,
                },
                // query: {
                //     id: id,
                // },
            });
        },
        async getNewList() {
            // const res = await this.$API.newApi({
            //     newsType: this.newsType, pageNum: this.pageNum, pageSize: this.pageSize
            // })
            // this.new_list = res.data.data
            let params = { pageNum: this.pageNum, pageSize: this.pageSize }
            this.$store.dispatch('getNew', params).then(res => {

                this.total = res.total;
            })
        },


        changeTabs(item, index) {
            this.pageNum = 1
            // this.newsType = item.newsType
            this.active = index;
            this.getNewList()
        },
    },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_new_no-search {
    width: 100%;

    .tabs {
        width: 100%;
        height: 100px;
        background: #fff;
        @include font(22, 33, #181b20, 400);
        @include text-center;
        font-family: "SourceHanSansCN-Bold, SourceHanSansCN";

        .tabs_title {
            margin-right: 100px;
            height: 100%;
            @include text-center;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            @include font(22, 33, #181b20, 600);
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: 0;
                width: 30px;
                height: 3px;
                border-radius: 2px;
                background: #111;
            }
        }
    }

    .tabs_content {
        width: 100%;
        padding: 60px 260px;
        background: #fafafa;
        box-sizing: border-box;

        .content_ul {
            width: 100%;

            .content_li {
                display: flex;
                @include font(16, 30, #6c7483, 400);
                font-family: "PingFangSC-Regular, PingFang SC";
                width: 100%;
                margin-bottom: 30px;
                position: relative;

                .zd {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    color: #fff;
                    position: absolute;
                    background: var(--bg-colors);
                    width: 100px;
                    padding: 5px 0 5px 20px;
                    top: -15px;
                    left: 70%;

                    &::after {
                        position: absolute;
                        right: -15px;
                        content: '';
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        transform: rotate(45deg);
                        background: #fff;
                        // border: 2px solid #fff;
                        // box-sizing: border-box;
                    }

                    &::before {
                        content: '';
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    cursor: pointer;
                }

                .left_img {
                    width: 400px;
                    height: 247px;

                    .img_news {
                        width: 100%;
                        height: 100%;
                    }
                }

                .right_box {
                    // flex: 1;
                    width: 73%;
                    background: #fff;
                    padding: 30px 50px 30px 40px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;

                    .left {
                        width: 65%;

                        .title {
                            width: 100%;
                            @include font(22, 28, #181b20, 600);
                            font-family: "PingFangSC-Semibold, PingFang SC";
                            margin-bottom: 16px;
                            /* 文字超出一行省略号 */
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .describe {
                            width: 100%;
                            @include font(18, 30, #6c7483, 400);
                            font-family: "PingFangSC-Semibold, PingFang SC";
                            margin-bottom: 27px;
                            min-height: 60px;
                            /* 文字超出两行省略号 */
                            word-break: break-all;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }

                        .browse_num {
                            .icon-eye {
                                margin-right: 8px;
                            }

                            .num {
                                @include font(16, 30, #6c7483, 400);
                                font-family: "PingFangSC-Semibold, PingFang SC";
                            }
                        }
                    }

                    .right {
                        width: 35%;
                        @include text-center;
                        flex-direction: column;

                        .day {
                            @include font(44, 66, #62657a, 400);
                            font-family: "SourceHanSansCN-Bold, SourceHanSansCN";
                        }

                        .year_month {
                            @include font(14, 21, #62657a, 500);
                            font-family: "SourceHanSansCN-Medium, SourceHanSansCN";
                        }
                    }
                }
            }
        }

        .pag_ing {
            /deep/ .el-pagination {
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                .btn-prev,
                .btn-next {
                    padding: 5px !important;
                    border: 1px solid #cbcfd3;
                }

                .el-pager {
                    .number {
                        border: 1px solid #cbcfd3;
                        margin: 0 2px;
                    }

                    .active {
                        background: var(--bg-colors);
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
