<template>
    <div class="pc_about_buju">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>
        <div class="pc_content">
            <div class="public_margin_top_bottom_font">
                {{ public_banner_info.showTitle }}
            </div>
            <div class="title" v-if="false">
                {{ public_banner_info.showText }}
            </div>

            <div class="atlas_img">
                <img :src="public_banner_info.showSmImg" alt="">
                <div v-if="false"> <!-- 去掉红色点  -->
                <div class="circleBox">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox1">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox2">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox3">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox4">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox5">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div class="circleBox6">
                    <div class="circle"></div>
                    <!-- <div class="circle1"></div> -->
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import banner from '../banner.vue'
import { mapGetters } from "vuex";
export default {
    name: '',
    components: { banner },
    data() {
        return {
            key: ''
        }
    },
    created() { },
    computed: {
        ...mapGetters(["public_banner_info"]),
    },
    mounted() {
        this.key = this.$route.params.key;
        this.$store.dispatch('getPageHeader', this.key)
    },
    methods: {

    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_about_buju {
    width: 100%;
    height: 100%;

    .public_margin_top_bottom_font {
        margin: 80px 0 40px;
        @include font(40, 56, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
        text-align: center;
    }

    .pc_content {
        width: 100%;
        // padding: 0 458px;
        box-sizing: border-box;

        .title {
            text-align: center;
            @include font(28, 46, #333, 400);
            font-family: 'PingFangSC-Regular, PingFang SC';
            padding: 24px 100px;
            margin-bottom: 62px;
            background: #fff;
            box-sizing: border-box;
            box-shadow: 0px 0px 24px 0px rgba(161, 97, 97, 0.06);
        }
    }

    .atlas_img {
        // width: 692px;
        // height: 861px;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0 30px 88px;
        box-sizing: border-box;
        img{
            width: 100%;
        }
        .showSmIng {
            width: 100%;
        }

        .circleBox {
            position: absolute;
            top: 79px;
            right: 114px;
            margin: 10px auto;
        }

        .circleBox1 {
            position: absolute;
            top: 217px;
            right: 142px;
            margin: 10px auto;
        }

        .circleBox2 {
            position: absolute;
            top: 226px;
            right: 172px;
            margin: 10px auto;
        }

        .circleBox3 {
            position: absolute;
            top: 312px;
            right: 108px;
            margin: 10px auto;
        }

        .circleBox4 {
            position: absolute;
            top: 338px;
            right: 108px;
            margin: 10px auto;
        }

        .circleBox5 {
            position: absolute;
            top: 288px;
            right: 232px;
            margin: 10px auto;
        }

        .circleBox6 {
            position: absolute;
            top: 355px;
            right: 190px;
            margin: 10px auto;
        }

        /* 扩散动画 */
        .circle,
        .circle1,
        .circle2,
        .circle3 {
            width: 10px;
            height: 10px;
            background: var(--bg-colors);
            border: 1px solid var(--bg-colors);
            border-radius: 999px;
            position: absolute;
            top: 50px;
            left: 15px;
        }

        .circle1,
        .circle2,
        .circle3 {
            /* animation: circleChange 2s 1s ease-out infinite; */
            animation-name: circleChange;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        .circle1 {
            animation-delay: 1s;
        }

        .circle2 {
            animation-delay: 2s;
        }

        .circle3 {
            animation-delay: 3s;
        }

        @keyframes circleChange {
            0% {
                transform: scale(1);
                opacity: 0.95;
            }

            25% {
                transform: scale(1.5);
                opacity: 0.75;
            }

            50% {
                transform: scale(2);
                opacity: 0.5;
            }

            75% {
                transform: scale(2.5);
                opacity: 0.25;
            }

            100% {
                transform: scale(3);
                opacity: 0.05;
            }
        }
    }
}
</style>
