<template>
    <div class="pc_about_xinwen-huodong">
        <!-- banner图 -->
        <div class="pc_banner public-yd-css-margin">
            <img class="banner_img" :src="$store.state.public_banner_info.smImg" alt="">
            <div class="title_or_input">
                <div class="title">{{ $store.state.public_banner_info.title }}</div>
                <div class="search_box">
                    <input @input="searchInput" v-model="search_val" @keyup.enter="search" type="text" class="search"
                        placeholder="请输入关键字搜索">
                    <i class="iconfont icon-sousuo icon_search" @click="search"></i>
                </div>
            </div>
        </div>
        <!-- tabs切换-非搜索状态下 -->
        <div class="show_search" v-if="!search_show">
            <aboutNewNoSearch></aboutNewNoSearch>
        </div>
        <!-- 搜索状态下 -->
        <div class="show_search" v-if="search_show">
            <aboutNewSearch :search_val="search_val"></aboutNewSearch>
        </div>

    </div>
</template>

<script>
import banner from '../banner.vue'
import aboutNewNoSearch from './about_content/about_new_no-search.vue'
import aboutNewSearch from './about_content/about_new_search.vue'
import _ from 'lodash'
export default {
    name: '',
    components: { banner, aboutNewNoSearch, aboutNewSearch },
    data() {
        return {
            search_show: false,
            search_val: '',
            new_list: {},
            pageNum: 1,
            pageSize: 10,
        }
    },
    created() { },
    mounted() {
        this.key = this.$route.params.key;
        localStorage.setItem('xinwen', this.key)
        this.getInit()
    },
    methods: {
        getInit() {
            this.$store.dispatch('getPageHeader', this.key)
        },
        getNewList(search_val) {
            if (this.search_val != '') {
                this.$store.dispatch('getNew', { search: search_val, pageNum: this.pageNum, pageSize: this.pageSize })
            }
        },
        search() {
            if (this.search_val != '') {
                this.search_show = true
            } else {
                this.search_show = false
            }
            this.getNewList(this.search_val)
        },
        searchInput: _.debounce(function () {
            this.setSearchInput()
        }, 1000),
        setSearchInput() {
            if (this.search_val != '') {
                this.search_show = true
            } else {
                this.search_show = false
            }
            this.getNewList(this.search_val)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_about_xinwen-huodong {

    .pc_banner {
        width: 100%;
        position: relative;
        // padding: 0 40px;

        .banner_img {
            width: 100%;
            height: 100%;
            min-height: 340px;
            vertical-align: middle;
        }

        .title_or_input {
            position: absolute;
            left: 50%;
            top: 90px;
            text-align: center;
            transform: translate(-50%);
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;

            .title {
                @include font(40, 52, #fff, 600);
                font-family: "PingFangSC-Semibold, PingFang SC";
                margin-bottom: 48px;
            }

            .search_box {
                background: #fff;
                display: flex;
                position: relative;
                align-items: center;
                // width: 800px;
                height: 60px;

                .search {
                    width: 90%;
                    padding: 20px 28px;
                    height: 100%;
                    @include font(22, 20, #999, 400);
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    box-sizing: border-box;
                    border: none;
                }

                .icon_search {
                    width: 10%;
                    position: absolute;
                    right: 0;
                    color: #666;
                    font-size: 20px;
                    height: 100%;
                    @include text-center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

    }
}
</style>
