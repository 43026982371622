<template>
  <div class="pc_jion_list">
    <div class="pc_jion_list_li" v-for="(item, index) in list" :key="index">
      <img class="lg_img" :src="item.lgImg" alt="">
      <div class="text_box">
        <div class="core_box">
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{ item.lgText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    list: {
      type: Array
    },
  },
  data() {
    return {};
  },
  created() { },
  mounted() {
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.pc_jion_list {
  display: flex;
  justify-content: center;
  .pc_jion_list_li {
    width: 100%;
    width: 690px;
    padding-right: 20px;

    &:last-child {

      padding-right: 0;
    }

    .lg_img {
      width: 100%;
    }

    .text_box {
      padding: 0 30px;

      transform: translate(0, -50px);

      .core_box {

        padding: 40px 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 14px 0px rgba(121, 51, 61, 0.08);

        .title {
          font-size: 32px;
          font-family: 'PingFangSC-Semibold, PingFang SC';
          font-weight: 600;
          color: #1A1A1A;
          line-height: 40px;
          padding-bottom: 20px;

          &::before {
            position: absolute;
            content: '';
            display: inline-block;
            width: 25px;
            height: 23px;
            background: url('../../../../images/about/pic_about_decorate.png') no-repeat;
            background-size: 100% 100%;
          }
        }

        .text {
          width: 550px;
          overflow-y: scroll;
          height: 192px;
          font-size: 18px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #383838;
          line-height: 32px;

          &::-webkit-scrollbar {
            display: none;
            /* Chrome、Safari和Opera浏览器 */
          }
        }
      }

    }
  }
}
</style>
