import Vue from "vue";
import Vuex from "vuex";
import persist from "vuex-persistedstate";
import getters from "./getters";
Vue.use(Vuex);
import {
  indexProductApi,
  homeProductApi,
  indexMenusApi,
  indexSwiperApi,
  indexInfoApi,
  PageHeaderApi,
  newApi,
  companyApi,
} from "@/request/api.js";
import moment from "moment";
export default new Vuex.Store({
  state: {
    productLg: [], //合作伙伴大屏图片
    productSm: [], //合作伙伴小屏图片
    menus: [], //菜单
    swiper: [], //轮播图
    info: [], // 基础信息、如各种图片及邮箱
    public_banner_info: {}, // banner图片+title
    new_list: [], // 新闻列表
    company: [], // 公司基础信息、如股票等
    menus_index: -1, // 菜单索引
    homeProductList: [], // 首页轮播产品大屏
  },
  mutations: {
    /* 热点产品 */
    SET_HOTPRODUCT(state, hotProduct) {
      state.productSm = hotProduct.filter((item) => {
        return item.showSwitch == "sm" || item.showSwitch == "all";
      });
      state.productLg = hotProduct.filter((item) => {
        return item.showSwitch == "lg" || item.showSwitch == "all";
      });
    },
    /* 首页轮播产品 */
    SET_HOMEPROCUT(state, homeProduct) {
      state.homeProductList = homeProduct
    },
    /* 菜单 */
    SET_MENUS(state, menus) {
      state.menus = menus;
    },
    /* 轮播图 */
    SET_SWIPER(state, swiper) {
      state.swiper = swiper;
    },
    /* 基础信息 */
    SET_INFO(state, info) {
      state.info = info;
    },
    /* 页面头部 */
    SET_PAGE_HEADER(state, public_banner_info) {
      state.public_banner_info = public_banner_info;
    },
    /* 公司基础信息、如股票/业务等 */
    SET_COMPANY(state, company) {
      state.company = company;
    },
    /* 新闻列表  */
    SET_NEW(state, newList) {
      state.new_list = newList;
      state.new_list.data.forEach((item) => {
        item.publishTime = moment(item.publishTime).format("YYYY-MM-DD");
        item.year_month = moment(item.publishTime).format("YYYY-MM");
        item.day = moment(item.publishTime).format("DD");
      });
    },

    /* 菜单栏点击事件 */
    changeMenus(state, index) {
      state.menus_index = index;
    },
  },
  actions: {
    // 获取热点产品
    getHotProduct({ commit }) {
      return new Promise((resolve, reject) => {
        indexProductApi().then((res) => {
          commit("SET_HOTPRODUCT", res.data.data);
          resolve(res.data.data);
        });
      });
    },
    // 获取首页轮播产品
    getHomeProcut({ commit }) {
      return new Promise((resolve, reject) => {
        homeProductApi().then((res) => {
			console.log('首页轮播产品', res);
          commit("SET_HOMEPROCUT", res.data.data);
          resolve(res.data.data);
        });
      });
    },
    getInfoApi({ commit }) {
      return new Promise((resolve, reject) => {
        indexInfoApi().then((res) => {
          commit("SET_INFO", res.data.data);
          resolve(res.data.data);
        });
      });
    },
    getApiAll({ commit }) {
      indexMenusApi().then((res) => {
        commit("SET_MENUS", res.data.data);
      });
      indexSwiperApi().then((res) => {
        commit("SET_SWIPER", res.data.data);
      });

      companyApi().then((res) => {
        commit("SET_COMPANY", res.data.data);
      });
    },
    getNew({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (state.new_list) {
          newApi(data).then((res) => {
            commit("SET_NEW", res.data.data);
            resolve(res.data.data);
          });
        }
      });
    },
    getPageHeader({ state, commit }, key) {
      return new Promise((resolve, reject) => {
        // if (state.about) {
        PageHeaderApi(key).then((res) => {
			console.log('页面头部', res)
          commit("SET_PAGE_HEADER", res.data.data);
          resolve(res.data.data);
        });
        // }
      });
    },
  },
  plugins: [
    new persist({
      storage: window.sessionStorage,
    }),
  ],
  getters,
});
