<template>
  <div class="index type-arae ">
    <div class="content-box">

      <!-- 内容-banner -->
      <el-carousel class="content-banner" :height="banner_heights + 'px'" :interval="5000" arrow="always">
        <el-carousel-item v-for="item in $store.state.swiper" :key="item.sid">
          <img class="banner-imgs" ref="banner_heights" @load="imgloads()" :src="item.lgImg" alt="">
        </el-carousel-item>
      </el-carousel>

      <!-- 智能制造板块  -->
      <div class="smart-box index-modules">
        <div class="index-modules-title">
          <span class="title">智能制造板块</span>
        </div>
      </div>
      <!-- about/jianjie#smart -->
      <div class="smart-ul" ref="smartRef" :style="`background-image:url(${smart_img})`">
        <a @click="goProduct" :class="['smart-li', smart_index == index ? 'active-li' : '']"
          @mouseover="mouseoverSmart(item, index)" v-for="(item, index) in smart_list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="children-ul" v-if="smart_index == index" v-for="(it, i) in item.categories">
            <div>{{ it }}</div>
          </div>
        </a>
      </div>

      <!-- 内容——热点产品 -->
      <div class="content-hot index-modules">
        <!-- 标题 -->
        <div class="index-modules-title">
          <span class="title">核心产品</span>
        </div>
        <homePro></homePro>
        <div class="btn-box">
          <button class="btn-all" @click="goProduct">查看全部</button>
        </div>
        <!-- 图片盒子 -->
        <!-- <div class="index-img-box">
          <div class="index-img-ul">
            <div class="swiper-left-icon" @click="changeSwiper('left')">
              <span class="jt"></span>
            </div>
            <el-carousel arrow="never" indicator-position="outside" @change="changeProduct"
              :height="product_heights + 'px'" ref="productRef">
              <el-carousel-item v-for="(item, index) in $store.state.productLg" :key="index">
                <template v-for="(it, i) in item.list">
                  <div class="index-img-li" ref="product_heights" v-if="it.homeShow == 1" :key="i">
                    <img class="swiper-img" @load="imgload()" :src="it.lgImg" alt="" />
                    <div class="swiper-text">{{ it.title }}</div>
                  </div>

                </template>

              </el-carousel-item>
            </el-carousel>
            <div class="swiper-right-icon" @click="changeSwiper('right')">
              <span class="jt"></span>
            </div>
            <div class="btn-box">
              <button class="btn-all" @click="goProduct">查看全部</button>
            </div>
          </div>
        </div> -->
      </div>

      <!-- 视频 -->
      <div class="video-player" v-if="$store.state.info.video !== ''">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
          :options="playerOptions"></video-player>
      </div>

      <!-- 合作客户 -->
      <div class="content-feat index-modules">
        <!-- 标题 -->
        <div class="index-modules-title feat-title">
          <span class="title">合作客户</span>
        </div>
        <div id="wrapper">
          <div class="feat-img-ul-one">
            <div class="feat-img-li" ref="oneSwiper" v-for="item in before_partner" :key="item.pid">
              <img :src="item.logo" alt="" @load="oneSwiperMeth()">
            </div>
            <div class="feat-img-li" v-for="item in before_partner" :key="item.logo">
              <img :src="item.logo" alt="">
            </div>

          </div>
          <div class="feat-img-ul-two">
            <div class="feat-img-li" ref="twoSwiper" v-for="item in back_partner" :key="item.pid">
              <img :src="item.logo" alt="" @load="twoSwiperMeth()">
            </div>
            <div class="feat-img-li" v-for="item in back_partner" :key="item.logo">
              <img :src="item.logo" alt="">
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import pcIndexHotTabs from '@/views/pc/components/index/pc_index_hot_tabs.vue'
import homePro from '@/views/pc/components/index/homePro.vue'
export default {
  name: "",
  components: {
    pcIndexHotTabs,
	homePro
  },
  data() {
    return {
      tabs_index: 0,//tab索引
      banner_heights: 0, //轮播图高度
      product_heights: 0, //轮播图高度
      oneSwiper: 0,// 合作客户宽度
      twoSwiper: 0,// 合作客户宽度
      before_partnerLength: '',
      back_partnerLength: '',
      before_partner: [],
      back_partner: [],
      screenWidth: null,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:8', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: this.$store.state.info.video //url地址
        }],
        poster: this.$store.state.info.videoCover, //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },/* 视频播放器 */
      smart_show: false,
      smart_index: 0,
      smart_img: '',
      smart_list: [
      ],
    };
  },
  mounted() {
    // console.log('this.$store.state', this.$store.state)
    window.addEventListener('load', this.preloadImages());
    // window.addEventListener("resize",, false);
    window.addEventListener("resize", () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();

    }, false);

    this.getPartner()
    this.getInit()
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        console.log('屏幕变了')
        this.imgload()
        this.imgloads()
        this.oneSwiperMeth()
        this.twoSwiperMeth()
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    toSwiperSlide(evt) {
      //切换到第一个slide，速度为1秒
      this.swiper.slideTo(evt, 1000, false);
    },
    async getInit() {
      const res = await this.$API.siteBannerApi()
      if (res.data.meta.code == 200) {
        this.smart_list = res.data.data
        this.smart_img = res.data.data[0].lgImg
      }
    },
    preloadImages() {
      this.smart_list.forEach((item, index) => {
        const img = new Image();
        img.src = this.smart_list[i].lgImg;
      })


    },
    mouseoverSmart(row, index) {
      this.smart_index = index
      this.smart_img = row.lgImg
    },

    //轮播图适配高度
    imgloads() {
      if (this.$refs.banner_heights && this.$refs.banner_heights[0]) {
        // this.$nextTick(() => {
        this.banner_heights = this.$refs.banner_heights[0].offsetHeight;
        // });
      }
    },
    imgload() {
      if (this.$refs.product_heights && this.$refs.product_heights[0]) {
        // this.$nextTick(() => {
        this.product_heights = this.$refs.product_heights[0].offsetHeight * 2;
        // });
      }
    },

    oneSwiperMeth() {
      if (this.$refs.oneSwiper && this.$refs.oneSwiper[0]) {
        // this.$nextTick(() => {
        this.oneSwiper = this.$refs.oneSwiper[0].offsetWidth * this.before_partner.length + this.before_partner.length * 25 + 'px';
        this.before_partnerLength = this.before_partner.length * 2 + 's'
        // });
      }
    },
    twoSwiperMeth() {
      if (this.$refs.twoSwiper && this.$refs.twoSwiper[0]) {
        // this.$nextTick(() => {
        this.twoSwiper = this.$refs.twoSwiper[0].offsetWidth * this.back_partner.length + this.back_partner.length * 25 + 'px';
        this.back_partnerLength = this.back_partner.length * 2 + 's'
        // });
      }
    },
    // 点击tab
    changeTabs(index) {
      this.tabs_index = index
      this.$refs.productRef.setActiveItem(index)

    },
    /* 热点产品顶部tab赋索引 */
    changeProduct(index) {
      this.tabs_index = index
    },
    /* 热点产品-手动轮播 */
    changeSwiper(index) {
      if (index == 'left') {
        this.$refs.productRef.prev()
      } else {
        this.$refs.productRef.next()
      }
    },
    // 合作伙伴
    getPartner() {
      // const res = await this.$API.indexPartnerApi()
	  this.$API.indexPartnerApi().then(res => {
		  console.log(res);
		  let half = Math.ceil(res.data.data.length / 2)
		  this.before_partner = res.data.data.splice(0, half)
		  this.back_partner = res.data.data.splice(-half)
	  })
      
    },
    goProduct() {

      this.$store.commit('changeMenus', 1)
      this.$router.push('/product')
    }
  },

};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";
// 修改样式

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

.btn-box {
  margin: 50px auto 0;
  text-align: center;
  overflow: hidden;
  .btn-all {
    font-family: 'PingFangSC-Regular, PingFang SC';
    @include font(16, 22, #333, 400);
    padding: 14px 48px;
    background: #fff;
    border: 1px solid #666666;
    box-sizing: border-box;

    &:hover {
      color: #fff;
      background: var(--bg-colors);
      cursor: pointer;
      border: none;
    }
  }
}
.index {
  width: 100%;
  height: 100%;
  background: #fff;

  .content-box {
    width: 100%;

    .content-banner {
      .banner-imgs {
        width: 100%;
      }

      /deep/ .el-carousel__indicators {
        // 指示器
        left: 50%;
        transform: translate(-50%, 0);
        // transform: unset;
      }

      /deep/ .el-carousel__button {
        // 指示器按钮
        @include height-width(12, 12);
        border: none;
        border-radius: 50%;
        background-color: #fff;
      }

      /deep/ .is-active .el-carousel__button {
        // 指示器激活按钮
        background: var(--bg-colors);
        @include height-width(12, 40);
        border: none;
        border-radius: 10px;
      }

    }

    .smart-box {
      width: 100%;
    }

    .index-modules {
      padding: 100px 260px 60px;
    }

    .smart-ul {
      display: flex;
      width: 100%;
      font-size: 18px;
      font-family: 'PingFangSC-Regular, PingFang SC';
      font-weight: 400;
      color: #fff;
      line-height: 34px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      pointer-events: auto;

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: #00000082;
        pointer-events: none;
      }

      @keyframes myfirst {
        0% {
          opacity: 0;
        }

        40% {
          opacity: 0.75;
        }



        100% {
          opacity: 1;
        }
      }

      @keyframes fadeInLeft {
        from {
          opacity: 0;
          -webkit-transform: translate(-1000px, 0);
          transform: translate(-1000px, 0);
        }

        to {
          opacity: 1;
          -webkit-transform: translate(10px, 0);
          transform: translate(10px, 0);
        }
      }



      .smart-li {
        width: calc(100% / 6);
        min-height: 560px;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 180px 0;
        border-right: 1px solid #fff;
        z-index: 5;
        color: #fff;

        &:hover {
          cursor: pointer;

          .title {
            animation: fadeInLeft 1s;
          }

          .children-ul {
            animation: myfirst 1.5s;
          }
        }

        .title {
          width: 32px;
          font-size: 32px;
          font-family: 'PingFangSC-Semibold, PingFang SC';
          font-weight: 600;
          color: #fff;
          line-height: 38px;

        }
      }

      .active-li {
        width: calc(100% / 6 * 3);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 226px;

        box-sizing: border-box;

        .title {
          width: 100%;
          font-size: 32px;
          font-family: 'PingFangSC-Semibold, PingFang SC';
          font-weight: 600;
          color: #fff;
          line-height: 38px;
          padding-bottom: 20px;
        }
      }
    }

    // 事业部
    .content-hot {
      width: 100%;
      background: #fff;
      @include font(14, 28, #666666, 400);
      overflow: hidden;
      .index-tabs {
        width: 100%;
        margin-top: 40px;
      }

      .index-describe-box {
        margin-top: 20px;
        background: #F9F9F9;
        padding: 24px 0;
        box-sizing: border-box;
        width: 100%;
        @include text-center;

        .index-describe {

          @include font(14, 28, #666666, 400);

          text-align: center;
          font-family: 'PingFangSC-Regular, PingFang SC';
        }
      }
    }

    .video-player {
      width: 100%;
    }

    .content-feat {
      .feat-title {
        margin-bottom: 60px;
      }

      #wrapper {
        width: 100%;
        overflow: hidden;

        .feat-img-ul-one {

          animation: danmu v-bind(before_partnerLength) infinite linear;
          -webkit-animation: danmu v-bind(before_partnerLength) linear infinite;
          width: v-bind(oneSwiper);
          display: flex;

          &:hover {
            animation-play-state: paused;
          }

          .feat-img-li {
            flex-shrink: 0; // 关键代码： 子元素设置不缩小
            margin: 0 15px 30px;
            text-align: center;
            border-radius: 25px;

            @include height-width(110, 224);

            img {
              width: 100%;
			  object-fit: contain;
              // height: 100%;
              // border-radius: 10px;
            }
          }
        }

        .feat-img-ul-two {

          animation: danmus v-bind(back_partnerLength) infinite linear;
          -webkit-animation: danmus v-bind(back_partnerLength) linear infinite;
          width: v-bind(twoSwiper);
          display: flex;

          &:hover {
            animation-play-state: paused;
          }

          .feat-img-li {
            flex-shrink: 0; // 关键代码： 子元素设置不缩小
            margin: 0 15px 30px;
            text-align: center;
            border-radius: 25px;

            @include height-width(110, 224);


            img {
              width: 100%;
              // height: 100%;
			  object-fit: contain;
            }
          }
        }

        @keyframes danmu {
          from {
            left: 0;
            transform: translate(2%);
          }

          to {
            left: 100%;
            transform: translate(-100%);
          }
        }

        @keyframes danmus {
          from {
            left: 0;
            transform: translate(-100%);
          }

          to {
            left: 100%;
            transform: translate(0);
          }
        }
      }


    }
  }
}
@media screen and (max-width: 1280px) {
  .index-img-box {
    width: 960px;
  }
}
</style>
