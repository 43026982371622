<template>
    <div class="yd_jion_page">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>

        <div class="yd-jion-page-content" v-if="key == 'shehui'">
            <ydJionPageContent></ydJionPageContent>
        </div>

        <div v-else class="public-padding-left-right">
            <div class="fwb" v-for="item in school" :key="item.lid" v-html="item.smText">
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/views/yd/components/banner.vue'
import ydJionPageContent from '@/views/yd/components/jion/yd_jion_page-content.vue'
import { mapGetters } from "vuex";
export default {
    name: '',

    components: { banner, ydJionPageContent },
    data() {
        return {
            key: '',
            school: [],
        }
    },
    computed: {
        ...mapGetters(["public_banner_info"]),
    },
    mounted() {
        this.getInit()
        this.getSchool()
    },
    methods: {
        async getInit() {
            this.key = this.$route.params.key
            this.$store.dispatch('getPageHeader', this.key)
        },
        async getSchool() {
            if (this.key == 'xiaoyuan') {
                this.$API.listApi('jion_xiaoyuan ').then(res => {
                    if (res.data.meta.code == 200) {
                        this.school = res.data.data
                    }
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped >
.yd_jion_page {
    width: 100%;
    padding: 1px 0;
    box-sizing: border-box;

    .public-padding-left-right {

        padding: 40px 30px;
        box-sizing: border-box;

        .fwb {
            font-size: 20px;
            // display: flex;
            // justify-content: center;
        }
    }
}
</style>
