<template>
    <div class="pc_rd_yanfa">
        <!-- <div class="public-title">研发能力</div> -->
        <div class="pc-list">
            <pcList :list="list"></pcList>
        </div>
    </div>
</template>

<script>
import pcList from '../pc_list.vue'
export default {
    props: {
        title: {
            type: String
        }
    },
    components: { pcList },
    data() {
        return {
            list: []
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_yanfa')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_yanfa {
    width: 100%;


    .public-title {
        text-align: center;
        @include font(36, 32, #1a1a1a, 600);
        padding: 120px 0 60px 0;
    }

    .pc-list {
        padding-bottom: 100px;
    }
}
</style>
