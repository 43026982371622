<template>
    <div class="type-arae">
        <banner :images="$store.state.public_banner_info.lgImg" :title="$store.state.public_banner_info.title"></banner>
        <div class="product_box">
            <div class="product_parent pubilc-left-right">
                <div class="product_ul">
                    <div :class="['product_li', kelleg_key == item.pid ? 'active_product_li' : '']"
                        v-for="(item, index) in product_parent" :key="item.pid" @mouseenter="changetabs(item, index)">
                        <div class="icon">
                            <img v-if="kelleg_key !== item.pid" :src="item.icon" alt="">
                            <img v-else :src="item.activeIcon" alt="">
                        </div>
                        <!-- 顶部tab -->
                        <div class="text">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="product_childrens pubilc-left-right">
                <div class="title">产品类别</div>
                <div class="product_content">
                    <template v-for="(pit, pid) in product_parent">
                        <div style="width:100%" :key="pid" v-if="kelleg_key == pit.pid">
                            <div class="content_left_li">
                                <div class="left">
                                    <!-- 左侧tab -->
                                    <template v-for="(cit, cid) in pit.list">
                                        <div :class="['content_left', children_index == cid ? 'active_content_left' : '']"
                                            @click="children_index = cid">
                                            {{ cit.title }}
                                        </div>
                                    </template>
                                </div>
                                <!-- 右侧tabs -->
                                <template v-for="(cit, cid) in pit.list">
                                    <div :key="cid" class="content_right" v-if="children_index == cid">
                                        <div class="left_img">
                                            <img class="lg_img" :src="cit.lgImg" alt="">
                                        </div>
                                        <div class="right_text">
                                            <div class="title">{{ cit.title }}</div>
                                            <div class="describe" style="white-space: pre-wrap;" v-html="cit.text">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import banner from '@/views/pc/components/banner.vue'
export default {
    name: '',
    components: { banner },
    data() {
        return {
            key: '',
            product_parent: [],
            children_index: 0,
            kelleg_key: 0,
        }
    },

    mounted() {
        /* 图片 */
        this.$store.dispatch('getPageHeader', 'product')
        this.getInit()
    },
    watch: {
        //监听路由携带的参数type,mold  配置地址:src/router/index.js
        '$route': {
            //执行 val:当前最新值 old:上一刻的值
            handler: function (val, old) {
                this.kelleg_key = window.location.href.split('#')[1]
            },
            // 立即处理 进入页面就触发
            immediate: true,
            // 深度监听 属性的变化
            deep: true
        }
    },
    methods: {
        async getInit() {
            const res = await this.$API.productApi()
            this.product_parent = res.data.data
            this.kelleg_key = this.product_parent[0].pid
        },
        changetabs(item, index) {
            this.kelleg_key = item.pid
            this.children_index = 0
        },

    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.product_box {
    width: 100%;
    height: 100%;
    background: #FAFAFA;

    .pubilc-left-right {
        padding: 0 360px;
    }

    .product_parent {
        box-sizing: border-box;
        background: #fff;

        .product_ul {
            display: flex;
            @include font(16, 22, #666, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding: 40px 0;
            justify-content: space-between;
            .product_li {
                width: calc(100% / 7);
                padding: 15px 40px;
                box-sizing: border-box;
                @include text-center;
                flex-direction: column;

                &:hover {
                    cursor: pointer;
                }



                .icon {
                    @include text-center;
                    width: 100px;
                    height: 100px;
                    padding-bottom: 5px;

                    img {
                        width: 100%;
                    }

                    // @media screen and(max-width: 1536px) {
                    //     padding-bottom: 20px;

                    //     img {
                    //         transform: scale(0.7);
                    //     }
                    // }

                    // @media screen and(max-width: 1440px) {
                    //     padding-bottom: 20px;

                    //     img {
                    //         transform: scale(0.7);
                    //     }
                    // }

                    // @media screen and(max-width: 1096px) {
                    //     padding-bottom: 25px;

                    //     img {
                    //         transform: scale(0.6);
                    //     }
                    // }

                    // @media screen and(max-width: 990px) {
                    //     padding-bottom: 30px;

                    //     img {
                    //         transform: scale(0.6);
                    //     }
                    // }
                }

                .text {
                    font-size: 18px;
                    white-space: nowrap;
                }

            }

            .active_product_li {
                .text {
                    color: var(--bg-colors);
                }
            }
        }
    }

    .product_childrens {
        background: #FAFAFA;
        padding-bottom: 80px;

        .title {
            @include font(24, 52, #151515, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding: 22px 0 20px;
        }

        .product_content {
            width: 100%;
            display: flex;
            background: #FAFAFA;

            .content_left_li {
                width: 100%;
                display: flex;


                ::-webkit-scrollbar {
                    width: 0 !important;
                }

                ::-webkit-scrollbar {
                    width: 0 !important;
                    height: 0;
                }

                .left {
                    height: calc(64px * 6);
                    width: 200px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    margin-right: 30px;
                    box-sizing: border-box;

                }

                .content_left {
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    box-sizing: border-box;
                    background: hsl(0, 0%, 100%);
                    height: 64px;
                    @include font(18, 22, #151515, 500);
                    font-family: 'PingFangSC-Medium, PingFang SC';
                    margin-bottom: 3px;
                    // width: 200px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .active_content_left {
                    background: var(--bg-colors);
                    color: #fff;
                }

                .content_right {
                    width: 980px;
                    // flex: 1;
                    padding: 40px 70px 68px;
                    box-sizing: border-box;
                    background: #fff;
                    display: flex;

                    .left_img {
                        width: 279px;
                        height: 238px;
                        // padding: 23px 53px 18px 18px;
                        box-sizing: border-box;

                        .lg_img {
                            width: 100%;
							height: 100%;
							object-fit:contain;
                        }
                    }

                    .right_text {
                        @include font(16, 28, #444452, 400);
                        font-family: 'AlibabaPuHuiTiR';
                        padding: 0 0 0 127px;

                        .title {
                            @include font(28, 0, #151515, 600);
                            font-family: 'AlibabaPuHuiTiR';
                            padding-bottom: 32px;
                        }

                        .describe {
                            width: 480px;
                            height: 225px;
                            overflow-y: scroll;
                            font-size: 20px;
							line-height: 1.8;
                        }
                    }
                }
            }
        }
    }
}
</style>
