<template>
    <div class="banner_btm_describe public-padding">
        <div class="top">
            <div class="top_title">
                <!-- {{ showTitle }} -->
            </div>
            <div class="top_describe" style="white-space: pre-wrap;" v-html="showText">
            </div>
        </div>
        <div class="bottom">
            <img class="show_smImg" :src="showSmImg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showText: {
            type: String
        },
        showTitle: {
            type: String
        },
        showSmImg: {
            type: String
        }
    },
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.banner_btm_describe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px 80px;
    box-sizing: border-box;
    background: #fff;

    .top {
        display: flex;
        flex-direction: column;
        width: 100%;
        // margin-right: 84px;
        padding: 80px 0 0;


        ::-webkit-scrollbar {
            width: 0 !important;
        }

        ::-webkit-scrollbar {
            width: 0 !important;
            height: 0;
        }

        // .top_title {
        //     @include font(36, 50, #1a1a1a, 600);
        //     font-family: 'PingFangSC-Semibold, PingFang SC';
        //     padding-bottom: 40px;
        //     text-align: center;
        //     width: 100%;
        // }

        .top_describe {
            @include font(28, 52, #383838, 400);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            width: 100%;
            overflow-y: scroll;

        }
    }

    .bottom {
        width: 100%;
        padding-top: 40px;

        .show_smImg {
            width: 100%;
        }
    }


}

// .public-padding {
//     padding: 105px 260px;
//     box-sizing: border-box;
// }
</style>
