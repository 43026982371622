<template>
    <div class="type-arae pc-rd">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>
        <!-- <div class="pc-rd-1" v-if="key == 'jishu'">
            <pcRdJishu></pcRdJishu>
        </div> -->
        <div class="pc-rd-2">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showText="public_banner_info.showText"
                    :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <div class="pc-rd-yanfa">
                <pcRdYanfa :title="public_banner_info.title"></pcRdYanfa>
            </div>
        </div>
        <!-- <div class="pc-rd-3" v-if="key == 'shiyan'">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showText="public_banner_info.showText"
                    :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <pcRdShiyan :title="public_banner_info.title"></pcRdShiyan>
        </div>
        <div class="pc-rd-4" v-if="key == 'jujiao'">
            <div class="banner-btm-describe">
                <bannerBtmDescribe :showLgImg="public_banner_info.showLgImg" :showText="public_banner_info.showText"
                    :showTitle="public_banner_info.showTitle">
                </bannerBtmDescribe>
            </div>
            <pcRdJujiao :title="public_banner_info.title"></pcRdJujiao>
        </div> -->
    </div>
</template>

<script>
import pcRdJishu from '@/views/pc/components/rd/pc_rd_jishu.vue'
import pcRdJujiao from '@/views/pc/components/rd/pc_rd_jujiao.vue'
import pcRdShiyan from '@/views/pc/components/rd/pc_rd_shiyan.vue'
import pcRdYanfa from '@/views/pc/components/rd/pc_rd_yanfa.vue'

import banner from '@/views/pc/components/banner.vue'
import bannerBtmDescribe from '@/views/pc/components/banner_btm_describe.vue'
import { mapGetters } from "vuex";
export default {
    name: '',
    components: { pcRdJishu, pcRdJujiao, pcRdShiyan, pcRdYanfa, banner, bannerBtmDescribe },
    data() {
        return {
            public_banner_info: {},
        }
    },
    computed: {
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            this.$store.dispatch('getPageHeader', 'rd').then(res => {
                console.log(res)
                this.public_banner_info = res
            })
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-rd {
    @include font(14, 22, #111, 400);
}
</style>
