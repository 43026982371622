<template>
    <div class="yd_about">
        <div class="about-1" v-if="key == 'jianjie'">
            <aboutJianJie></aboutJianJie>
        </div>
        <div class="about-2" v-if="key == 'rongyu'">
            <aboutRongYu></aboutRongYu>
        </div>
        <div class="about-3" v-if="key == 'buju'">
            <aboutBuju></aboutBuju>
        </div>
        <div class="about-4" v-if="key == 'xinwen-huodong'">
            <aboutXinwenHuodong></aboutXinwenHuodong>
        </div>
        <div class="about-5" v-if="key == 'zeren'">
            <aboutZeren></aboutZeren>
        </div>
    </div>
</template>

<script>
import aboutJianJie from '@/views/yd/components/about/yd_about_jianjie.vue'
import aboutRongYu from '@/views/yd/components/about/yd_about_rongyu.vue'
import aboutBuju from '@/views/yd/components/about/yd_about_buju.vue'
import aboutXinwenHuodong from '@/views/yd/components/about/yd_about_xinwen-huodong.vue'
import aboutZeren from '@/views/yd/components/about/yd_about_zeren.vue'
export default {
    name: '',
    components: { aboutJianJie, aboutRongYu, aboutBuju, aboutXinwenHuodong, aboutZeren },
    data() {
        return {
            key: ''
        }
    },
    created() {
        this.key = this.$route.params.key;
    },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about-1,
.about-2,
.about-3,
.about-4,
.about-5 {
    padding: 1px;
    box-sizing: border-box;

}
</style>
