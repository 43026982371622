<template>
    <div class="relation type-arae">
        <banner :images="public_banner_info.smImg" :title="public_banner_info.title"></banner>
        <div class="atlas">
            <img class="address-img" :src="public_banner_info.showSmImg" alt="">
            <div class="address-box">
                <div class="title">办公地址</div>
                <div class="cont">{{ company.address }}</div>
            </div>
        </div>
        <div class="abstract">
            <div class="company-name">海联金汇科技股份有限公司</div>
            <div class="company-address">
                <img src="@/images/relation/icon_place.png" alt="">
                <div class="address">{{ company.address }}</div>
            </div>
            <div class="company-phone">
                <img src="@/images/relation/icon_telephone.png" alt="">
                <div>{{ info.phone }}</div>
            </div>
            <div class="company-email">
                <img src="@/images/relation/icon_mail.png" alt="">
                <div>{{ info.email }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import banner from '@/views/yd/components/banner.vue'
import { mapGetters } from 'vuex'
export default {
    name: '',
    components: { banner },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'info',
            'public_banner_info',
            'company'
        ]),
    },

    created() {
        this.$store.dispatch('getPageHeader', 'lianxi')
    },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.relation {
    padding: 1px;
    width: 100%;
    height: 100%;

    @include font(22, 25, #1a1a1a, 400);

    .abstract {
        padding: 104px 160px;
        box-sizing: border-box;
        background: url('../../../../images/relation/bg.png') no-repeat;
        // background-size:  100%;
        display: flex;
        flex-direction: column;

        .company-name {
            width: 100%;
            @include font(32, 50, #1a1a1a, 600);
            padding-bottom: 40px;
        }

        .company-address {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 40px;

            img {
                padding-right: 10px;
            }

        }

        .company-phone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 40px;

            img {
                padding-right: 10px;
            }
        }

        .company-email {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 40px;

            img {
                padding-right: 10px;
            }
        }
    }

    .atlas {
        position: relative;

        .address-img {
            width: 100%;
        }

        .address-box {
            position: absolute;
            top: 65%;
            margin-left: 150px;
            background: #FFFFFF;
            box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.09);
            border-radius: 16px;
            transform: translate(0, -50%);
            padding: 60px 40px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 36px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 50px;
                padding-bottom: 20px;
            }

            .cont {
                font-size: 18px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #1A1A1A;
                line-height: 25px;
                width: 441px;
            }
        }
    }
}
</style>
