<template>
    <div class="pc_index_hot_tabs">
        <div class='pc_index_ul'>
            <div @click="changeTabs(index)" class="pc_index_li" v-for="(item, index) in list" :key="index">
                <span :class="['tabs', tabs_index == index ? 'pc_index_active' : 'pc_index_name']">{{
                    item.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        tabs_index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            // tabs_index: 0,
            list: [],
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        changeTabs(index) {
            this.$emit('changeTabs', index)
        },
        async getInit() {
            const res = await this.$API.productApi()
            this.list = res.data.data
            this.list.forEach(item => {
                item.title = item.title + '产品'
            })
            console.log(this.list)
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_index_hot_tabs {
    width: 100%;
    height: 100%;

    @include text-center;

    .pc_index_ul {
        width: 100%;
        height: 70px;
        @include text-center;
        justify-content: space-between;

        .pc_index_li {
            height: 100%;
            width: 210px;
            border: 1px solid #CCCCCC;
            @include text-center;

            &:hover {
                cursor: pointer;
            }

            .pc_index_name {
                height: 100%;
                display: flex;

                @include text-center;
                @include font(18, 25, #333333, 400);
            }

            .pc_index_active {
                width: 100%;
                display: block;
                height: 100%;
                background: var(--bg-colors);
                @include text-center;
                @include font(18, 25, #fff, 600);
            }


        }

    }
}
</style>
