const getters = {
  productLg: (state) => state.productLg,
  productSm: (state) => state.productSm,
  menus: (state) => state.menus,
  swiper: (state) => state.swiper,
  info: (state) => state.info,
  public_banner_info: (state) => state.public_banner_info,
  new_list: (state) => state.new_list,
  company: (state) => state.company,
  menus_index: (state) => state.menus_index,
};
export default getters;
