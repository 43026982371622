<template>
    <div class="banner_btm_describe public-padding">
        <div class="left">
            <!-- <div class="left_title">
                {{ showTitle }}
            </div> -->
            <div class="left_describe" v-html="showText">
            </div>
        </div>
        <div class="right">
            <img class="show_lgImg" :src="showLgImg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showText: {
            type: String
        },
        showTitle: {
            type: String
        },
        showLgImg: {
            type: String
        }
    },
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.banner_btm_describe {
    width: 100%;
    display: flex;
    align-items: center;

    .left {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin-right: 67px;

        ::-webkit-scrollbar {
            width: 0 !important;
        }

        ::-webkit-scrollbar {
            width: 0 !important;
            height: 0;
        }

        .left_title {
            @include font(36, 32, #1a1a1a, 600);
            font-family: 'PingFangSC-Semibold, PingFang SC';
            padding-bottom: 48px;
        }

        .left_describe {
            width: 100%;
            height: 288px;
            @include font(18, 36, #383838, 400);
            font-family: 'PingFangSC-Semibold, PingFang SC';

            overflow-y: scroll;

        }
    }

    .right {
        width: 40%;

        .show_lgImg {
            width: 100%;
        }
    }


}

.public-padding {
    padding: 105px 260px;
    box-sizing: border-box;
}
</style>
