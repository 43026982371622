<template>
    <div class="yd-about-new-detail type-arae ">
        <div class="detail-top public-yd-css-margin">
            <div class="top-box ">
                <div class="go-back" @click="goBack">
                    <i class="iconfont icon-xitongfanhui icon-go-back"></i>
                    <span class="go-back-text">返回</span>
                </div>
                <div class="presentation">
                    {{ newsDetail.title }}
                </div>
                <div class="timer">
                    <img class="icon-timer" src="../../../../images/about/icon_time.png" alt="">
                    <div class="date"> {{ timer }}</div>
                </div>
            </div>
        </div>
        <div class="detail-bottom">
            <!-- 富文本 -->
            <div class="yd-public-rich-text" id="ydnew-public-rich-text" v-html="newsDetail.smText">

            </div>


            <!-- 切换章节 -->
            <div class="change-page">
                <!-- 上一章 -->
                <div :class="['previous', lastNoShow ? 'lastNoShow' : '']" @click="handlerLast" :disabled="lastNoShow">
                    <div class="previous-text">上一篇</div>
                    <div class="previous-describe">{{ lastTitle }}</div>
                </div>

                <!-- 下一章 -->
                <div :class="['next', nextNoShow ? 'nextNoShow' : '']" @click="handlerNext" :disabled="nextNoShow">
                    <div class="next-text">下一篇</div>
                    <div class="next-describe">{{ nextTitle }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            nid: '',
            newsDetail: {},

            //上一个现实的标题内容
            lastTitle: "",
            //下一个显示的标题内容
            nextTitle: "",
            //上一个的ID 用于跳转页面
            lastId: "",
            //下一个的ID 用于跳转页面
            nextId: "",
            // 控制按钮是否可以点击
            lastNoShow: false,
            nextNoShow: false,

        }
    },
    mounted() {
        this.nid = this.$route.params.nid
        this.getInit()
    },
    computed: {
        ...mapGetters([
            'new_list'
        ]),
        timer() {
            let publishTime = ''
            if (this.newsDetail.publishTime) {
                publishTime = this.newsDetail.publishTime
            }
            return moment(publishTime).format("YYYY-MM-DD")
        }
    },
    methods: {
        async getInit(nextId) {
            let res = await this.$API.newDetailApi(nextId ? nextId : this.nid)
            this.newsDetail = res.data.data
            if (this.newsDetail.after == null) {
                // 把 下一篇 按钮的内容变成 无      
                this.nextTitle = "无";
                this.nextNoShow = true;
            } else {
                // 改变你下一篇标题显示的内容 为 下个index里显示的标题
                this.nextTitle = this.newsDetail.after.title;
                this.nextId = this.newsDetail.after.nid;
                this.nextNoShow = false;
            }
            //   这个和上面一样
            if (this.newsDetail.front == null) {
                // 把 上一篇 按钮的内容变成 无    
                this.lastTitle = "无";
                this.lastNoShow = true;
            } else {
                this.lastTitle = this.newsDetail.front.title;
                this.lastId = this.newsDetail.front.nid;
                this.lastNoShow = false;
            }
        },

        handlerLast() {
			this.$router.push({
			    name: "news",
			    params: {
			        nid: this.newsDetail.front.nid,
			    },
			
			});
            this.getInit(this.newsDetail.front.nid)
			window.scrollTo(0, 0);
        },
        handlerNext() {
			this.$router.push({
			    name: "news",
			    params: {
			        nid: this.newsDetail.after.nid,
			    },
			
			});
            this.getInit(this.newsDetail.after.nid)
			window.scrollTo(0, 0);
        },
        goBack() {
            let xinwen = localStorage.getItem('xinwen')
            this.$router.push(`/about/${xinwen}`)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.yd-about-new-detail {
    width: 100%;
    padding: 1px;
    box-sizing: border-box;

    .detail-top {
        width: 100%;
        background: url('../../../../images/about/yd_news_details.png') no-repeat;
        min-height: 286px;
        background-size: 100% 100%;
        height: 100%;

        .top-box {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 286px;
            padding: 43px 80px 27px;
            box-sizing: border-box;

            .go-back {
                @include font(24, 26, #fff, 400);
                font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
                // padding-top: 50px;
                // padding-bottom: 40px;

                .icon-go-back {
                    padding-right: 5px;
                }

                .go-back-text {
                    @include font(24, 27, #fff, 400);
                    font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
                }
            }

            .presentation {
                @include font(34, 52, #fff, 600);
                font-family: 'PingFangSC-Semibold, PingFang SC';
                // padding-bottom: 40px;
            }

            .timer {
                display: flex;
                align-items: center;

                .icon-timer {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                .date {
                    @include font(24, 40, #fff, 500);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                }
            }
        }
    }

    .detail-bottom {
        padding: 60px 30px;
        box-sizing: border-box;
        margin-top: 60px;

        // .qrcode {
        //     @include font(12, 18, #6C7483, 400);
        //     font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
        //     text-align: center;
        //     padding: 37px 0 59px;
        //     border-bottom: 1px solid #E8E5E5;

        //     .icon-yd {
        //         .phone {
        //             width: 56px;
        //             height: 56px;
        //         }
        //     }
        // }
        .yd-public-rich-text {
            width: 100%;
            font-size: 28px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            color: #383838;
            line-height: 52px;
        }

        .change-page {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            // margin-bottom: 100px;

            .previous,
            .next {
                margin-top: 20px;
                @include font(24, 33, #666666, 400);
                font-family: 'PingFangSC-Regular, PingFang SC';
                width: 100%;
                // height: 170px;
                background: #F7F7F7;
                padding: 38px 40px;
                box-sizing: border-box;



                .previous-describe,
                .next-describe {
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /* 这里是超出几行省略 */
                    overflow: hidden;
                    margin-top: 14px;
                    @include font(28, 46, #1a1a1a, 500);
                    font-family: 'PingFangSC-Medium, PingFang SC';
                }

            }

            .lastNoShow,
            .nextNoShow {
                &:hover {
                    background: #F7F7F7 !important;
                    cursor: not-allowed;

                    .previous-text,
                    .next-text {
                        @include font(24, 33, #666666, 400);
                    }

                    .previous-describe,
                    .next-describe {
                        margin-top: 14px;
                        @include font(28, 46, #1a1a1a, 500);
                        font-family: 'PingFangSC-Medium, PingFang SC';
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
	#ydnew-public-rich-text {
		img {
			max-width: 100%;
		}
	}
</style>
