<template>
    <div class="pc-jion-describe">
        <div class="header-goback public-yd-css-margin" @click="$router.go(-1)">
            <i class="iconfont icon-xitongfanhui icon-back"></i>
            <span class="text">返回职位列表</span>
        </div>
        <div class="content-box">
            <div class="name">{{ jionList.title }}</div>
            <div class="address">
                <img class="address-img" src="@/images/icon_position.png" alt="">
                <span class="address-text">{{ jionList.site }}</span>
            </div>
            <div class="position-describe">
                <div class="title">职位描述</div>
                <div class="fwb" style="white-space: pre-wrap;" v-html="jionList.text"></div>
                <div class="email" :href="jionList.email">简历请投递至邮箱：{{ jionList.email }}</div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            jid: '',
            jionList: {}
        }
    },
    created() {
        this.jid = this.$route.params.jid
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.jionDetailApi(this.jid)
            if (res.data.meta.code == 200) {
                this.jionList = res.data.data
            }
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc-jion-describe {
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 1px;

    .header-goback {
        width: 100%;
        height: 100px;
        background: var(--bg-colors);
        display: flex;
        align-items: center;
        color: #FFFFFF;
        padding: 0 40px;
        box-sizing: border-box;
        margin-bottom: 80px;

        &:hover {
            cursor: pointer;
        }



        .text {
            font-size: 24px;
            font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
            padding-left: 10px
        }
    }

    .content-box {
        width: 100%;
        background: #fff;
        padding: 0 40px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
        font-weight: 400;
        color: #666666;
        line-height: 21px;

        .name {
            font-size: 36px;
            font-family: 'PingFangSC-Semibold, PingFang SC';
            font-weight: 600;
            color: #1A1A1A;
            line-height: 50px;
            padding-bottom: 10px;
        }

        .address {
            display: flex;
            align-items: center;
            padding-bottom: 40px;

            .address-text {
                font-size: 20px;
                font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
                font-weight: 400;
                color: #666666;
                line-height: 30px;
                padding-left: 4px;

            }
        }

        .position-describe {

            .title {
                font-size: 28px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 30px;

                &::after {
                    content: '';
                    display: flex;
                    width: 73%;
                    background: #E6E6E6;
                    height: 1px;
                    padding-left: 40px;
                }
            }

            .fwb {
                padding-bottom: 42px;
                font-size: 24px;
                font-family: 'SourceHanSansCN-Normal, SourceHanSansCN';
                font-weight: 400;
                color: #333333;
                line-height: 40px;
            }

            .email {
                color: var(--bg-colors);

                font-size: 20px;
                font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
                font-weight: 600;
                line-height: 30px;
                padding-bottom: 80px;
            }
        }
    }
}

.public-padding {
    padding: 0 360px;
    box-sizing: border-box;
}
</style>
