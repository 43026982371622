<template>
    <div class="pc_touzi_info public-padding-left-right">
        <div class="title" id="company">
            公司信息
        </div>
        <div class="box">
            <div class="left_img">
                <img class="show_lgImg" :src="showLgImg" alt="">
            </div>
            <div class="right_company">
                <div class="company_li" v-for="(item, index) in company_info" :key="index">
                    <div class="li_box">
                        <div class="company_title">
                            <img class="icon" :src="item.img" alt="">
                            <span class="text">{{ item.title }}</span>
                        </div>
                        <div class="company_name">
                            <span>{{ item.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: '',
    components: {},
    data() {
        return {
            company_info: [],

        }
    },
    props: {
        showLgImg: {
            type: String
        }
    },
    computed: {
        ...mapGetters(
            [
                "company",
                "info",
                "company"]
        ),
    },
    mounted() {
        this.getInit()
    },
    methods: {
        getInit() {
            let arr = this.company.gupiao.split('|')
            let title = ['上市时间', '证券类别', '联系地址']
            let img = [
                require('../../../../images/touzi/icon_message_1.png'),
                require('../../../../images/touzi/icon_message_2.png'),
                require('../../../../images/touzi/icon_message_3.png')
            ]

            let company1 = []

            let company2 = [
                {
                    img: require('../../../../images/touzi/icon_message_4.png'),
                    title: '投资者专线',
                    content: this.info.touziPhone
                },
                {
                    img: require('../../../../images/touzi/icon_message_5.png'),
                    title: '联系传真',
                    content: this.info.fax
                },
                {
                    img: require('../../../../images/touzi/icon_message_6.png'),
                    title: '联系邮箱',
                    content: this.info.touziEmail
                },
            ]
            arr.forEach((item, index) => {
                company1.push({
                    img: img[index],
                    title: title[index],
                    content: item
                })
            });

            this.company_info = [

                ...company1, ...company2,
            ]
            console.log(this.company_info)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_touzi_info {
    width: 100%;

    .title {
        @include font(28, 40, #1A1A1A, 600);
        font-family: 'PingFangSC-Semibold, PingFang SC';
        padding: 80px 0 62px;
        text-align: center;
    }

    .box {
        display: flex;
        background: url('../../../../images/touzi/bg_message.png') no-repeat;
        background-size: 100% 100%;
        width: 100%;

        .left_img {
            width: 540px;

            // transform: translate(-42px, -28px);

            .show_lgImg {
                width: 540px;
            }
        }

        .right_company {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 75px 0;
            box-sizing: border-box;

            .company_li {
                width: 33.3%;
                height: 50%;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                position: relative;

                .li_box {
                    width: 157px;
                    margin-top: 30px;
                }

                &:nth-child(1) {
                    &::after {
                        display: inline;
                        background: transparent;
                    }
                }

                &:nth-child(4) {
                    &::after {
                        display: inline;
                        background: transparent;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    // right: 0;
                    top: -60px;
                    left: 0;
                    display: inline-block;
                    width: 1px;
                    height: 250px;
                    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #E3E3E3 49%, rgba(216, 216, 216, 0) 100%);
                }



                .company_title {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .icon {
                        @include height-width(22, 22);
                        margin-right: 6px;
                        position: absolute;
                        left: -28px;
                    }

                    .text {
                        @include font(18, 22, #333, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';

                    }
                }

                .company_name {
                    padding-top: 12px;
                    @include font(16, 20, #999, 400);
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                }
            }
        }
    }

}

.public-padding-left-right {
    padding: 0 260px;
    box-sizing: border-box;
}
</style>
