import api from "./requestUrl";

// 公共-头部
export const PageHeaderApi = (mkey) =>
  api({
    url: `/web/${mkey}`,
    method: "GET",
  });
// 公共-列表
export const listApi = (mkey) =>
  api({
    url: `/web/list/${mkey}`,
    method: "GET",
  });

/* 企业简介 */
export const companyApi = (mkey) =>
  api({
    url: `/site/company`,
    method: "GET",
  });
/* 菜单 */
export const indexMenusApi = () =>
  api({
    url: "/site/menu",
    method: "GET",
  });
/* 热点产品 */
export const indexProductApi = () =>
  api({
    url: "/product",
    method: "GET",
  });
/* 基础信息 */
export const indexInfoApi = () =>
  api({
    url: "/site/info",
    method: "GET",
  });
/* 轮播图 */
export const indexSwiperApi = () =>
  api({
    url: "/site/swiper",
    method: "GET",
  });
/* 合作伙伴 */
export const indexPartnerApi = () =>
  api({
    url: "/site/customer",
    method: "GET",
  });

/* 新闻 */
export const newApi = (params) =>
  api({
    url: `/news`,
    method: "GET",
    params,
  });
/* 新闻详情 */
export const newDetailApi = (key) =>
  api({
    url: `/news/${key}`,
    method: "GET",
  });
/* 产品中心 */
export const productApi = (params) =>
  api({
    url: `/product`,
    method: "GET",
    params,
  });
/* 投资者关系——股票信息 */
export const stockApi = (params) =>
  api({
    url: `/site/stock`,
    method: "GET",
    params,
  });
/* 信息披露 */
export const reportApi = (params) =>
  api({
    url: `/report`,
    method: "GET",
    params,
  });
/* 投资交流 */
export const touzjiaolApi = () =>
api({
  url: `/web/list/touzi_jiaoliu`,
  method: "GET",
});
/* 首页轮播图产品 */
export const homeProductApi = () =>
api({
  url: `/product/home`,
  method: "GET",
});
/* 招聘 */
export const jionlApi = (params) =>
  api({
    url: `/job`,
    method: "GET",
    params,
  });
export const jionDetailApi = (jib) =>
  api({
    url: `/job/${jib}`,
    method: "GET",
  });
/* 法律声明 */
export const legalApi = (params) =>
  api({
    url: `/site/legal`,
    method: "GET",
    params,
  });
/* 隐私保护 */
export const privacyApi = (params) =>
  api({
    url: `/site/privacy`,
    method: "GET",
    params,
  });
/* 地区 */
export const sitesApi = (params) =>
  api({
    url: `/job/sites`,
    method: "GET",
    params,
  });

/* 只能创造板块 */
export const siteBannerApi = (params) =>
  api({
    url: `/site/banner`,
    method: "GET",
    params,
  });
/* 判断网站是否变灰 */
export const grayApi = (params) =>
  api({
    url: `/site/page/gray`,
    method: "GET",
    params,
  });
/* 校园招聘页面数据 */
export const jobApi = (params) =>
  api({
    url: `/job`,
    method: "GET",
    params,
  });
