<template>
    <div class="pc_list">
        <div class="list_ul">
            <div class="list_li public-padding-left-right" v-for="(item, index) in list" :key="index">
                <div class="lt" v-if="index % 2 == 0">
                    <div class="left">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.lgText }}
                        </div>
                    </div>
                    <div class="right">
                        <img class="lg-img" :src="item.lgImg" alt="">
                    </div>
                </div>
                <div class="lt" v-else>
                    <div class="right">
                        <img class="lg-img" :src="item.lgImg" alt="">
                    </div>
                    <div class="left">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="describe">
                            {{ item.lgText }}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        },
        paddingTopBottom: {
            type: String,
            default: '80px'
        },
        paddingLeft: {
            type: String,
            default: '80px'
        },
        paddingRight: {
            type: String,
            default: '80px'
        },
        bg_color: {
            type: String,
            default: '#f5f5f5'
        }
    },
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_list {
    width: 100%;

    .public-padding-left-right {
        padding: 0 260px;
        box-sizing: border-box;
    }

    .list_ul {
        width: 100%;

        .list_li {
            display: flex;
            align-items: center;

            &:nth-child(even) {
                .left {
                    padding-top: v-bind(paddingTopBottom);
                    padding-left: 80px;
                    padding-right: 80px;
                    box-sizing: border-box;
                    position: relative;

                    &::after {
                        content: '';
                        display: flex;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        left: -48px;
                        width: 0px;
                        height: 0px;
                        border-left: 25px solid transparent;
                        border-right: 25px solid #F5F5F5;
                        border-bottom: 19px solid transparent;
                        border-top: 19px solid transparent;
                        /* 根据需要修改颜色 */
                    }

                }
            }

            &:nth-child(odd) {
                .left {
                    padding-top: 80px;
                    padding-left: 80px;
                    padding-right: 80px;
                    box-sizing: border-box;
                    position: relative;

                    &::after {
                        content: '';
                        display: flex;
                        top: 50%;
                        transform: translate(0, -50%);
                        position: absolute;
                        right: -48px;
                        width: 0px;
                        height: 0px;
                        border-left: 25px solid #F5F5F5;
                        border-right: 25px solid transparent;
                        border-bottom: 19px solid transparent;
                        border-top: 19px solid transparent;
                        /* 根据需要修改颜色 */
                    }
                }
            }

            .lt,
            .rt {
                width: 100%;
                height: 398px;
                display: flex;
                background-color: v-bind(bg_color);

                .left {
                    min-height: 398px;
                    width: 50%;
                    box-sizing: border-box;


                    .title {
                        @include font(32, 45, #1A1A1A, 600);
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        padding-bottom: 30px;
                    }

                    .describe {
                        height: 50%;
                        overflow-y: scroll;

                        @include font(18, 32, #383838, 400);
                        font-family: 'PingFangSC-Regular, PingFang SC';

                        &::-webkit-scrollbar {
                            display: none;
                            /* Chrome、Safari和Opera浏览器 */
                        }
                    }
                }

                .right {
                    width: 50%;

                    .lg-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                    }

                }
            }
        }
    }
}
</style>
