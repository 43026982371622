<template>
    <div class="relation type-arae">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>
        <div class="atlas">
            <img class="address-img" :src="public_banner_info.showLgImg" alt="">
            <div class="address-box">
                <div class="title">办公地址</div>
                <div class="cont">{{ company.address }}</div>
            </div>
        </div>
        <div class="abstract">
            <div class="company-name">海联金汇科技股份有限公司</div>
            <div class="company-address">
                <img src="@/images/relation/icon_place.png" alt="">
                <div class="address">{{ company.address }}</div>
            </div>
            <div class="company-phone">
                <img src="@/images/relation/icon_telephone.png" alt="">
                <div>{{ info.phone }}</div>
            </div>
            <div class="company-email">
                <img src="@/images/relation/icon_mail.png" alt="">
                <div>{{ info.email }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import banner from '@/views/pc/components/banner.vue'
import { mapGetters } from 'vuex'
export default {
    name: '',
    components: { banner },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('getPageHeader', 'lianxi')
    },
    computed: {
        ...mapGetters([
            'info',
            'public_banner_info',
            'company'
        ]),

    },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.relation {
    width: 100%;
    height: 100%;

    @include font(18, 25, #1a1a1a, 400);

    .abstract {
        padding: 124px 230px;
        box-sizing: border-box;
        background: url('../../../../images/relation/bg.png') no-repeat;
        background-size: 100% 100%;
        display: flex;

        @media screen and(max-width: 1213px) {
            padding: 124px 210px;
        }

        @media screen and(max-width: 1124px) {
            padding: 124px 190px;
        }

        @media screen and(max-width: 1044px) {
            padding: 124px 170px;
        }

        @media screen and(max-width: 967px) {
            padding: 124px 150px;
        }

        @media screen and(max-width: 907px) {
            padding: 124px 130px;
        }

        .company-name {
            padding-right: 51px;
            @include font(36, 50, #1a1a1a, 600);
            width: 432px;
            white-space: nowrap;

            @media screen and(max-width: 1158px) {
                padding-right: 41px;
            }
        }

        .company-address {
            padding-left: 51px;
            border-left: 1px solid #D8D8D8;
            @include text-center;

            @media screen and(max-width: 1158px) {
                padding-left: 41px;
            }

            img {
                padding-right: 5px;
            }

            .address {
                width: 330px;
                font-size: 18px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #1A1A1A;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                // white-space: nowrap;

                @media screen and(max-width: 1290px) {
                    width: 360px;
                }

                @media screen and(max-width: 1185px) {
                    width: 380px;
                }

                @media screen and(max-width: 1120px) {
                    width: 400px;
                }

                @media screen and(max-width: 1065px) {}
            }
        }

        .company-phone {
            padding: 0 60px;
            @include text-center;

            @media screen and(max-width: 1290px) {
                padding: 0 40px;
            }

            img {
                padding-right: 5px;
            }
        }

        .company-email {
            @include text-center;

            img {
                padding-right: 5px;
            }
        }
    }

    .atlas {
        position: relative;

        .address-img {
            width: 100%;
        }

        .address-box {
            position: absolute;
            top: 50%;
            left: 43%;
            background: #fff;
            box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.09);
            border-radius: 16px;
            transform: translate(0, -50%);
            padding: 42px 50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 36px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 50px;
                padding-bottom: 20px;
            }

            .cont {
                font-size: 18px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #1A1A1A;
                line-height: 25px;
                width: 331px;
            }
        }
    }
}
</style>
