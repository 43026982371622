var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc_about_buju"},[_c('banner',{attrs:{"images":_vm.public_banner_info.smImg,"title":_vm.public_banner_info.title}}),_c('div',{staticClass:"pc_content"},[_c('div',{staticClass:"public_margin_top_bottom_font"},[_vm._v(" "+_vm._s(_vm.public_banner_info.showTitle)+" ")]),(false)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.public_banner_info.showText)+" ")]):_vm._e(),_c('div',{staticClass:"atlas_img"},[_c('img',{attrs:{"src":_vm.public_banner_info.showSmImg,"alt":""}}),(false)?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox1"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox2"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox3"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox4"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox5"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circleBox6"},[_c('div',{staticClass:"circle"}),_c('div',{staticClass:"circle2"}),_c('div',{staticClass:"circle3"})])
}]

export { render, staticRenderFns }