<template>
    <div class="pc_rd_jujiao">
        <div class="public-title">四大智能驾驶辅助系统</div>
        <div class="box public-padding-left-right">
            <div class="top_ul">
                <div :class="['top_li', active_index == index ? 'active_top_li' : '']" @mouseenter="active_index = index"
                    v-for="(item, index) in list" :key="index">
                    {{ item.title }}
                </div>
            </div>
            <template v-for="(item, index) in list">
                <div class="bottom_li" v-if="active_index == index" :key="index">
                    <div class="left">
                        <div class="left_title">{{ item.title }}</div>
                        <div class="left_text" style="white-space: pre-wrap;" v-html="item.lgText"></div>
                    </div>
                    <div class="right">
                        <img class="lg_img" :src="item.lgImg" alt="">
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            list: [],
            active_index: [],
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_jujiao')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_jujiao {
    width: 100%;
    background: #F7F7F7;
    padding-bottom: 100px;

    .public-padding-left-right {
        padding: 0 260px;
        box-sizing: border-box;
    }

    .public-title {
        text-align: center;
        @include font(36, 32, #1a1a1a, 600);
        padding: 120px 0 60px 0;
    }

    .box {
        .top_ul {
            @include justify(space-between);

            .top_li {
                padding: 21px 116px;
                box-sizing: border-box;
                background: #fff;
                @include font(16, 22, #151515, 400);
                font-family: 'PingFangSC-Medium, PingFang SC';

                @media screen and(max-width: 1389px) {
                    padding: 21px 100px;
                }

                @media screen and(max-width: 1120px) {
                    padding: 21px 90px;
                }

                @media screen and(max-width: 995px) {
                    padding: 21px 80px;
                }

                @media screen and(max-width: 902px) {
                    padding: 21px 70px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .active_top_li {
                background: var(--bg-colors);
                @include font(16, 22, #fff, 400);
                position: relative;

                &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 50%;
                    width: 57px;
                    height: 33px;
                    transform: translate(-50%, 50%);

                    border: 28px solid #000;
                    border-top-color: var(--bg-colors);
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    box-sizing: border-box;
                }
            }
        }

        .bottom_li {
            background: #fff;
            margin-top: 30px;
            display: flex;
            align-items: center;
            padding: 70px 80px;
            box-sizing: border-box;

            .left {
                @include font(16, 32, #444452, 400);
                font-family: 'AlibabaPuHuiTiR';
                width: 690px;
                margin-right: 100px;

                .left_title {
                    @include font(28, 52, #151515, 600);
                    padding-bottom: 20px;
                }


            }

            .right {
                width: 450px;

                .lg_img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
