<template>
    <div class="yd_index_header">
        <div class="header_left">
            <a href="/" class="header_left_a">
                <img class="img1" :src="$store.state.info.logo" alt="">
            </a>
            <div class="describe">科技联动美好未来</div>
        </div>
        <div class="header_right">
            <!-- <div class="chinese">
                中文
            </div> -->
            <div class="right-icon">
                <span class="iconfont icon-liebiao2 right-icon" @click="openShade"></span>
            </div>
        </div>

        <div v-if="shade_show" class="shade_box">
            <div class="header">
                <div class="header_left">
                    <a href="/" class="header_left_a">
                        <img class="img1" :src="$store.state.info.logo" alt="">
                    </a>
                    <div class="describe">科技联动美好未来</div>
                </div>
                <img @click="$emit('delShade', false)" class="icon_close" src="../../../../images/yd_img/icon_close.png"
                    alt="">
            </div>
            <div class="content">
                <van-collapse v-model="parent_activeName" accordion>
					<a class="stair" href="/">
					    首页
					</a>
                    <template v-for="(item, index) in menus">
                        <a class="stair" v-if="item.menus.length == 0 || item.mkey == 'product'" :href="item.url">
                            {{ item.name }}
                        </a>
                        <van-collapse-item v-if="item.show == 1 && item.menus.length > 0 && item.mkey !== 'product'"
                            :title="item.name" :key="index" :name="index">
                            <template v-for="(it, i) in item.menus">
                                <a :href="it.url" @click="goRouter(it)" class="it_name" v-if="it.show == 1" :title="it.name"
                                    :key="i" :name="i">
                                    {{ it.name }}
                                </a>
                            </template>
                        </van-collapse-item>
                    </template>
                </van-collapse>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    props: {
        shade_show: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            parent_activeName: 0,
        }
    },
    computed: {
        ...mapGetters([
            'menus'
        ]),
    },
    mounted() {
    },

    methods: {
        goRouter(it) {
            console.log(it)
            if (it.mkey == 'hezuo' || it.mkey == 'zhaobiao') {
                window.location.href = it.url
                return false
            }
            // this.$router.push(it.url)
            this.$emit('delShade', false)
        },
        openShade() {
            this.$emit('openShade', true)
        },

    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";



.yd_index_header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;

    .header_left {

        height: 50px;
        display: flex;
        align-items: center;

        .header_left_a {
            // width: 100%;
            width: 200px;
            height: 50px;
            padding-right: 14px;

            .img1 {
                height: 100%;
            }
        }

        .describe {
            font-size: 28px;
            font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
            font-weight: 400;
            color: #666666;
            line-height: 36px;
            padding-left: 14px;
            border-left: 1px solid #D8D8D8;
        }
    }

    .header_right {
        // height: 50px;
        display: flex;
        align-items: center;

        .chinese {
            padding: 2px 16px;
            box-sizing: border-box;
            border: 1px solid #111;
            @include font(24, 33, #1A1A1A, 400);
            margin-right: 50px;
        }

        .right-icon {
            width: 44px;
            height: 36px;

            span {
                font-size: 40px;
                font-weight: 600;
            }
        }
    }

    .shade_box {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0;
        background: #FFFFFF;

        .header {
            width: 100%;
            height: 100px;
            background: #FFFFFF;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-bottom: 1px solid #ddd;

            .logo {
                width: 200px;
                height: 50px;

                .img1 {
                    width: 100%;
                }
            }

            .icon_close {
                width: 36px;
                height: 36px;
            }
        }

        .content {
            height: 100vh;
            background: #fff;

            .stair {
                display: block;
                padding: 40px;
                font-size: 34px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #333333;
                line-height: 24px;
            }

            /deep/ .van-collapse-item {
                .van-cell {
                    padding: 40px;
                    font-size: 34px;
                    font-family: 'PingFangSC-Regular, PingFang SC';
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                }

                .van-collapse-item__wrapper {
                    .it_name {
                        display: block;
                        padding: 30px 45px;
                        font-size: 30px;
                        font-family: 'PingFangSC-Regular, PingFang SC';
                        font-weight: 400;
                        color: #999999;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
</style>
