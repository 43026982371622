<template>
    <div class="banner public-yd-css-margin">
        <div class="banner_box">
            <img class="banner_img" :src="images" alt="">
            <div class="title">{{ title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: String
        },
        title: {
            type: String
        }
    },

    data() {
        return {
        }
    },

    created() { },
    mounted() { },
    methods: {}
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.banner {
    width: 100%;

    .banner_box {
        width: 100%;
        position: relative;

        .banner_img {
            width: 100%;
            height: 100%;
            // min-height: 340px;
            vertical-align: middle;
        }

        .title {
            position: absolute;
            left: 80px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 34px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            line-height: 52px;
        }
    }

}
</style>
