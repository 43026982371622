<template>
    <div class="about_new_search">
        <div class="title">找到约{{ new_list.total }}条记录</div>
        <div class="search_ul">
            <div class="search_li" v-for="(item, index) in new_list.data" @click="toDetail(item, index)" :key="item.nid">
                <div class="left_text">
                    {{ item.lgText }}
                </div>
                <div class="right_icon iconfont icon-youjiantou"></div>
            </div>
        </div>

        <div class="pag_ing">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="new_list.pageNum" :page-size="new_list.pageSize" layout="  prev, pager, next, jumper"
                :total="new_list.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        search_val: {
            type: String,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(["new_list"]),
    },
    mounted() {
    },
    methods: {
        handleCurrentChange(val) {
            this.new_list.pageNum = val;
            this.$store.dispatch('getNew', { search: this.search_val, pageNum: this.new_list.pageNum, pageSize: this.new_list.pageSize })
        },
        handleSizeChange(val) {
            this.new_list.pageSize = val;
            this.$store.dispatch('getNew', { search: this.search_val, pageNum: this.new_list.pageNum, pageSize: this.new_list.pageSize })
        },
        toDetail(row, id) {
            this.$router.push({
                name: "news",
                params: {
                    nid: row.nid,
                },
                // query: {
                //     id: id,
                // },
            });
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_new_search {
    width: 100%;
    padding: 40px 260px;
    box-sizing: border-box;

    .title {
        @include font(18, 33, #181B20, 600);
    }

    .search_ul {
        width: 100%;

        .search_li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font(16, 33, #6C7483, 400);
            padding: 15px 0;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                background: #bbb;
                bottom: 0;
                width: 100%;
                height: 1px;
            }

            &:hover {
                cursor: pointer;
            }

            .left_text {
                width: 98%;
                /* 文字超出一行省略号 */
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .right_icon {
                width: 2%;
            }
        }
    }

    .pag_ing {

        /deep/ .el-pagination {

            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-prev,
            .btn-next {
                padding: 5px !important;
                border: 1px solid #CBCFD3;
            }

            .el-pager {
                .number {
                    border: 1px solid #CBCFD3;
                    margin: 0 2px;
                }

                .active {
                    background: var(--bg-colors);
                    color: #fff;
                }
            }
        }
    }
}
</style>
