<template>
    <div class="pc_jion_page type-arae">
        <banner :images="public_banner_info.lgImg" :title="public_banner_info.title"></banner>

        <div class="pc-jion-page-content" v-if="key == 'shehui'">
            <pcJionPageContent></pcJionPageContent>
        </div>

        <div v-else class="public-padding-left-right">
					<!-- 校园招聘title -->
					<div class="school-jion-title">
						<span></span>
						<span>招聘岗位</span>
						<span></span>
					</div>
					<!-- 折叠内容 -->
					<div class="school-jion-content">
						<el-collapse accordion>
						  <el-collapse-item v-for="item in school" :key="item.jid">
						    <template slot="title">
						      <span>{{item.title}}</span>
						    </template>
								<div v-html="item.text"></div>
						  </el-collapse-item>
						</el-collapse>
					</div>
					<!-- 简历投递二维码 -->
					<div class="erweima_content">
						<p>
							<span class="title">简历投递二维码</span>
						</p>
						<p>
							<img class="erweimaimg" :src="info.hrQRCode" alt="" />
						</p>
					</div>
					<div class="school-jion-title" style="margin: 0.3rem 0;">
						<span></span>
						<span>联系我们</span>
						<span></span>
					</div>
					<!-- 联系电话 -->
					<div class="contact_information">
						<p><img src="../../../../images/icon_ph.png" alt="" /> 联系电话: {{info.hrPhone}}</p>
						<p><img src="../../../../images/icon_em.png" alt="" /> HR邮箱 : {{info.hrEmail}}</p>
					</div>
            <!-- <div class="fwb" v-for="item in school" :key="item.lid" v-html="item.lgText">
            </div> -->
        </div>
    </div>
</template>

<script>
import banner from '@/views/pc/components/banner.vue'
import pcJionPageContent from '@/views/pc/components/jion/pc_jion_page-content.vue'
import { mapGetters } from "vuex";
export default {
    name: '',

    components: { banner, pcJionPageContent },
    data() {
        return {
            key: '',
            school: [],
        }
    },
    computed: {
        ...mapGetters(["public_banner_info","info"]),
    },
    mounted() {
        this.getInit()
				this.getJob()
        // this.getSchool()
    },
    methods: {
        async getInit() {
            this.key = this.$route.params.key
            this.$store.dispatch('getPageHeader', this.key)
        },
        async getSchool() {
            if (this.key == 'xiaoyuan') {
                this.$API.listApi('jion_xiaoyuan ').then(res => {
                    if (res.data.meta.code == 200) {
                        this.school = res.data.data
                    }
                })
            }
        },
				// 获取信息
				getJob() {
					this.$API.jobApi({
						site: '',
						jobType: '2',
						pageNum: 1,
						pageSize: 99
					}).then(res => {
						console.log('校园招聘', res);
						if (res.data.meta.code == 200) {
						    this.school = res.data.data.data
						}
					})
				}
    }
}
</script>
<style lang="scss" scoped >
.pc_jion_page {
    width: 100%;
    .public-padding-left-right {
        padding: 40px 370px 37px ;
        box-sizing: border-box;
				// font-size: 20px;
				.school-jion-title {
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						font-size: 28px;
						font-family: 'PingFangSC-Semibold, PingFang SC';
						font-weight: 600;
						color: var(--bg-colors);
						line-height: 28px;
						&:first-child,
						&:last-child {
							display: inline-block;
							width: 100px;
							height: 2px;
							background-color: #ccc;
						}
						&:nth-child(2) {
							margin: 0 20px;
						}
					}
				}
				.school-jion-content {
					margin-top: 40px;
					.el-collapse {
						border-top: 0;
						border-bottom: 0;
						.el-collapse-item {
							margin-bottom: 34px;
							::v-deep .el-collapse-item__header,
							::v-deep .el-collapse-item__wrap {
								border-bottom: 0 !important;
							}
							::v-deep .el-collapse-item__header{
								background-color: #d5d3d3;
								padding: 0 15px;
								font-weight: 700;
								color: #333;
								font-size: 22px;
								span {
									display: flex;
									align-items: center;
									&::before {
										content: '';
										display: inline-block;
										width: 8px;
										height: 23px;
										background-color: var(--bg-colors);
										margin-right: 10px;
									}
								}
							}
							::v-deep .el-collapse-item__content{
								padding: 25px 45px;
								background-color: #f7f7f7;
							}
						}
					}
				}
				.erweima_content {
					text-align: center;
					margin-top: 40px;
					font-size: 24px;
					.title {
						padding: 10px 20px;
						background-color: var(--bg-colors);
						color: #fff;
						border-radius: 20px;
					}
					.erweimaimg {
						width: 100px;
						margin-top: 10px;
					}
				}
				.contact_information {
					font-size: 22px;
					p {
						display: flex;
						align-items: center;
						img {
							width: 36px;
							margin-right: 10px;
						}
						width: 420px;
						text-align: left;
						margin: 0 auto;
						&:last-child {
							margin-top: 0.18rem;
						}
					}
				}
        .fwb {
            font-size: 20px;
            // display: flex;
            // justify-content: center;
        }
    }
}
</style>
