<template>
    <div class="about_new_no-search">
        <!-- <div class="tabs">
            <div class="tabs_title" v-for="(item, index) in tab_list" :key="index" @click="changeTabs(item, index)"
                :class="{ active: active == index }">
                {{ item.title }}
            </div>
        </div> -->
        <div class="tabs_content">
            <div class="content_ul">
                <div class="content_li" @click="toDetail(item, index)" v-for="(item, index) in new_list.data"
                    :key="item.nid">

                    <div class="top_img">
                        <img class="img_news" :src="item.smImg" alt="" />
                        <div class="zd" v-if="item.isTop == 1">
                            置顶
                        </div>
                    </div>
                    <div class="timer_or_content">
                        <div class="timer_or_num">
                            <div class="center_timer">
                                <div class="day">{{ item.day }}</div>
                                <div class="year_month">{{ item.year_month }}</div>
                            </div>

                            <div class="browse_num">
                                <i class="iconfont icon-zhengyan icon-eye"></i>
                                <span class="num">{{ item.visit }}</span>
                            </div>
                        </div>

                        <div class="bottom_box">
                            <div class="left">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="describe">
                                    {{ item.subTitle }}
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="pag_ing">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-size="pageSize" :pager-count='5' layout="prev,pager,next,jumper" :total="total">
                </el-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            active: 0,
            // newsType: 1,
            // tab_list: [
            //     {
            //         title: "新闻中心",
            //         newsType: 1,
            //     },
            //     {
            //         title: "员工风采",
            //         newsType: 2,
            //     },
            // ],
            pageNum: 1,
            pageSize: 9,
            total: 0,
            //上一个现实的标题内容
            lastTitle: "",
            //下一个显示的标题内容
            nextTitle: "",
            //上一个的ID 用于跳转页面
            lastId: "",
            //下一个的ID 用于跳转页面
            nextId: "",
            // 控制按钮是否可以点击
            lastNoShow: "",
            nextNoShow: "",
        };
    },

    mounted() {
        this.getNewList()
    },
    computed: {
        ...mapGetters(["new_list"]),
    },
    methods: {
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getNewList()
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getNewList()
        },
        toDetail(row, id) {
            this.$router.push({
                name: "news",
                params: {
                    nid: row.nid,
                },

            });
        },
        getNewList() {
            let params = { pageNum: this.pageNum, pageSize: this.pageSize }
            this.$store.dispatch('getNew', params).then(res => {

                this.total = res.total;
            })
        },


        changeTabs(item, index) {
            this.pageNum = 1
            // this.newsType = item.newsType
            this.active = index;
            this.getNewList()
        },
    },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.about_new_no-search {
    width: 100%;

    .tabs {
        width: 100%;
        height: 100px;
        background: #fff;
        @include font(32, 18, #181b20, 400);
        @include text-center;
        font-family: "SourceHanSansCN-Bold, SourceHanSansCN";

        .tabs_title {
            // margin-right: 100px;
            width: 50%;
            height: 100%;
            @include text-center;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            @include font(32, 18, var(--bg-colors), 600);
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: 0;
                width: 30px;
                height: 3px;
                border-radius: 2px;
                background: var(--bg-colors);
            }
        }
    }

    .tabs_content {
        width: 100%;
        padding: 40px 30px;
        background: #fafafa;
        box-sizing: border-box;

        .content_ul {
            width: 100%;

            .content_li {
                display: flex;
                flex-direction: column;
                @include font(16, 30, #6c7483, 400);
                font-family: "PingFangSC-Regular, PingFang SC";
                width: 100%;
                margin-bottom: 30px;
                background: #fff;
                position: relative;


                &:last-child {
                    margin-bottom: 0;
                }

                .top_img {
                    width: 100%;
                    height: 426px;
                    position: relative;

                    .img_news {
                        width: 100%;
                        height: 100%;
                    }

                    .zd {
                        display: flex;
                        align-items: center;
                        padding-left: 25px;
                        box-sizing: border-box;
                        color: #fff;
                        position: absolute;
                        background: var(--bg-colors);
                        width: 100px;
                        padding: 5px 0 5px 25px;
                        bottom: -40px;
                        left: 70%;

                        &::after {
                            position: absolute;
                            right: -10px;
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                            transform: rotate(45deg);
                            // border: 1px solid red;
                        }

                        &::before {
                            content: '';
                        }
                    }
                }

                .timer_or_content {
                    display: flex;
                    align-items: flex-start;
                    padding-top: 50px;

                    .timer_or_num {
                        width: 25%;
                        display: flex;
                        flex-direction: column;
                        flex-shrink: 0;

                        .center_timer {
                            margin: 0 0 20px 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            border: 1px solid #E6EBF2;
                            text-align: center;
                            padding: 10px;
                            box-sizing: border-box;


                            .day {
                                @include font(44, 66, #62657a, 400);
                                font-family: "SourceHanSansCN-Bold, SourceHanSansCN";
                            }

                            .year_month {
                                @include font(14, 21, #62657a, 500);
                                font-family: "SourceHanSansCN-Medium, SourceHanSansCN";
                            }
                        }

                        .browse_num {

                            margin: 0 0 20px 40px;

                            .icon-eye {
                                margin-right: 8px;
                                font-size: 20px;
                            }

                            .num {
                                @include font(20, 30, #6c7483, 400);
                                font-family: "PingFangSC-Semibold, PingFang SC";
                            }
                        }
                    }


                    .bottom_box {
                        width: 75%;
                        background: #fff;
                        padding: 0 50px 30px 40px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;



                        .title {
                            width: 100%;
                            @include font(30, 42, #181b20, 600);
                            font-family: "PingFangSC-Semibold, PingFang SC";
                            margin-bottom: 16px;
                            /* 文字超出一行省略号 */
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .describe {
                            width: 100%;
                            @include font(28, 40, #6c7483, 400);
                            font-family: "PingFangSC-Semibold, PingFang SC";
                            margin-bottom: 27px;
                            min-height: 60px;
                            /* 文字超出两行省略号 */
                            word-break: break-all;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }

                    }


                }

            }
        }

        .pag_ing {
            display: flex;
            align-items: center;
            padding: 0 0 30px;
            box-sizing: border-box;


            /deep/ .el-pagination {
                width: 100%;
                height: 62px !important;
                margin-top: 60px;
                display: flex;

                .btn-prev,
                .btn-next {
                    width: 62px;
                    padding: 5px !important;
                    border: 1px solid #cbcfd3;
                    height: 100%;
                    box-sizing: border-box;
                }

                .el-pagination__jump {
                    height: 62px;
                    font-size: 24px;
                    font-family: AlibabaPuHuiTiR;
                    color: #171721;
                    line-height: 62px;

                    word-break: break-all;

                    .el-input {
                        width: 76px;
                        height: 62px;

                        .el-input__inner {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            opacity: 0.6;
                            border: 1px solid #CBCFD3;
                            font-size: 18px;
                        }
                    }
                }

                .el-pager {
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                }

                .el-pager .number {
                    width: 62px;
                    height: 62px;
                    border-radius: 4px;
                    opacity: 0.6;
                    border: 1px solid #CBCFD3;
                    line-height: 62px;
                    padding: 0 4px;
                    margin-right: 10px;
                    font-size: 18px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .el-icon {
                        display: flex;
                        align-items: center;

                        height: 62px;
                    }


                }

                .active {
                    background: var(--bg-colors);
                    color: #fff;
                }
            }
        }
    }
}
</style>
