import Vue from "vue";
import {
  Button as VButton,
  Swipe,
  SwipeItem,
  Overlay,
  Collapse,
  CollapseItem,
  Pagination,
  Icon,
  Empty,
} from "vant";
Vue.use(VButton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Icon);
