import pc_touzi from "@/views/pc/views/touzi/pc_touzi.vue";
import yd_touzi from "@/views/yd/views/touzi/yd_touzi.vue";
import pc_baogao_gonggao from "@/views/pc/views/touzi/pc_baogao-gonggao.vue";
import yd_baogao_gonggao from "@/views/yd/views/touzi/yd_baogao-gonggao.vue";

const rd = [
  {
    path: "/touzi",
    components: {
      default: pc_touzi,
      yd: yd_touzi,
    },
  },
  {
    path: "/baogao-gonggao",
    components: {
      default: pc_baogao_gonggao,
      yd: yd_baogao_gonggao,
    },
  },
];

export default rd;
