<template>
    <div class="pc_rd_jishu">
        <div class="ul">
            <div class="li" v-for="(item, index) in list" :key="index">
                <div class="jishu_title">
                    {{ item.title }}
                </div>
                <div class="jishu_describe" style="white-space: pre-wrap;" v-html="item.smText">
                </div>
                <div class="jishu_sm_img">
                    <img :src="item.smImg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            list: []
        }
    },
    created() { },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            const res = await this.$API.listApi('rd_jishu')
            this.list = res.data.data
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_rd_jishu {
    width: 100%;

    .ul {
        .li {
            background: url('../../../../images/yd_technology.png') no-repeat;
            background-size: 100% 100%;
            padding: 80px 30px;
            box-sizing: border-box;

            &:nth-child(even) {
                background: #F5F5F5;
            }

            .jishu_title {
                font-size: 36px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #1A1A1A;
                line-height: 50px;
                padding-bottom: 20px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .jishu_describe {
                font-size: 28px;
                font-family: 'PingFangSC-Regular, PingFang SC';
                font-weight: 400;
                color: #383838;
                line-height: 52px;
                padding-bottom: 40px;
            }

            .jishu_sm_img {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
